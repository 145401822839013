import { alpha, Box, Button, FormLabel, IconButton, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { FormContextType, getTemplate, RJSFSchema, StrictRJSFSchema, WidgetProps } from "@rjsf/utils";
import { DeleteOutlined as DeleteOutlinedIcon } from "@mui/icons-material";
import { getDocxPageCount, getPdfPageCount } from "@/utils/getPageCount";
import { UPLOAD_FILE_PAGE_LIMIT, UPLOAD_FILE_SIZE_LIMIT } from "@/contexts/MessagesContext/constants";
import { UploadFile as UploadFileIcon } from "@mui/icons-material";
import { base64ToFile } from "@/utils/base64ToFile";
import { logger } from "@/core/logger";
import { FileTypes } from "@/core/skills/types";
import { WebToast } from "@/components/core/Toast";
import Markdown from "markdown-to-jsx";

const defaultAccept = [FileTypes.PDF, FileTypes.DOCX];

function addNameToDataURL(dataURL: string, name: string) {
  if (dataURL === null) {
    return null;
  }
  return dataURL.replace(";base64", `;name=${encodeURIComponent(name)};base64`);
}

type FileInfoType = {
  dataURL?: string | null;
  name: string;
  size: number;
  type: string;
};

function processFile(file: File): Promise<FileInfoType> {
  const { name, size, type } = file;
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader();
    reader.onerror = (error) => {
      logger.error(`Erro ao ler o arquivo ${name}`, error);
      reject(error);
    };
    reader.onload = (event) => {
      if (typeof event.target?.result === "string") {
        const dataURL = addNameToDataURL(event.target.result, name);
        if (dataURL) {
          logger.debug(`Arquivo ${name} convertido para data-url: ${dataURL.slice(0, 50)}...`);
        }
        resolve({ dataURL, name, size, type });
      } else {
        logger.warn(`Leitura do arquivo ${name} falhou, dataURL é null.`);
        resolve({ dataURL: null, name, size, type });
      }
    };
    reader.readAsDataURL(file);
  });
}

function processFiles(files: File[]) {
  return Promise.all(files.map(processFile));
}

async function checkFileError(file: File, accept: FileTypes[]): Promise<string | null> {
  try {
    if (!(accept as string[]).includes(file.type)) {
      logger.warn(`Arquivo inválido: ${file.name}. Tipo ${file.type} não é permitido.`);
      return `Somente permitido arquivos dos tipo: ${accept.map(fileTypeToFriendlyName).join(", ")}.`;
    }

    let pageCount: number | null = null;
    if (file.type === FileTypes.PDF) {
      pageCount = await getPdfPageCount(file);
    }
    if (file.type === FileTypes.DOCX) {
      pageCount = await getDocxPageCount(file);
    }
    if (pageCount !== null && pageCount > UPLOAD_FILE_PAGE_LIMIT) {
      logger.warn(`Arquivo ${file.name} excede o limite de páginas (${UPLOAD_FILE_PAGE_LIMIT}).`);
      return `O limite de páginas é de ${UPLOAD_FILE_PAGE_LIMIT} por arquivo.`;
    }

    if (file.size > UPLOAD_FILE_SIZE_LIMIT) {
      logger.warn(`Arquivo ${file.name} excede o limite de tamanho (${UPLOAD_FILE_SIZE_LIMIT} bytes).`);
      return `O limite de tamanho é de ${UPLOAD_FILE_SIZE_LIMIT / (1000 * 1000)}MB por arquivo.`;
    }

    return null;
  } catch (e) {
    logger.error(`Erro ao processar o arquivo ${file.name}`, e);
    if (e instanceof Error && e.message.includes("is encrypted")) {
      return "Arquivo com senha";
    }
    return "Erro ao processar o arquivo.";
  }
}

const fileTypeToFriendlyName = (fileType: FileTypes) => {
  switch (fileType) {
    case FileTypes.PDF:
      return "PDF";
    case FileTypes.DOCX:
      return "DOCX";
    case FileTypes.JPEG:
      return "JPEG";
    case FileTypes.PNG:
      return "PNG";
    case FileTypes.TIFF:
      return "TIFF";
  }
};

function generateAcceptText(accept: FileTypes[]): string {
  const imageTypes = accept.filter((fileType) => [FileTypes.JPEG, FileTypes.PNG, FileTypes.TIFF].includes(fileType));
  const imageDescription =
    imageTypes.length > 0 ? `imagens (${imageTypes.map(fileTypeToFriendlyName).join(", ")})` : null;

  const nonImageTypes = accept.filter((fileType) => !imageTypes.includes(fileType));

  const allDescriptions = [imageDescription, ...nonImageTypes.map(fileTypeToFriendlyName)].filter(Boolean) as string[];

  const formattedExtensions =
    allDescriptions.length > 1
      ? `${allDescriptions.slice(0, -1).join(", ")} ou ${allDescriptions.slice(-1)}`
      : allDescriptions[0];

  const includesDocxOrPdf = accept.includes(FileTypes.PDF) || accept.includes(FileTypes.DOCX);

  if (includesDocxOrPdf) {
    return `Apenas ${formattedExtensions} (o limite de pág. é ${UPLOAD_FILE_PAGE_LIMIT} ou no máx. ${(
      UPLOAD_FILE_SIZE_LIMIT /
      (1000 * 1000)
    ).toFixed(0)}MB)`;
  }

  return `Apenas ${formattedExtensions} com no máximo ${(UPLOAD_FILE_SIZE_LIMIT / (1000 * 1000)).toFixed(0)}MB.`;
}

const getFilesErrors = async ({
  files,
  maxFiles,
  accept,
}: {
  files: File[];
  maxFiles?: number;
  accept: FileTypes[];
}) => {
  const exceedsMaxFileLimit = maxFiles ? files.length > maxFiles : false;
  if (exceedsMaxFileLimit) {
    return [`Você pode enviar no máximo ${maxFiles} arquivos`];
  }

  return (await Promise.all(files.map((file) => checkFileError(file, accept)))).filter((error) => error !== null);
};

export const FileWidget = <
  T = unknown,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = Record<string, unknown>,
>(
  props: WidgetProps<T, S, F>
) => {
  const { palette } = useTheme();
  const { id, disabled, readonly, required, onChange, value, options, registry, maxFiles, uiSchema } = props;
  const { multiple, accept: customAccept } = options;
  const accept = (customAccept as FileTypes[]) || defaultAccept;

  const BaseInputTemplate = getTemplate<"BaseInputTemplate", T, S, F>("BaseInputTemplate", registry, options);

  const [files, setFiles] = React.useState<File[]>();
  const [errors, setErrors] = React.useState<string[]>([]);

  useEffect(() => {
    if (!value || (Array.isArray(value) && value.length === 0)) return;

    const newFiles = Array.isArray(value) ? value : [value];

    setFiles(newFiles.map(base64ToFile));
  }, [value]);

  const handleDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    let droppedFiles: File[];
    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      droppedFiles = Array.from(event.dataTransfer.items)
        .filter((item) => item.kind === "file")
        .map((item) => item.getAsFile())
        .filter((item) => !!item) as File[];
    } else {
      // Use DataTransfer interface to access the file(s)
      droppedFiles = Array.from(event.dataTransfer.files);
    }

    if (!multiple) {
      droppedFiles = droppedFiles.slice(0, 1);
    }

    const newFiles = droppedFiles.filter(
      (newFile) => !files?.some((existingFile) => existingFile.name === newFile.name)
    );

    if (newFiles.length < droppedFiles.length) {
      WebToast.error("Alguns arquivos foram ignorados por já existirem na lista");
    }

    const errors = await getFilesErrors({ files: newFiles, maxFiles, accept });

    if (errors.length) {
      logger.warn(`Erros encontrados ao processar arquivos: ${errors.join(", ")}`);
      setErrors(errors);
    }

    setFiles(files ? [...files, ...newFiles] : newFiles);
  };

  const handleAttachment = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const filesList = event.target.files || [];
      const attachedFiles = filesList.length > 0 ? Array.from(filesList) : [];
      const newFiles = attachedFiles.filter(
        (newFile) => !files?.some((existingFile) => existingFile.name === newFile.name)
      );

      if (newFiles.length < attachedFiles.length) {
        WebToast.error("Alguns arquivos foram ignorados por já existirem na lista");
      }

      const errors = await getFilesErrors({ files: newFiles, maxFiles, accept });

      if (errors.length) {
        logger.warn(`Erros encontrados ao processar arquivos: ${errors.join(", ")}`);
        setErrors(errors);
      }

      setFiles(files ? [...files, ...newFiles] : newFiles);
    },
    [accept, maxFiles, files]
  );

  const removeFile = React.useCallback(
    (index: number) => {
      setFiles((prev = []) => {
        const newFiles = [...prev];
        newFiles.splice(index, 1);
        return newFiles;
      });
      setErrors((prev = []) => {
        const newErrors = [...prev];
        newErrors.splice(index, 1);
        return newErrors;
      });
      onChange(undefined);
    },
    [onChange]
  );

  useEffect(() => {
    if (files?.length) {
      if (errors.length) {
        logger.warn(`Erro ao atualizar campo ${id}: ${errors.join(", ")}`);
        onChange(undefined, { __errors: errors }, id);
      } else {
        processFiles(files).then((filesInfoEvent) => {
          const base64Files = filesInfoEvent.map((fileInfo) => fileInfo.dataURL);
          onChange(multiple ? base64Files : base64Files[0]);
        });
      }
    } else {
      onChange(multiple ? [] : null);
    }
  }, [files, onChange, errors, id, multiple]);

  if (!multiple && files?.length) {
    return (
      <>
        {files.map((file, index) => (
          <FileItem
            key={index}
            file={file}
            index={index}
            removeFile={removeFile}
            errors={errors}
            readonly={readonly || false}
          />
        ))}

        {!!errors.length && <FileError errors={errors} />}
      </>
    );
  }
  const descriptionTextSx = { textWrap: "wrap", width: "100%", color: alpha(palette.text.secondary, 0.75) };

  return (
    <>
      <Box
        onDrop={handleDrop}
        onDragOver={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        sx={{
          backgroundColor: "background.paper",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {props.label ? (
          <Typography
            variant="subtitle1"
            sx={{
              whiteSpace: "normal",
              color: palette.text?.primary,
              fontWeight: 600,
              lineHeight: "28px",
              fontSize: "16px",
              mb: 0,
            }}
            component={FormLabel}
          >
            {props.label}
          </Typography>
        ) : null}
        {uiSchema?.["ui:description"] && uiSchema?.["ui:enableMarkdownInDescription"] ? (
          <Markdown
            options={{
              wrapper: (props) => <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }} {...props} />,
              disableParsingRawHTML: true,
              overrides: {
                h1: (props) => <Typography variant="preTitle" sx={descriptionTextSx} {...props} />,
                p: (props) => <Typography variant="body1" sx={descriptionTextSx} {...props} />,
                span: (props) => <Typography variant="subtitle1" sx={descriptionTextSx} {...props} />,
              },
            }}
          >
            {uiSchema?.["ui:description"]}
          </Markdown>
        ) : (
          <Typography
            variant="multiLineBody"
            sx={{
              color: "text.secondary",
              display: "block",
            }}
          >
            {uiSchema?.["ui:description"]}
          </Typography>
        )}
        <Button
          aria-label="Anexar arquivo"
          component="label"
          sx={{
            minHeight: 150,
            width: "100%",
            borderRadius: 4,
            px: 3,
            border: "1px dashed",
            borderColor: props.rawErrors && props.rawErrors?.length > 0 ? "error.main" : "#0000001F",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <UploadFileIcon sx={{ color: "primary.main" }} />
          <Box
            color={"text.primary"}
            sx={{
              textAlign: "center",
              fontSize: "14px",
              textTransform: "none",
              letterSpacing: "0.03em",
              lineHeight: "140%",
            }}
          >
            <Typography variant="subtitle1">
              <Typography
                variant="subtitle1"
                color={"text.primary"}
                sx={{
                  color: "primary.main",
                  textDecoration: "underline",
                  display: "inline",
                  textUnderlineOffset: "4px",
                  textDecorationColor: alpha(palette.primary.main, 0.4),
                }}
              >
                Clique para carregar o arquivo
              </Typography>{" "}
              ou arraste e solte aqui
            </Typography>
            <Typography variant="multiLineBody" sx={{ color: "text.secondary", display: "block", mt: 1 }}>
              {generateAcceptText(accept)}
            </Typography>
          </Box>

          <BaseInputTemplate
            {...props}
            id={id}
            disabled={disabled || readonly}
            type="file"
            required={value ? false : required} // this turns off HTML required validation when a value exists
            onChangeOverride={handleAttachment}
            value=""
            hidden
            accept={accept.join(",")}
          />
        </Button>
      </Box>
      <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 1 }}>
        {files &&
          files.map((file, index) => (
            <FileItem
              key={index}
              file={file}
              index={index}
              removeFile={removeFile}
              errors={errors}
              readonly={readonly || false}
            />
          ))}

        {!!multiple && !!errors.length && <FileError errors={errors} />}
      </Box>
    </>
  );
};

const FileItem = ({
  file,
  index,
  removeFile,
  errors,
  readonly,
}: {
  file: File;
  index: number;
  removeFile: (index: number) => void;
  errors: string[];
  readonly: boolean;
}) => {
  return (
    <Box
      key={index}
      sx={{
        backgroundColor: "background.paper",
        width: "100%",
      }}
    >
      <Box
        sx={{
          minHeight: 64,
          border: "1px solid",
          borderColor: errors?.length > 0 ? "error.main" : "#0000001F",
          borderRadius: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
          py: 2,
          px: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, maxWidth: "calc(100% - 48px)" }}>
          <UploadFileIcon sx={{ color: "primary.main", mr: 1 }} />
          <Typography
            variant="body"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {file.name}
          </Typography>
        </Box>
        {!readonly && (
          <IconButton
            aria-label="Remover arquivo"
            size="medium"
            onClick={() => removeFile(index)}
            sx={{ color: "common.shade" }}
          >
            <DeleteOutlinedIcon fontSize="inherit" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

const FileError = ({ errors }: { errors: string[] }) => {
  return (
    <Box sx={{ color: "error.main", mt: 1 }}>
      {errors.map((error, index) => (
        <Typography key={index} variant="body2">
          {error}
        </Typography>
      ))}
    </Box>
  );
};
