import { LexZapChannel } from "@/services/lexZap/types";

export const getChannelStatus = (channel: LexZapChannel) => {
  if (channel.terminated_at) {
    return { color: "common.black", details: "O canal foi excluído" };
  }

  if (channel.waba_account.status !== "approved") {
    return { color: "error.main", details: `A conta está pendente de aprovação. "${channel.waba_account.status}"` };
  }

  if (!channel.company) {
    if (channel.failedToAutoAssociateCompany) {
      return { color: "warning.main", details: "Necessário associar manualmente a uma company" };
    }
    return { color: "common.mediumShade", details: "O canal está em processo de configuração" };
  }

  return { color: "success.main", details: "O canal está ativo e funcionando corretamente" };
};
