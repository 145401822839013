import React, { useMemo, useState } from "react";
import { Box, Button, Collapse, IconButton, Link, Tab, Tabs, Typography } from "@mui/material";
import { LexZapChat, Ticket, TicketStatus } from "@/hooks/lexZap/types";
import { DateTime } from "luxon";
import {
  ExpandLessRounded as ExpandLessRoundedIcon,
  ExpandMoreRounded as ExpandMoreRoundedIcon,
} from "@mui/icons-material";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { generatePath } from "react-router-dom";
import { TicketSummary } from "./components/TicketSummary";
import { TicketDetails } from "./components/TicketDetails";
import { TicketNotes } from "./components/TicketNotes";

interface TicketCardProps {
  ticket: Ticket;
  chat: LexZapChat;
  onCloseTicket: () => void;
}

enum TicketTab {
  DETAILS,
  SUMMARY,
  NOTES,
}

export const TicketCard = ({ ticket, chat, onCloseTicket }: TicketCardProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(ticket.status === TicketStatus.ACTIVE);
  const ExpandIcon = isExpanded ? ExpandLessRoundedIcon : ExpandMoreRoundedIcon;
  const [tab, setTab] = useState<TicketTab>(TicketTab.DETAILS);
  const relatedCase = useMemo(() => {
    return chat.cases?.find((chatCase) => chatCase.ticketId === ticket.id);
  }, [chat.cases, ticket.id]);

  return (
    <Box sx={{}}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          py: 1,
          px: 2,
          gap: 1,
        }}
        onClick={() => setIsExpanded((prev) => !prev)}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: "16px",
            color: "text.secondary",
          }}
        >
          {DateTime.fromMillis(ticket.createdAt).toFormat("dd/MM/yyyy")}
          {" - "}
          {ticket.closedAt && `${DateTime.fromMillis(ticket.closedAt).toFormat("dd/MM/yyyy")}`}
        </Typography>

        <IconButton sx={{ m: -1 }}>
          <ExpandIcon fontSize="medium" sx={{ width: "24px", height: "24px", color: "text.secondary" }} />
        </IconButton>
      </Box>

      <Collapse in={isExpanded}>
        <Box
          sx={{
            padding: 2,
            paddingTop: 1,
          }}
        >
          <Tabs
            value={tab}
            onChange={(_, newValue) => setTab(newValue)}
            sx={{
              width: "100%",
              mb: 2.5,
              "& .MuiTab-root": {
                textTransform: "none",
                flex: 1,
                py: 1,
                minHeight: "48px",
                fontWeight: 600,
                borderBottom: "1px solid",
                borderColor: "divider",
              },
            }}
          >
            <Tab label="Detalhes" value={TicketTab.DETAILS} />
            <Tab label="Assunto" value={TicketTab.SUMMARY} />
            <Tab label="Anotações" value={TicketTab.NOTES} />
          </Tabs>

          {tab === TicketTab.DETAILS && <TicketDetails ticket={ticket} ticketCase={relatedCase} />}
          {tab === TicketTab.SUMMARY && <TicketSummary ticket={ticket} />}
          {tab === TicketTab.NOTES && <TicketNotes ticket={ticket} />}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 4,
            }}
          >
            {ticket.status === TicketStatus.ACTIVE && (
              <Button variant="outlined" onClick={onCloseTicket}>
                Encerrar atendimento
              </Button>
            )}
            <Link href={generatePath(ROUTE_PATHS.TICKET_DETAILS, { ticketId: ticket.id })}>
              <Button variant="contained" color="secondary" fullWidth>
                {!!ticket.summary ? "Ver resumo" : "Ver detalhes"}
              </Button>
            </Link>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};
