import { SkillGroup } from "@/core/skillGroups/types";
import { CreditPerSkill } from "@/core/skills/types";

export const CreateANewDocumentSkillGroup: SkillGroup = {
  id: "create_new_document",
  name: "Escrever novo documento",
  subtitle: "Selecione o tipo de peça:",
  credits: CreditPerSkill.ADVANCED_SKILL,
  skillShortcuts: [
    {
      label: "Petição inicial",
      skillId: "create_initial_petition",
      category: "Peças judiciais",
    },
    {
      label: "Reclamação trabalhista",
      skillId: "create_labor_complaint",
      category: "Peças judiciais",
      flag: "skillCreateLaborComplaintV1Enabled",
    },
    {
      label: "Reclamação trabalhista",
      skillId: "create_labor_complaint_v2",
      category: "Peças judiciais",
      flag: "skillCreateLaborComplaintV2Enabled",
    },
    {
      label: "Contestação",
      skillId: "create_contestation",
      category: "Peças judiciais",
      flag: "skillCreateDefenseV1Enabled",
    },
    {
      label: "Contestação",
      skillId: "create_contestation_v2",
      category: "Peças judiciais",
      flag: "skillCreateDefenseV2Enabled",
    },
    {
      label: "Réplica",
      skillId: "create_reply",
      category: "Peças judiciais",
      flag: "skillCreateReplyV1Enabled",
    },
    { label: "Réplica", skillId: "create_reply_v2", category: "Peças judiciais", flag: "skillCreateReplyV2Enabled" },
    { label: "Petição intercorrente", skillId: "intercurrent_motion", category: "Peças judiciais" },
    { label: "Apelação", skillId: "create_appeal", category: "Peças judiciais" },
    { label: "Recurso ordinário trabalhista", skillId: "create_ordinary_appeal", category: "Peças judiciais" },
    {
      label: "Outro tipo de peça",
      skillId: "create_other_type_of_piece",
      category: "Peças judiciais",
      flag: "skillOtherTypeOfPieceV1Enabled",
    },
    {
      label: "Outro tipo de peça",
      skillId: "create_other_type_of_piece_v2",
      category: "Peças judiciais",
      flag: "skillOtherTypeOfPieceV2Enabled",
    },
    { label: "Contrato", skillId: "create_contract", category: "Contratos" },
    { label: "Contrato de honorários", skillId: "fee_agreement", category: "Contratos" },
    { label: "Estratégia jurídica", skillId: "create_case_strategy", category: "Outros tipos de documentos" },
    { label: "Proposta de acordo", skillId: "settlement_offer", category: "Outros tipos de documentos" },
    {
      label: "Notificação Extrajudicial",
      skillId: "create_notice",
      category: "Outros tipos de documentos",
    },
    { label: "Parecer jurídico", skillId: "legal_advice", category: "Outros tipos de documentos" },
  ],
  tags: ["recommend", "litigation"],
};
