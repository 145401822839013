import { WebToast } from "@//components/core/Toast";
import { Loading } from "@/components/Loading";
import { ModalRight } from "@/components/Modal/ModalRight";
import { Box, Button } from "@mui/material";
import React, { ChangeEvent, useEffect } from "react";
import { InputField } from "../InputField";
import { LexZapChannel } from "@/services/lexZap/types";
import { useUpdateLexZapChannel } from "@/hooks/lexZap/admin/useUpdateLexZapChannel";

export const UpdateChannelCompanyModal = ({
  isOpen,
  onClose,
  channel,
}: {
  isOpen: boolean;
  onClose: () => void;
  channel?: LexZapChannel;
}) => {
  const [form, setForm] = React.useState<
    Partial<{
      email?: string;
    }>
  >({});
  const { mutateAsync: updateChannel, isPending: isLoading } = useUpdateLexZapChannel();

  useEffect(() => {
    setForm({});
  }, [channel]);

  const handleUpdateUser = async () => {
    if (!channel) {
      return onClose();
    }

    try {
      await updateChannel({
        channelId: channel.id,
        updates: {
          email: form.email,
        },
      });
      onClose();
      WebToast.success("Company vinculada com sucesso");
    } catch {
      WebToast.error("Falha ao vincular a company");
    }
  };

  const handleChangeForm = <K extends keyof typeof form>(prop: K) => {
    return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setForm((prevState) => ({
        ...prevState,
        [prop]: e.target.value,
      }));
    };
  };

  if (!channel) return null;

  return (
    <ModalRight
      title={"Vincular company"}
      size={"SMALL"}
      onClose={onClose}
      footer={
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            gap: "8px",
          }}
        >
          <Button variant="contained" disabled={isLoading || !form.email} onClick={handleUpdateUser}>
            Atualizar
          </Button>
          <Button disabled={isLoading} onClick={onClose}>
            Cancelar
          </Button>
        </Box>
      }
      open={isOpen}
    >
      <Loading isLoading={isLoading}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
          }}
        >
          {!!channel.company && (
            <InputField disabled={true} value={channel.company.companyId} label="CompanyId atual" />
          )}

          <InputField
            placeholder="Email de um membro da company"
            onChange={handleChangeForm("email")}
            type="text"
            value={form.email}
            label="Email"
          />
        </Box>
      </Loading>
    </ModalRight>
  );
};
