import { LegalPieceMacroType } from "@/contexts/MessagesContext";
import { buildSkillStep } from "@/core/skillForms/builder";

export const createOtherTypeOfPieceSkillStep3Response = buildSkillStep({
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Nome do cliente",
        description: "Insira o nome completo do cliente, conforme registrado nos documentos oficiais do processo.",
        placeholder: "Maria Fernanda Oliveira da Silva, conforme consta no CPF 987.654.321-00 e RG nº 1234567.",
      },
    },
    {
      name: "case_breakdown",
      type: "string",
      ui: {
        label: "Descrição dos fatos",
        description:
          "Conte a história detalhando os fatos essenciais, cronologicamente inclua: datas, locais e eventos.",
        placeholder:
          "Em 12/04/2021, na cidade de São Paulo, o cliente teve seu pedido de aposentadoria por tempo de contribuição indeferido, apesar de cumprir os requisitos legais.",
      },
    },
    {
      name: "theses",
      type: "string",
      required: false,
      ui: {
        label: "Argumentos jurídicos",
        description:
          "Insira os argumentos jurídicos que sustentam sua petição, referenciando a legislação aplicável e vinculando-os aos fatos e provas apresentados.",
        placeholder:
          "Com base na CF e na Lei 8.213/91, o cliente tem direito à aposentadoria por tempo de contribuição, cumpridos os requisitos.",
      },
    },
    {
      name: "evidence_text",
      type: "string",
      required: false,
      ui: {
        label: "Descrever provas",
        description:
          "Descreva provas que sustentem os fatos, como: documentos, imagens ou evidências relacionadas ao caso.",
        placeholder:
          "Anexo laudo médico de 05/08/2020, comprovando a incapacidade laboral e o período de afastamento necessário.",
      },
    },
    {
      name: "requests",
      type: "string",
      required: false,
      ui: {
        label: "Solicitar pedidos",
        description:
          "Liste os pedidos ao juiz, detalhando de forma clara as ações ou reparações que você busca obter no processo.",
        placeholder:
          "Requer o deferimento da aposentadoria por tempo de contribuição, com pagamento retroativo desde a data do requerimento.",
      },
    },
  ],
  condition: {
    value: LegalPieceMacroType.RESPONSE,
    field: "legal_piece_macro_type",
  },
  title: "Detalhes do caso",
});
