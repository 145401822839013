import React from "react";
import { Box, Link, Skeleton, Typography } from "@mui/material";
import { SxProps, Theme } from "@mui/material";

import { Applicant } from "@/services/applicant/types";
import { Ticket } from "@/hooks/lexZap/types";

interface TicketCardProps {
  ticketData?: Ticket;
  applicant?: Applicant;
  loadingTicket: boolean;
  openEditApplicantModal: () => void;
}

export const TicketCard = ({ ticketData, applicant, loadingTicket, openEditApplicantModal }: TicketCardProps) => {
  if (loadingTicket || !ticketData) {
    return <TicketCardSkeleton />;
  }

  return (
    <Card>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", gap: 2 }}>
        <Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <Typography variant="h6" mb={1}>
              {`Atendimento - ${applicant?.name}`}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography variant="subtitle2" color="text.secondary">
            Cliente
          </Typography>
          <Link
            onClick={applicant?.id ? openEditApplicantModal : () => null}
            sx={{
              color: "primary.main",
            }}
          >
            {applicant?.name}
          </Link>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Typography variant="subtitle2" color="text.secondary">
            Contato
          </Typography>
          {applicant?.phoneNumber ? (
            <Typography variant="body1">{formatPhoneNumber(applicant.phoneNumber)}</Typography>
          ) : (
            <Link
              onClick={applicant?.id ? openEditApplicantModal : () => null}
              sx={{
                width: "fit-content",
                textDecoration: "underline",
                color: "primary.main",
              }}
            >
              Adicionar número
            </Link>
          )}
        </Box>
      </Box>
    </Card>
  );
};

const formatPhoneNumber = (phoneNumber: string) => {
  return phoneNumber.slice(2).replace(/(\d{2})(9?\d{4})(\d{4})/, "($1) $2-$3");
};

const TicketCardSkeleton = () => {
  return (
    <Card>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
          <Box>
            <Skeleton variant="rectangular" width={200} height={24} sx={{ mb: 1 }} />
            <Skeleton variant="rectangular" width={160} height={20} />
          </Box>
          <Skeleton variant="circular" width={24} height={24} />
        </Box>

        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 2 }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Skeleton variant="rectangular" width={80} height={16} />
            <Skeleton variant="rectangular" width={120} height={20} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Skeleton variant="rectangular" width={80} height={16} />
            <Skeleton variant="rectangular" width={120} height={20} />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Skeleton variant="rectangular" width={80} height={16} />
            <Skeleton variant="rectangular" width={120} height={20} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

interface CardProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
  loadingSize?: string;
}

const Card = ({ children, sx, loadingSize }: CardProps) => {
  if (loadingSize) {
    return (
      <Skeleton
        variant="rectangular"
        sx={{
          borderRadius: 2,
        }}
        width="100%"
        height={loadingSize}
      />
    );
  }

  return (
    <Box
      sx={{
        bgcolor: "common.white",
        borderRadius: 2,
        border: "1px solid",
        borderColor: "divider",
        display: "flex",
        flexDirection: "column",
        gap: 3,
        p: 3,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
