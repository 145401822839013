import React from "react";
import { DialogModal } from "@/components/DialogModal";
import { CircularProgress, Box, Typography } from "@mui/material";

interface ActivateAiModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading: boolean;
}

export const ActivateAiModal = ({ isOpen, onConfirm, onCancel, isLoading }: ActivateAiModalProps) => {
  if (!isOpen) return null;
  return (
    <DialogModal
      open={isOpen}
      onClose={onCancel}
      title="Reativar assistente IA"
      description={
        <Box>
          <Typography>
            A Lia não segue conversas já iniciadas, ou seja, se existe um atendimento ativo a assistente IA não vai
            entrar nessa conversa, e sim no início do próximo atendimento.
          </Typography>
          {isLoading && (
            <Box display="flex" color="secondary" justifyContent="center" mt={1}>
              <CircularProgress />
            </Box>
          )}
        </Box>
      }
      descriptionTextAlign="center"
      buttonsDirection="row"
      buttons={[
        {
          label: "Cancelar",
          onClick: onCancel,
          sx: {
            color: "primary.main",
            backgroundColor: "transparent",
            fontWeight: 600,
            "&:hover": {
              backgroundColor: "transparent",
              textDecoration: "underline",
            },
          },
        },
        {
          label: "Confirmar",
          onClick: onConfirm,
          sx: {
            fontWeight: 600,
          },
          color: "primary",
          disabled: isLoading,
        },
      ]}
      variant="error"
    />
  );
};
