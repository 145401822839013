import { Box, IconButton, TextField, Typography } from "@mui/material";
import React, { useRef, useState } from "react";

import { SendRounded as SendRoundedIcon } from "@mui/icons-material";
import { useSendLexZapMessage } from "@/hooks/lexZap/useSendLexZapMessage";
import { useLexZapChatInfo } from "@/hooks/lexZap/useLexZapChatInfo";
import { MessageTemplateCharacterCounter } from "@/components/LexZap/MessageTemplateCharacterCounter";
import { Case, CaseMessageToSendType } from "@/hooks/lexZap/types";
import { useLexZapChat } from "@/hooks/lexZap/useLexZapChat";
import { buildTemplateMessage } from "@/hooks/lexZap/useSendLexZapMessage/templates";
import { Applicant } from "@/services/applicant/types";
import { useCredits } from "@/hooks/credits/useCredits";
import { PlanType } from "@/hooks/credits/types";

interface CaseChatInputProps {
  caseData?: Case;
  applicant?: Applicant;
  disabled: boolean;
}

export const CaseChatInput = ({ caseData, applicant, disabled }: CaseChatInputProps) => {
  const { chat } = useLexZapChat({
    applicantPhoneNumber: applicant?.phoneNumber || caseData?.applicantPhoneNumber,
    disabled:
      disabled || !(caseData?.applicantPhoneNumber || applicant?.phoneNumber) || (caseData && !caseData?.chatId),
  });

  const { data: credits } = useCredits();
  const hasPlanWithWpp = [PlanType.PROFESSIONAL_WITH_WPP, PlanType.CUSTOM, PlanType.PROFESSIONAL_CUSTOM].includes(
    credits?.companyPlan?.plan as PlanType
  );
  const disabledSendMessage =
    !(caseData?.applicantPhoneNumber || applicant?.phoneNumber) ||
    (caseData && !caseData?.chatId) ||
    disabled ||
    !hasPlanWithWpp;

  const { shouldUseTemplate, templateToUse, templateTextWithoutMessage } = useLexZapChatInfo({ chat });
  const [inputValue, setInputValue] = useState("");
  const formRef = useRef<HTMLFormElement>(null);

  const { mutate: sendMessage } = useSendLexZapMessage();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (inputValue.trim()) {
      const applicantPhoneNumber = applicant?.phoneNumber || caseData?.applicantPhoneNumber;
      if (!applicantPhoneNumber) return;

      sendMessage({
        applicantPhoneNumber,
        message: templateToUse
          ? buildTemplateMessage({
              templateKey: templateToUse,
              message: inputValue,
              applicantName: chat?.applicantName || "",
            })
          : {
              type: CaseMessageToSendType.TEXT,
              body: inputValue,
            },
      });
      setInputValue("");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      formRef.current?.requestSubmit();
    }
  };

  return (
    <Box
      component="form"
      ref={formRef}
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "6px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: 2.5,
          paddingRight: 1.5,
          borderRadius: "5px",
          border: "1px solid",
          borderColor: "common.lightShade",
          backgroundColor: disabled ? "#F4F3F3" : "grey.50",
          "&:hover": {
            borderColor: "common.mediumShade",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
          }}
        >
          {shouldUseTemplate && (
            <Typography variant="multiLineBody" color="text.primary" mb={0.5}>
              {templateTextWithoutMessage}
            </Typography>
          )}

          <TextField
            sx={{
              width: "100%",
              "& label.Mui-focused": {
                color: "common.mediumShade",
              },
              "& .MuiInput-underline:after": {
                border: "none",
                borderBottomColor: "common.mediumShade",
              },
              "& .MuiOutlinedInput-root": {
                padding: 0,
                "& fieldset": {
                  border: "none",
                },
              },
            }}
            autoComplete="off"
            placeholder="Digite sua mensagem aqui"
            multiline
            variant="outlined"
            size="small"
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown}
            disabled={disabledSendMessage}
            value={inputValue}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "20px",
          }}
        >
          <IconButton type="submit">
            <SendRoundedIcon
              fontSize="medium"
              sx={{
                color: disabled ? "#BAB6B5" : "orange.light.90",
              }}
            />
          </IconButton>
        </Box>
      </Box>

      {shouldUseTemplate && <MessageTemplateCharacterCounter inputLength={inputValue.length} />}
    </Box>
  );
};
