import React, { useMemo } from "react";

import { Box, Skeleton, Typography } from "@mui/material";
import { LegalProceedingsAttachedModal } from "./components/LegalProceedingsAttachedModal";
import { useCaseLegalProceedingsQuery } from "@/hooks/legalProceedings/useCaseLegalProceedingsQuery";
import { LegalProceedingType } from "@/services/legalProceeding/types";
import { formatLegalProceedingCnj } from "@/utils/formatLegalProceedingCnj";
import { useLegalProceedingHistory } from "@/hooks/legalProceedings/useLegalProceedingHistory";
import { LexterDate } from "@/core/LexterDate";
import { LegalProceedingImportByCNJ } from "@/components/LegalProceedingImportByCNJ";
import { Case } from "@/hooks/lexZap/types";
import { ChevronRight } from "@mui/icons-material";

type LegalProceedingsAttachedProps = {
  caseData: Case;
};

export const LegalProceedingsAttached = ({ caseData }: LegalProceedingsAttachedProps) => {
  const [open, setOpen] = React.useState(false);
  const {
    data: legalProceedings,
    isLoading: isLoadingLegalProceedings,
    isSuccess: isSuccessLegalProceedings,
  } = useCaseLegalProceedingsQuery({
    caseId: caseData.id,
  });
  const [selectedLegalProceeding, setSelectedLegalProceeding] = React.useState<LegalProceedingType | null>(
    legalProceedings?.[0] || null
  );

  const onClose = () => {
    setOpen(false);
  };

  const onOpenDetails = (legalProceeding: LegalProceedingType) => {
    setSelectedLegalProceeding(legalProceeding);
    setOpen(true);
  };

  const isLoadingLegalProceedingsAttached = useMemo(() => {
    return isLoadingLegalProceedings || (caseData.cnjs && caseData.cnjs.length > 0 && legalProceedings?.length === 0);
  }, [isLoadingLegalProceedings, caseData.cnjs, legalProceedings]);

  if (isLoadingLegalProceedingsAttached) {
    return <LegalProceedingsAttachedSkeleton />;
  }

  return (
    <Box sx={{ bgcolor: "common.white", borderRadius: 2, p: 3, border: "1px solid", borderColor: "divider" }}>
      <LegalProceedingsAttachedHeader hasLegalProceedings={isSuccessLegalProceedings && legalProceedings.length > 0} />
      {isSuccessLegalProceedings ? (
        <>
          <LegalProceedingsAttachedList
            caseData={caseData}
            legalProceedings={legalProceedings}
            onOpenDetails={onOpenDetails}
          />
          <LegalProceedingsAttachedModal
            open={open}
            onClose={onClose}
            legalProceedings={legalProceedings}
            selectedLegalProceeding={selectedLegalProceeding}
          />
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "150px",
            bgcolor: "grey.50",
            borderRadius: 2,
          }}
        >
          <Typography variant="subtitle1">Erro ao carregar processos vinculados</Typography>
        </Box>
      )}
    </Box>
  );
};

const LegalProceedingsAttachedHeader = ({ hasLegalProceedings }: { hasLegalProceedings: boolean }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 1, mb: 3 }}>
      <Typography variant="subtitle1" fontWeight={700}>
        {hasLegalProceedings ? "Processos vinculados" : "Vincular processo"}
      </Typography>
    </Box>
  );
};

const LegalProceedingsAttachedList = ({
  legalProceedings,
  onOpenDetails,
  caseData,
}: {
  legalProceedings: LegalProceedingType[];
  onOpenDetails: (legalProceeding: LegalProceedingType) => void;
  caseData: Case;
}) => {
  if (legalProceedings.length === 0) {
    return <LegalProceedingImportByCNJ caseData={caseData} />;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {legalProceedings.map((legalProceeding) => (
          <LegalProceedingsAttachedItem
            key={`${legalProceeding.cnj}-${legalProceeding.instance}`}
            legalProceeding={legalProceeding}
            onOpenDetails={() => onOpenDetails(legalProceeding)}
          />
        ))}
      </Box>
    </Box>
  );
};

const LegalProceedingsAttachedItem = ({
  legalProceeding,
  onOpenDetails,
}: {
  legalProceeding: LegalProceedingType;
  onOpenDetails: () => void;
}) => {
  const { data: legalProceedingHistory } = useLegalProceedingHistory({
    cnj: legalProceeding.cnj,
    instance: legalProceeding.instance,
  });

  const lastLegalProceedingHistoryItem = useMemo(() => {
    if (legalProceedingHistory && legalProceedingHistory.length > 0) {
      return legalProceedingHistory
        .filter((item) => item.date)
        .sort((a, b) => {
          const aDate = LexterDate.fromLegalProceedingDateString(a.date);
          const bDate = LexterDate.fromLegalProceedingDateString(b.date);
          if (aDate.isSuccess && bDate.isSuccess) {
            return bDate.getValue().toTimestamp() - aDate.getValue().toTimestamp();
          }
          return 0;
        })[0];
    }
    return null;
  }, [legalProceedingHistory]);

  const lastMovementFromLegalProceedingHistoryItem = useMemo(() => {
    if (lastLegalProceedingHistoryItem) {
      const dateOrError = LexterDate.fromLegalProceedingDateString(lastLegalProceedingHistoryItem.date);
      if (dateOrError.isSuccess) {
        return dateOrError.getValue();
      }
      return null;
    }
    return null;
  }, [lastLegalProceedingHistoryItem]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 2,
        borderRadius: 2,
        border: "1px solid",
        borderColor: "divider",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#FFF9F8",
        },
      }}
      onClick={onOpenDetails}
    >
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 1 }}>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Typography variant="body2">
            {legalProceeding.tribunalAcronym} - {formatLegalProceedingCnj(legalProceeding.cnj)} -{" "}
            {legalProceeding.instance}ª Instância
          </Typography>
        </Box>
      </Box>
      <Box>
        <Typography variant="subtitle1" fontWeight={700}>
          {legalProceeding.name}
        </Typography>
      </Box>
      {legalProceedingHistory ? (
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr", gap: 0.5, width: "100%" }}>
          {lastMovementFromLegalProceedingHistoryItem ? (
            <Typography variant="body1">
              Última movimentação em {lastMovementFromLegalProceedingHistoryItem?.toDisplayString()}
            </Typography>
          ) : (
            <Typography variant="body1">Sem movimentações</Typography>
          )}
          <Typography
            variant="body1"
            color="primary.main"
            sx={{
              display: "flex",
              alignItems: "center",
              fontWeight: 600,
              justifyContent: "flex-end",
            }}
          >
            Ver histórico de movimentação
            <ChevronRight sx={{ fontSize: 18 }} />
          </Typography>
        </Box>
      ) : (
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr", gap: 0.5, width: "100%" }}>
          <Typography variant="body1" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            Última movimentação em <Skeleton variant="text" width={150} />
          </Typography>
          <Typography
            variant="body1"
            color="primary.main"
            sx={{
              display: "flex",
              alignItems: "center",
              fontWeight: 600,
              justifyContent: "flex-end",
            }}
          >
            <Skeleton variant="text" width={150} sx={{ ml: "auto" }} />
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const LegalProceedingsAttachedSkeleton = () => {
  return (
    <Box sx={{ bgcolor: "common.white", borderRadius: 2, p: 3, border: "1px solid", borderColor: "divider" }}>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 1, mb: 3 }}>
        <Skeleton variant="text" width={150} />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            borderRadius: 2,
            border: "1px solid",
            borderColor: "divider",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 1, mb: 2 }}>
            <Box sx={{ display: "flex", gap: 1 }}>
              <Skeleton variant="text" width={40} />
              <Skeleton variant="text" width={200} />
            </Box>
            <Skeleton variant="circular" width={24} height={24} />
          </Box>
          <Skeleton variant="text" width="80%" height={32} sx={{ mb: 4 }} />
          <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 2 }}>
            <Box>
              <Skeleton variant="text" width={150} />
              <Skeleton variant="text" width={100} />
              <Skeleton variant="text" width={120} />
            </Box>
            <Box sx={{ textAlign: "end" }}>
              <Skeleton variant="text" width={100} sx={{ ml: "auto" }} />
              <Skeleton variant="text" width={80} sx={{ ml: "auto" }} />
              <Skeleton variant="text" width={160} sx={{ ml: "auto" }} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
