import { CloseOutlined, SearchOutlined, Https as HttpsIcon } from "@mui/icons-material";
import {
  Box,
  Card,
  Chip,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { StarBorder as StarBorderIcon, Star as StarFillIcon } from "@mui/icons-material";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActionProps } from "@/core/actions";
import { Tab, Tabs } from "@/components/Tabs";
import { useCredits } from "@/hooks/credits/useCredits";
import { notShowCredits } from "@/utils/plans";
import { usePaywallContext } from "@/contexts/PaywallContext";
import { getSkillGroupPath, getSkillPath } from "@/routes/routePaths";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { useCustomerHelpChat } from "@/hooks/customerHelpChat/useCustomerHelpChat";

interface ActionsListProps {
  actions: ActionProps[];
  handleComingSoon: (action: ActionProps) => void;
  isLoading: boolean;
  toggleFavoriteSkill: ({ skillId, isFavorite }: { skillId: string; isFavorite: boolean }) => Promise<void>;
  hasDescription?: boolean;
  threadId?: string;
}

const allTabs = ["new", "mostUsed", "litigation", "consultative", "comingSoon", "all"] as const;

type TabValue = (typeof allTabs)[number];

export const ActionsList = ({
  actions,
  handleComingSoon,
  isLoading,
  toggleFavoriteSkill,
  hasDescription = false,
  threadId,
}: ActionsListProps) => {
  const { showCustomerHelpChat } = useCustomerHelpChat();
  const { data: credits } = useCredits();
  const [tabValue, setTabValue] = React.useState<TabValue>("new");
  const [searchValue, setSearchValue] = useState("");

  const currentTab: TabValue = searchValue ? "all" : tabValue;

  const tabActions = useMemo(
    () => Object.fromEntries(allTabs.map((tab) => [tab, filterActionsByTab(tab, actions)])),
    [actions]
  );

  const currentTabActions = tabActions[currentTab];

  const filteredActions = useMemo(() => {
    return searchValue.trim()
      ? currentTabActions.filter((action) => normalizeToSearch(action.name).includes(normalizeToSearch(searchValue)))
      : currentTabActions;
  }, [searchValue, currentTabActions]);

  const tabsWithActions = useMemo(() => {
    return allTabs.filter((tab) => tabActions[tab].length > 0);
  }, [tabActions]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleTabChange = (value: TabValue) => {
    setTabValue(value);
  };

  React.useEffect(() => {
    handleTabChange(tabValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "end",
          justifyContent: "space-between",
          mb: 3,
        }}
      >
        <TabsActionList
          tabs={tabsWithActions}
          value={currentTab}
          handleTabChange={handleTabChange}
          onlyAll={Boolean(searchValue)}
        />
        <SearchField value={searchValue} handleSearch={handleSearch} />
      </Box>

      {filteredActions.length === 0 && (
        <Card
          sx={{
            p: 8,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h5">Não encontramos sua busca.</Typography>
          <Typography>
            <Link
              sx={{
                fontWeight: 700,
              }}
              href={getSkillGroupPath({ skillGroupId: "create_new_document" })}
            >
              Escreva uma peça
            </Link>
            {", "}
            <Link
              sx={{
                fontWeight: 700,
              }}
              href={getSkillPath({ skillId: "legal_questions" })}
            >
              Tire duvidas juridicas
            </Link>
            {" ou "}
            <Link
              sx={{
                fontWeight: 700,
              }}
              component="button"
              onClick={() => {
                showCustomerHelpChat(credits?.companyPlan?.plan.toString() || "");
              }}
            >
              Entre em contato com nosso time
            </Link>
            .
          </Typography>
        </Card>
      )}
      <List
        sx={{
          border: "1px",
        }}
      >
        {filteredActions.map((action, i) => {
          if (action.tags.includes("dependsOnDocument") && !threadId) {
            return;
          }
          return (
            <ActionButton
              key={i}
              action={action}
              toggleFavoriteSkill={toggleFavoriteSkill}
              handleComingSoon={handleComingSoon}
              isLoading={isLoading}
              hasDescription={hasDescription}
              threadId={threadId}
            />
          );
        })}
      </List>
    </Box>
  );
};

interface ActionButtonProps {
  action: ActionProps;
  handleComingSoon: (action: ActionProps) => void;
  isLoading: boolean;
  toggleFavoriteSkill: ({ skillId, isFavorite }: { skillId: string; isFavorite: boolean }) => Promise<void>;
  hasDescription?: boolean;
  threadId?: string;
}

const ActionButton = ({
  action,
  toggleFavoriteSkill,
  isLoading,
  handleComingSoon,
  hasDescription,
  threadId,
}: ActionButtonProps) => {
  const { palette } = useTheme();
  const navigate = useNavigate();
  const { name, credits, hidden, id } = action;
  const { data: creditsResponse, isLoading: isLoadingCredits } = useCredits();
  const { openSkillPaywall } = usePaywallContext();
  const flags = useFeatureFlags();
  const showCredits = !notShowCredits(creditsResponse, flags.planScreenV2Enabled);

  const handleFavorite = async () => {
    await toggleFavoriteSkill({ skillId: id, isFavorite: action.favorite });
  };

  if (hidden) return null;

  const FavoriteIcon = ({ isFavorite }: { isFavorite: boolean }) => {
    if (isLoading) {
      return <Skeleton variant="circular" width="24px" height="24px" />;
    }

    if (isFavorite) {
      return <StarFillIcon onClick={handleFavorite} sx={{ cursor: "default", fill: palette.common?.yellow }} />;
    }

    return <StarBorderIcon onClick={handleFavorite} sx={{ cursor: "default", color: "common.ligthShade" }} />;
  };

  const handleClick = () => {
    if ("blocked" in action) {
      openSkillPaywall({
        skillName: name,
        availabilityPlans: action.availabilityPlans,
      });
    } else {
      if (action.tags.includes("comingSoon")) {
        return handleComingSoon(action);
      }
      navigate(
        action.type === "SKILL"
          ? getSkillPath({ skillId: action.id, threadId })
          : getSkillGroupPath({ skillGroupId: action.id, threadId })
      );
    }
  };

  return (
    <ListItem
      sx={{
        border: "1px solid black",
        borderColor: "common.lightShade",
        bgcolor: "common.white",
        marginY: 1,
        marginX: 0,
        padding: 0,

        borderRadius: "5px",
        boxShadow: "0px 4px 16px 0px #00000014",
      }}
      secondaryAction={
        <IconButton disabled={isLoading} aria-label="favorite">
          <FavoriteIcon isFavorite={action.favorite} />
        </IconButton>
      }
    >
      <ListItemButton
        id={`list_button_${id}`}
        component="button"
        sx={{
          borderRadius: "5px",

          "&:hover": {
            fontSize: "11px",
            outline: "1px solid",
            outlineColor: "primary.dark",
            borderColor: "primary.dark",
            bgcolor: "common.white",
          },

          "&:focus-visible": {
            fontSize: "11px",
            outline: "2px solid",
            outlineColor: "primary.dark",
            borderColor: "primary.dark",
            bgcolor: "common.white",
          },
        }}
        onClick={handleClick}
      >
        <ListItemText
          sx={{
            "& .MuiListItemText-primary": {
              fontWeight: 700,
              fontSize: "14px",
              lineHeight: "2",
            },
            "& .MuiListItemText-secondary": {
              fontSize: "11px",
            },
          }}
          primary={
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Typography variant="subtitle1" component="span" color="text.primary">
                  {name}
                </Typography>
                {"blocked" in action && <HttpsIcon sx={{ fontSize: 18, color: "grey.600" }} />}
                {action.tags.includes("comingSoon") && (
                  <Chip
                    label="Em breve"
                    size="small"
                    sx={{ backgroundColor: "primary.main", color: "primary.contrastText" }}
                  />
                )}
                {action.tags.includes("new") && (
                  <Chip
                    label="Novidade"
                    size="small"
                    sx={{ backgroundColor: "warning.main", color: "warning.contrastText" }}
                  />
                )}
                {action.tags.includes("recommend") && (
                  <Chip
                    label="Recomendado"
                    size="small"
                    sx={{ backgroundColor: "info.main", color: "info.contrastText" }}
                  />
                )}
              </Box>

              {hasDescription && action.description && <Typography>{action.description}</Typography>}
            </Box>
          }
          secondary={!isLoadingCredits && showCredits ? `${credits} CRÉDITOS` : ""}
        ></ListItemText>
      </ListItemButton>
    </ListItem>
  );
};

interface SearchProps {
  value: string;
  handleSearch: (value: string) => void;
}

const SearchField = (props: SearchProps) => {
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleSearch(event.target.value);
  };

  return (
    <TextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined sx={{ color: "common.lightShade" }} />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end" sx={{ mr: -1 }}>
            <IconButton
              onClick={() => {
                props.handleSearch("");
              }}
              size="small"
            >
              <CloseOutlined sx={{ color: "common.lightShade" }} fontSize={"small"} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      hiddenLabel
      variant="outlined"
      placeholder="Buscar..."
      value={props.value}
      onChange={handleSearch}
      size="small"
    />
  );
};

interface TabsActionListProps {
  handleTabChange: (value: TabValue) => void;
  value: TabValue;
  tabs: Array<TabValue>;
  onlyAll?: boolean;
}

const tabNameMap = new Map<TabValue, string>([
  ["all", "Todas as ações"],
  ["new", "Novidade"],
  ["mostUsed", "Mais usadas"],
  ["litigation", "Contencioso"],
  ["consultative", "Consultivas"],
  ["comingSoon", "Em breve"],
]);

const TabsActionList = (props: TabsActionListProps) => {
  const handleChange = (_event: React.SyntheticEvent, tab: TabValue) => {
    props.handleTabChange(tab);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Tabs value={props.value} onChange={handleChange}>
        {props.tabs.map((tab) => (
          <Tab key={tab} label={tabNameMap.get(tab)} value={tab} disabled={props.onlyAll && tab !== "all"} />
        ))}
      </Tabs>
    </Box>
  );
};

const filterActionsByTab = (tabValue: TabValue, actions: ActionProps[]) => {
  switch (tabValue) {
    case "comingSoon":
      return actions.filter((action) => action.tags.includes("comingSoon"));
    case "new":
      return actions.filter((action) => action.tags.includes("new"));
    case "litigation":
      return actions.filter((action) => action.tags.includes("litigation"));
    case "consultative":
      return actions.filter((action) => action.tags.includes("consultative"));
    case "mostUsed":
      return actions.filter((action) => action.tags.includes("mostUsed"));
    default:
      return actions;
  }
};

const normalizeToSearch = (str: string) =>
  str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .trim()
    .toLowerCase();
