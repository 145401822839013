import { useTawk } from "@/hooks/useTawk";
import { useCloudHumans } from "@/hooks/useCloudHumans";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { LoggedInUserData } from "@/contexts/AuthContext";

export const useCustomerHelpChat = () => {
  const {
    showWidget: showTawk,
    loadTawk: loadTawk,
    addProperties: addTawkProperties,
    addTags: addTawkTags,
  } = useTawk();
  const {
    showWidget: showCloudHumans,
    loadCloudHumans: loadCloudHumans,
    updateCloudHumansClientContact: updateCloudHumansClientContact,
  } = useCloudHumans();
  const flags = useFeatureFlags();
  const enabledPlans = flags.cloudHumansChatEnabled.split(",");

  const loadCustomerHelpChat = async ({
    user,
    isFreeUser,
    planType,
    isActivePlan,
  }: {
    user: LoggedInUserData;
    isFreeUser: boolean;
    planType?: string;
    isActivePlan: boolean;
  }) => {
    const isCloudHumansEnabled = enabledPlans.includes(planType || "");

    if (isCloudHumansEnabled) {
      loadCloudHumans(user);
      await updateCloudHumansClientContact({
        userName: user?.userName,
        planType: planType,
        email: user?.userEmail,
        isActivePlan,
      });
    } else {
      loadTawk(user, isFreeUser);
      addTawkProperties({ userid: user?.userId, companyid: user?.companyId, currentplan: planType });
      addTawkTags(planType ? [planType] : []);
    }
  };

  const showCustomerHelpChat = (planType: string) => {
    const isCloudHumansEnabled = enabledPlans.includes(planType);

    if (isCloudHumansEnabled) {
      showCloudHumans();
    } else {
      showTawk();
    }
  };

  return { loadCustomerHelpChat, showCustomerHelpChat };
};
