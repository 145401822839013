import { SkillForm } from "@/core/skillForms/types";
import { createLaborInitialPetitionSkillStep2 } from "./step2";
import { createLaborInitialPetitionSkillStep3 } from "./step3";

export const createLaborComplaintSkillFormV2: SkillForm = {
  skillId: "create_labor_complaint_v2",
  title: "Escrever reclamação trabalhista",
  steps: [createLaborInitialPetitionSkillStep2, createLaborInitialPetitionSkillStep3],
  lastStepSubmitButtonText: "Criar Petição Inicial",
};
