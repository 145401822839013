import { LexZapChannel } from "@/services/lexZap/types";

enum MandatoryTemplateKey {
  Notifications = "notifications",
  ErrorNotifications = "error_notifications",
  ReactivateChat = "reactivate_chat",
  InitChat = "init_chat",
}

export const getTemplateStatus = (channel: LexZapChannel) => {
  const missingTemplateKeys = Object.values(MandatoryTemplateKey).filter(
    (key) => !channel.templates.some((template) => template.templateKey === key && template.status === "approved")
  );

  if (missingTemplateKeys.length > 0) {
    return {
      color: "warning.main",
      details: `Templates faltando: ${missingTemplateKeys.join(", ")}`,
    };
  }

  return { color: "success.main", details: "Todos os templates estão aprovados." };
};
