import * as React from "react";
import { Routes, Route, useLocation, Navigate, Outlet, useNavigate } from "react-router-dom";

import { EditorPageLayout } from "@/components/EditorPageLayout";
import { Warnings } from "@/components/Warnings";
import { useAuthContext } from "@/contexts/AuthContext";
import { PaywallProvider } from "@/contexts/PaywallContext";
import { MessagesProvider } from "@/contexts/MessagesContext";
import { ThreadProvider } from "@/contexts/ThreadContext";
import { WebEditorContextProvider } from "@/contexts/WebEditorContext";
import { WebSocketProvider } from "@/contexts/WebSocketContext";
import { useCheckTermsOfUse } from "@/hooks/useCheckTermsOfUse";
import { useIdentifyUserForFlags } from "@/hooks/useIdentifyUserForFlags";
import { useLoadingHandler } from "@/hooks/useLoadingHandler";
import { ActivateAccount } from "@/pages/ActivateAccount";
import { ClientConfig } from "@/pages/ClientConfig";
import { ClientConfigChangePasswordModal } from "@/pages/ClientConfig/ClientConfigChangePasswordModal";
import { ClientConfigMyDataModal, ClientConfigMyDataModalWhatsapp } from "@/pages/ClientConfig/ClientConfigMyDataModal";
import { CreditsHistory } from "@/pages/CreditsHistory";
import { NotFound } from "@/pages/NotFound";
import { Plans } from "@/pages/Plans";
import { Plan } from "@/pages/Plans/Plan";
import { Signup } from "@/pages/Signup";
import { SignupFailure } from "@/pages/SignupFailure";
import { TermsOfUse } from "@/pages/TermsOfUse";
import { useEffect } from "react";
import { Chat } from "@//components/Chat";
import { Feedback } from "@//components/Feedback";
import { Login } from "@//components/Login";
import ConditionalLayout from "./components/ConditionalLayout";
import LoggedLayout from "./components/LoggedLayout";
import { EDITOR_ROUTES_PREFIX, AUTHENTICATED_ROUTES_PREFIX, ROUTE_PATHS } from "./routePaths";
import { SessionSettingsToast } from "@/components/SessionSettingsToast";
import { useUserGuiding } from "@/hooks/useUserGuiding";
import { SessionSettings } from "@/pages/SessionSettings";
import { InternalPaymentPage } from "@/pages/Plans/InternalPaymentPage";
import { PaymentProvider } from "@/contexts/PaymentContext";
import { Referral } from "@/pages/Referral";
import { Home } from "@/pages/Home";
import { HeapService } from "@/services/heap";
import { ClarityService } from "@/services/clarity";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { logger } from "@/core/logger";
import { Welcome } from "@/pages/Welcome";
import { InsideSalesQuestionsPage } from "@/pages/InsideSalesQuestions";
import { CallWithSpecialistPage } from "@/pages/CallWithSpecialist";
import { WhatsappAssistantLayout } from "@/pages/WhatsappAssistantLayout";
import { ManageWhatsappUsers } from "@/pages/admin/ManageWhatsappUsers";
import { CreateChat } from "@//components/Chat/CreateChat";
import { useDocumentOnboarding } from "@/hooks/skills/useDocumentOnboarding";
import { GoogleSignUpWithPhone } from "@/pages/GoogleSignUpWithPhone";
import { ScreenSizeChecker } from "@/components/ScreenSizeChecker";
import { WhatsappDlqEvents } from "@/pages/admin/WhatsappDlqEvents";
import { CompanyRole } from "@/contexts/AuthContext/types";
import { LegalProceedingMonitoringPage } from "@/pages/LegalProceedingMonitoring";
import { MyTeam } from "@/pages/MyTeam";
import { CasesLayout } from "@/pages/CasesLayout";
import { CasePage } from "../pages/CasePage";
import { TicketPage } from "../pages/TicketPage";
import { DocumentEditorModal } from "@/pages/CasePage/components/CaseDetails/components/DocumentEditorModal";
import { ExportContactsGuide } from "@/pages/ExportContactsGuide";
import { DevToolsPage } from "@/pages/DevToolsPage";
import { CreateCase } from "@/components/Cases";
import { UsageHistory } from "@/pages/UsageHistory";
import { Threads } from "@/components/Threads";
import { LegalProceedingImportingByOABModal } from "@/pages/WhatsappAssistantCases/components/LegalProceedingImportingByOABModal";
import { SignupSuccess } from "@/pages/SignupSuccess";

export const IndexRoutes = () => {
  const { loading, user } = useAuthContext();
  const navigate = useNavigate();
  const location = useLocation();
  useIdentifyUserForFlags(user);
  useLoadingHandler({ loading });
  const flags = useFeatureFlags();
  const { isOnboarding } = useDocumentOnboarding();

  const previousLocation = location.state?.previousLocation;
  const isLoggedRoute = location.pathname.startsWith(AUTHENTICATED_ROUTES_PREFIX);

  useEffect(() => {
    if (isOnboarding) {
      navigate(ROUTE_PATHS.WELCOME);
    }
  }, [isOnboarding]);

  if (loading) return null;

  if (!isLoggedRoute && user) {
    return <Navigate to={location.state?.redirectTo ?? ROUTE_PATHS.HOME} />;
  }

  if (isLoggedRoute && !user) {
    return null;
  }

  return (
    <ConditionalLayout>
      <Routes location={previousLocation || location}>
        {/* @TODO remover isso quando tirarmos o /taskpane.html como entrada inicial do addin */}
        <Route path="/taskpane.html" element={<Navigate to={ROUTE_PATHS.LOGIN} />} />

        <Route path={ROUTE_PATHS.LOGIN} element={<Login />} />
        <Route path={ROUTE_PATHS.SIGNUP} element={<Signup />} />
        <Route path={ROUTE_PATHS.ACTIVATE_ACCOUNT} element={<ActivateAccount />} />
        <Route path={ROUTE_PATHS.SIGNUP_SUCCESS} element={<SignupSuccess />} />
        <Route path={ROUTE_PATHS.ADD_PHONE} element={<GoogleSignUpWithPhone />} />
        <Route path={ROUTE_PATHS.SIGNUP_FAILURE} element={<SignupFailure />} />
        <Route path={ROUTE_PATHS.ONBOARDING_QUESTIONS} element={<InsideSalesQuestionsPage />} />

        <Route path={AUTHENTICATED_ROUTES_PREFIX} element={<LoggedWrapper />}>
          <Route path={EDITOR_ROUTES_PREFIX} element={<EditorPageLayout />}>
            <Route path={ROUTE_PATHS.CHAT} element={<CreateChat />} />
            <Route path={ROUTE_PATHS.THREAD} element={<Chat />} />
            <Route path={ROUTE_PATHS.FEEDBACK} element={<Feedback />} />
          </Route>

          <Route path={ROUTE_PATHS.CLIENT_CONFIG} element={<ClientConfig />}>
            <Route path={ROUTE_PATHS.CLIENT_CONFIG_MY_DATA} element={<ClientConfigMyDataModal />} />
            <Route path={ROUTE_PATHS.CLIENT_CONFIG_MY_DATA_WHATSAPP} element={<ClientConfigMyDataModalWhatsapp />} />
            <Route path={ROUTE_PATHS.CLIENT_CONFIG_CHANGE_PASSWORD} element={<ClientConfigChangePasswordModal />} />
          </Route>

          {flags.legalProceedingsPageEnabled && (
            <>
              <Route path={ROUTE_PATHS.LEGAL_PROCEEDINGS_MONITORING} element={<LegalProceedingMonitoringPage />} />
            </>
          )}
          <Route path={ROUTE_PATHS.CLIENT_CONFIG_MY_TEAM} element={<MyTeam />} />

          {user?.companyRole === CompanyRole.ADMIN && (
            <>
              <Route path={ROUTE_PATHS.PLANS} element={<Plans />} />
              <Route path={ROUTE_PATHS.PLAN} element={<Plan />} />
              <Route path={ROUTE_PATHS.INTERNAL_PAYMENT} element={<InternalPaymentPage />} />
              <Route path={ROUTE_PATHS.CREDITS_HISTORY} element={<CreditsHistory />} />
            </>
          )}

          <Route path={ROUTE_PATHS.TERMS_OF_USE} element={<TermsOfUse />} />
          <Route path={ROUTE_PATHS.REFERRAL} element={<Referral />} />
          <Route path={ROUTE_PATHS.HOME} element={<Home />} />

          {flags.newUsageHistoryScreen ? (
            <Route path={ROUTE_PATHS.THREAD_HISTORY} element={<UsageHistory />} />
          ) : (
            <Route
              path={ROUTE_PATHS.THREAD_HISTORY}
              element={<Threads component={"SCREEN"} onClose={() => navigate(-1)} />}
            />
          )}
          <Route path={ROUTE_PATHS.SESSION_SETTINGS} element={<SessionSettings />} />
          <Route path={ROUTE_PATHS.CALL_WITH_SPECIALIST} element={<CallWithSpecialistPage />} />
          <Route path={ROUTE_PATHS.WELCOME} element={<Welcome />} />
          <Route path={ROUTE_PATHS.DEV_TOOLS} element={<DevToolsPage />} />

          {flags.whatsAppAssistantEnabled && (
            <>
              <Route path={ROUTE_PATHS.CASES} element={<CasesLayout />} />
              <Route path={ROUTE_PATHS.ACTIVE_CASES} element={<CasesLayout />} />
              <Route path={ROUTE_PATHS.ARCHIVED_CASES} element={<CasesLayout />} />
              <Route
                path={ROUTE_PATHS.IMPORT_LEGAL_PROCEEDINGS_BY_OAB}
                element={<LegalProceedingImportingByOABModal />}
              />
              <Route path={ROUTE_PATHS.CREATE_CASE} element={<CreateCase />} />
              <Route path={ROUTE_PATHS.WHATSAPP_ASSISTANT} element={<WhatsappAssistantLayout />} />
              <Route path={ROUTE_PATHS.WHATSAPP_ASSISTANT_CHATS} element={<WhatsappAssistantLayout />} />
              <Route id="caseDetails" path={ROUTE_PATHS.CASE_DETAILS} element={<CasePage />}>
                <Route
                  id="caseDocumentDetails"
                  path={ROUTE_PATHS.CASE_DOCUMENT_DETAILS}
                  element={<DocumentEditorModal />}
                />
              </Route>
              <Route id="ticketDetails" path={ROUTE_PATHS.TICKET_DETAILS} element={<TicketPage />} />
              <Route path={ROUTE_PATHS.WHATSAPP_ASSISTANT_EXPORT_CONTACTS_GUIDE} element={<ExportContactsGuide />} />
            </>
          )}

          {!!user?.isAdmin && (
            <>
              <Route path={ROUTE_PATHS.WHATSAPP_USERS} element={<ManageWhatsappUsers />} />
              <Route path={ROUTE_PATHS.WHATSAPP_DLQ_EVENTS} element={<WhatsappDlqEvents />} />
            </>
          )}

          <Route path="*" element={<NotFound />} />
        </Route>

        <Route path="*" element={<Navigate to={ROUTE_PATHS.LOGIN} />} />
      </Routes>
      {previousLocation && !flags.newUsageHistoryScreen && (
        <Routes>
          <Route
            path={ROUTE_PATHS.THREAD_HISTORY}
            element={<Threads component={"MODAL"} onClose={() => navigate(previousLocation)} />}
          />
        </Routes>
      )}
    </ConditionalLayout>
  );
};

const LoggedWrapper = () => {
  useCheckTermsOfUse();
  const flags = useFeatureFlags();
  const { user } = useAuthContext();
  const userGuiding = useUserGuiding();

  useEffect(() => {
    if (user) {
      HeapService.identify(user.userId);
      HeapService.addUserProperties({
        UserEmail: user.userEmail,
        UserID: user.userId,
        CompanyID: user.companyId,
      });
      ClarityService.addUserId(user.userId);
    }

    userGuiding.initialize(user);

    return () => {
      userGuiding.remove();
    };
  }, []);

  useEffect(() => {
    try {
      HeapService.clearEventProperties();
      HeapService.addEventProperties(flags);
    } catch (e) {
      logger.debug(`HeapService error: ${JSON.stringify(e)}`);
    }
  }, [flags]);

  return (
    <ScreenSizeChecker>
      <WebSocketProvider>
        <PaymentProvider>
          <ThreadProvider>
            <WebEditorContextProvider>
              <PaywallProvider>
                <MessagesProvider>
                  <LoggedLayout>
                    <Warnings />
                    <SessionSettingsToast />
                    <Outlet />
                  </LoggedLayout>
                </MessagesProvider>
              </PaywallProvider>
            </WebEditorContextProvider>
          </ThreadProvider>
        </PaymentProvider>
      </WebSocketProvider>
    </ScreenSizeChecker>
  );
};
