import { ChatFilters } from "../../components/FilterModal/types";

export const calculateFilterChanges = ({
  filters,
  defaultFilters,
}: {
  filters: ChatFilters;
  defaultFilters: ChatFilters;
}) => {
  let changes = 0;

  if (filters.ticketStatus.open.abandoned !== defaultFilters.ticketStatus.open.abandoned) {
    changes++;
  }
  if (filters.ticketStatus.open.AIService !== defaultFilters.ticketStatus.open.AIService) {
    changes++;
  }
  if (filters.ticketStatus.open.redirectedHuman !== defaultFilters.ticketStatus.open.redirectedHuman) {
    changes++;
  }
  if (filters.ticketStatus.open.initialTriage !== defaultFilters.ticketStatus.open.initialTriage) {
    changes++;
  }

  if (filters.ticketStatus.closed !== defaultFilters.ticketStatus.closed) {
    changes++;
  }

  if (filters.answered !== defaultFilters.answered) {
    changes++;
  }

  if (filters.caseStatus !== defaultFilters.caseStatus) {
    changes++;
  }

  if (filters.responsibles.length !== defaultFilters.responsibles.length) {
    changes++;
  }

  if (filters.areas.length !== defaultFilters.areas.length) {
    changes++;
  }

  return changes;
};
