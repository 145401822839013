import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { Case } from "@/hooks/lexZap/types";
import { DateTime } from "luxon";
import { Button } from "@/components/Button";
import { getCasePath } from "@/routes/routePaths";
interface CaseCardProps {
  caseData: Case;
}

export const CaseCard = ({ caseData }: CaseCardProps) => {
  return (
    <Box
      sx={{
        borderRadius: "8px",
        border: "1px solid",
        borderColor: "primary.light",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        backgroundColor: "orange.light.5",
      }}
    >
      <Box>
        <Typography variant="subtitle1">{caseData.name}</Typography>
      </Box>

      <Box>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 700,
            color: "grey.800",
          }}
        >
          Criado em
        </Typography>
        <Typography variant="body1">
          {DateTime.fromJSDate(new Date(caseData.createdAt)).toFormat("dd/MM/yyyy")}
        </Typography>
      </Box>

      <Link href={getCasePath({ caseId: caseData.id })}>
        <Button variant="contained" color="primary" fullWidth>
          Acessar caso
        </Button>
      </Link>
    </Box>
  );
};
