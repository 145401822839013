import React, { useState } from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { LexZapChat, Ticket } from "@/hooks/lexZap/types";
import { scrollbarStyles } from "@/theme/mixins/scrollbar";
import { TicketCard } from "./components/TicketCard";
import { CloseTicketModal } from "../ChatList/components/CloseTicketModal";
import { useCloseTicket } from "@/hooks/lexZap/useCloseTicket";
import { WebToast } from "@/components/core/Toast";
import { logger } from "@/core/logger";
import { CaseCard } from "./components/CaseCard";

interface ChatHistoryProps {
  chat: LexZapChat;
  onClose: () => void;
}

export const ChatHistory = ({ chat, onClose }: ChatHistoryProps) => {
  const [ticketToClose, setTicketToClose] = useState<Ticket | null>(null);
  const { mutateAsync: closeTicket, isPending: isClosingTicket } = useCloseTicket();

  const handleCloseTicket = async () => {
    if (!ticketToClose) return;
    try {
      await closeTicket({ ticketId: ticketToClose.id, applicantPhoneNumber: ticketToClose.applicantPhoneNumber });
      WebToast.success("Atendimento encerrado com sucesso");
    } catch (error) {
      WebToast.error("Erro ao encerrar atendimento");
      logger.error("Error closing ticket", { error });
    }

    closeTicketModal();
  };

  const closeTicketModal = () => {
    setTicketToClose(null);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: "common.white",
          height: "100%",
          display: "grid",
          gridTemplateRows: "min-content minmax(0, 1fr)",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, px: 3, pt: 2, pb: 1 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h3" sx={{ fontWeight: "400", color: "text.secondary" }}>
              Histórico
            </Typography>

            <IconButton
              onClick={onClose}
              sx={{
                m: -1,
              }}
            >
              <CloseIcon sx={{ width: "24px", height: "24px", color: "text.secondary" }} />
            </IconButton>
          </Box>

          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            Aqui estão centralizados os atendimentos feitos e gerenciados pela Lexter.
          </Typography>
        </Box>

        <Box
          sx={{
            width: "100%",
            height: "100%",
            ...scrollbarStyles,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              pt: 3,
              pb: 4,
              px: 1,
              "& > *:not(:last-child)": {
                borderBottom: "1px solid",
                borderColor: "divider",
              },
            }}
          >
            {chat.tickets?.map((ticket) => (
              <TicketCard key={ticket.id} ticket={ticket} chat={chat} onCloseTicket={() => setTicketToClose(ticket)} />
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              px: 2,
            }}
          >
            {chat.cases?.map((caseData) => <CaseCard key={caseData.id} caseData={caseData} />)}
          </Box>
        </Box>
      </Box>

      <CloseTicketModal
        onConfirm={handleCloseTicket}
        isOpen={!!ticketToClose}
        onCancel={closeTicketModal}
        isLoading={isClosingTicket}
      />
    </>
  );
};
