import { ActionId, LegalPieceMacroType, LegalPieceMicroType } from "@/contexts/MessagesContext";
import { CreditPerSkill, Skill } from "@/core/skills/types";
import { base64ToFile } from "@/utils/base64ToFile";
import { fileToUploadingFile } from "@/utils/fileToUploadingFile";
import { Type } from "@sinclair/typebox";
import { DateTime } from "luxon";
import { v4 as uuidV4 } from "uuid";

const inputSchema = Type.Object({
  area: Type.String(),
  document_name: Type.String(),
  contestation_file: Type.String(),
  client: Type.String(),
  case_breakdown: Type.Optional(Type.String()),
  theses: Type.Optional(Type.String()),
  evidence_text: Type.Optional(Type.String()),
  requests: Type.Optional(Type.String()),
});

const actionId = ActionId.CREATE_ONE_PIECE;

export const createReplySkill = {
  id: "create_reply",
  name: "Escrever réplica",
  credits: CreditPerSkill.ADVANCED_SKILL,
  inputSchema,
  tags: ["litigation"],
  actionId,
  type: "message",
  flag: "skillCreateReplyV1Enabled",
  getMessage: (input) => {
    const contestation_file = input.contestation_file.startsWith("data:")
      ? fileToUploadingFile(base64ToFile(input.contestation_file))
      : undefined;

    if (!contestation_file) {
      throw new Error("Contestation file not found");
    }

    return {
      id: uuidV4(),
      type: "FLOW",
      direction: "SENT",
      author: "Current User",
      date: DateTime.now(),
      status: "READ",
      text: "Criar Réplica",
      actions: [
        {
          id: actionId,
          text: "Criar Réplica",
          client: input.client,
          legal_piece_macro_type: LegalPieceMacroType.RESPONSE,
          legal_piece_micro_type: input.document_name || LegalPieceMicroType.REPLICATION,
          area: input.area,
          case_breakdown: input.case_breakdown
            ? {
                source: "TEXT",
                text: input.case_breakdown,
              }
            : undefined,
          theses: input.theses,
          evidence_text: input.evidence_text,
          requests: input.requests,
          reference_piece: {
            source: "FILE",
            file: contestation_file,
          },
        },
      ],
      files: [contestation_file],
    };
  },
} satisfies Skill<typeof inputSchema>;
