import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useApi } from "@/hooks/useApi";
import { getMessagesQueryKey } from "../useLexZapMessages";
import { CaseMessage, CaseMessageType, LexZapChat } from "../types";
import { SendLexZapMessageParams } from "@/hooks/useApi/types";
import { useAuthContext } from "@/contexts/AuthContext";
import { v4 as uuidV4 } from "uuid";
import { getChatsQueryKey } from "../useLexZapChats";
import { getTemplateText } from "./templates";
import { useCallback } from "react";

export const useSendLexZapMessage = () => {
  const queryClient = useQueryClient();
  const { sendLexZapMessage } = useApi();
  const { user } = useAuthContext();

  const setFailedStatusMessage = useCallback(
    ({ messageId, applicantPhoneNumber }: { messageId: string; applicantPhoneNumber: string }) => {
      const messageToUpdate = queryClient
        .getQueryData<CaseMessage[]>(getMessagesQueryKey({ applicantPhoneNumber }))
        ?.find((message) => message.id === messageId);

      if (!messageToUpdate) return;

      messageToUpdate.statusUpdateTimestamp = {
        failed: Date.now(),
      };
      messageToUpdate.isSending = false;

      queryClient.setQueryData<CaseMessage[]>(getMessagesQueryKey({ applicantPhoneNumber }), (oldMessages = []) =>
        oldMessages.map((message) => (message.id === messageId ? messageToUpdate : message))
      );
    },
    [queryClient]
  );

  const sendMessageParamsToPreviewMessage = useCallback(
    ({ requestId, message, applicantPhoneNumber }: SendLexZapMessageParams): CaseMessage => {
      return {
        chatId: "",
        from: "",
        fromAssistant: false,
        lawyerId: user?.userId,
        fromLawyer: true,
        id: requestId,
        to: "",
        body:
          message.type === "template"
            ? getTemplateText({
                templateMessage: message,
              })
            : message.body,
        media: message.type === "template" ? undefined : message.media,
        type: message.type as CaseMessageType,
        isForwarded: false,
        timestamp: Date.now(),
        isSending: true,
        statusUpdateTimestamp: {},
        applicantPhoneNumber,
        requestId,
      };
    },
    [user?.userId]
  );

  const createPreviewMessage = useCallback(
    ({ applicantPhoneNumber, message, requestId }: SendLexZapMessageParams) => {
      const newMessage: CaseMessage = sendMessageParamsToPreviewMessage({ applicantPhoneNumber, message, requestId });

      queryClient.setQueryData<CaseMessage[]>(getMessagesQueryKey({ applicantPhoneNumber }), (oldMessages = []) => {
        const existingMessage = oldMessages.find((message) => message.id === newMessage.id);
        if (existingMessage) {
          return oldMessages.map((message) => (message.id === newMessage.id ? newMessage : message));
        }
        return [...oldMessages, newMessage];
      });

      queryClient.setQueryData<LexZapChat[]>(getChatsQueryKey(), (oldChats = []): LexZapChat[] => {
        const chatExists = oldChats.some((chat) => chat.endClientNumber === applicantPhoneNumber);

        if (!chatExists) {
          return [
            ...oldChats,
            {
              endClientNumber: applicantPhoneNumber,
              lastLawyerMessageDate: newMessage.timestamp,
            },
          ];
        }

        return oldChats.map((chat) =>
          chat.endClientNumber === applicantPhoneNumber
            ? { ...chat, lastLawyerMessageDate: newMessage.timestamp, unansweredMessagesCount: 0 }
            : chat
        );
      });

      return newMessage;
    },
    [queryClient, sendMessageParamsToPreviewMessage]
  );

  const setUploadingProgress = useCallback(
    ({
      applicantPhoneNumber,
      messageId,
      progress,
    }: {
      applicantPhoneNumber: string;
      messageId: string;
      progress: number;
    }) => {
      queryClient.setQueryData<CaseMessage[]>(getMessagesQueryKey({ applicantPhoneNumber }), (oldMessages = []) => {
        return oldMessages.map((message) =>
          message.id === messageId && message.media
            ? { ...message, media: { ...message.media, uploadingProgress: progress } }
            : message
        );
      });
    },
    [queryClient]
  );

  const { mutate, ...mutation } = useMutation({
    mutationKey: ["sendLexZapMessage"],
    mutationFn: async (params: SendLexZapMessageParams) => {
      return sendLexZapMessage(params);
    },
    onMutate: async ({ applicantPhoneNumber, message, requestId }) => {
      await queryClient.cancelQueries({ queryKey: getMessagesQueryKey({ applicantPhoneNumber }) });

      const newMessage: CaseMessage = createPreviewMessage({ applicantPhoneNumber, message, requestId });

      return { newMessage };
    },
    onError: (_error, { applicantPhoneNumber }, context) => {
      if (context?.newMessage) {
        const { newMessage } = context;

        setFailedStatusMessage({ messageId: newMessage.id, applicantPhoneNumber });
      }
    },
    onSuccess: async (data, { applicantPhoneNumber }, { newMessage }) => {
      const tempMessageId = newMessage.id;

      if (!data) return;

      newMessage.id = data.message.id;
      newMessage.isSending = false;

      queryClient.setQueryData<CaseMessage[]>(getMessagesQueryKey({ applicantPhoneNumber }), (oldMessages = []) =>
        oldMessages.map((message) => (message.id === tempMessageId ? newMessage : message))
      );
    },
  });

  return {
    ...mutation,
    mutate: (params: Omit<SendLexZapMessageParams, "requestId"> & { requestId?: string }) =>
      mutate({ ...params, requestId: params.requestId || uuidV4() }),
    setUploadingProgress,
    createPreviewMessage,
    setFailedStatusMessage,
  };
};
