export const getSignUpUtm = () => {
  const signUpUtm: Record<string, string> = {
    source: "word-addin",
    campaign: "organic",
  };

  const url = window.location.href;

  const documentUrl = new URL(url);
  const searchParams = documentUrl.searchParams;

  searchParams.forEach((value, key) => {
    if (key.startsWith("utm_") && key.length > 4) {
      const cleanedKey = key.substring(4);
      signUpUtm[cleanedKey] = value;
    }
  });

  return signUpUtm;
};
