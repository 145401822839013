export const getParam = (param: string) => {
  const url = window.location.href;

  const documentUrl = new URL(url);
  const searchParams = documentUrl.searchParams;

  let find: string | null = null;
  searchParams.forEach((value, key) => {
    if (key.toLowerCase() === param.toLowerCase()) {
      find = value;
    }
  });
  return find;
};
