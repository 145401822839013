import { datadogLogs, StatusType } from "@datadog/browser-logs";
import { env } from "@/constants/environment";

export function init() {
  const serviceBaseName = "lexter-copilot-addin-web";

  if (env.PUBLIC_DATADOG_CLIENT_ID) {
    //Documentation: https://docs.datadoghq.com/logs/log_collection/javascript/
    datadogLogs.init({
      clientToken: env.PUBLIC_DATADOG_CLIENT_ID || "",
      site: "datadoghq.com",
      sessionSampleRate: 100,
      service: `${serviceBaseName}`,
      env: env.DATADOG_ENV,
      version: env.DATADOG_VERSION,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: "all",
      forwardReports: "all",
    });

    datadogLogs.logger.setHandler("http");
    datadogLogs.logger.setLevel("debug");
  }
}

export function setProfile(id: string, companyId: string, name: string, email?: string) {
  if (env.PUBLIC_DATADOG_CLIENT_ID) {
    datadogLogs.setUser({
      id,
      name,
      companyId,
      email: email || "-",
    });
  }
}

export function clearProfile() {
  if (env.PUBLIC_DATADOG_CLIENT_ID) {
    datadogLogs.clearUser();
  }
}

export function debug(message: string) {
  log(message, undefined, "debug");
}

export function info(message: string, messageContext?: object) {
  log(message, messageContext, "info");
}

export function warn(message: string, error?: unknown) {
  log(message, undefined, "warn", error);
}

export function error(message: string, error?: unknown) {
  log(message, undefined, "error", error);
}

function log(message: string, messageContext?: object, status?: StatusType, error?: unknown) {
  if (env.PUBLIC_DATADOG_CLIENT_ID) {
    datadogLogs.logger.log(message, messageContext, status, error instanceof Error ? error : undefined);
  } else {
    // eslint-disable-next-line no-console
    console.log(message, messageContext, status, error instanceof Error ? error : undefined);
  }
}

export const logger = {
  init,
  setProfile,
  clearProfile,
  debug,
  info,
  warn,
  error,
};
