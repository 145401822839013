import React, { useState, useEffect, useMemo } from "react";
import { Box, Tooltip } from "@mui/material";
import { Link, LinkProps, useLocation } from "react-router-dom";
import HomeIcon from "@/assets/svgs/home.svg?react";
import HistoryIcon from "@/assets/svgs/history.svg?react";
import ConfigIcon from "@/assets/svgs/config.svg?react";
import LexterLogoIcon from "@/assets/svgs/lexter-logo.svg?react";
import { SvgIconComponent } from "@mui/icons-material";
import { ExitConfirmDialog } from "@/components/ExitConfirmDialog";
import { Avatar } from "./Avatar";
import { Help } from "./HelpMenu";
import { useMessagesContext } from "@/contexts/MessagesContext";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { useDocumentOnboarding } from "@/hooks/skills/useDocumentOnboarding";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { FolderOutlined as FolderOutlinedIcon, WhatsApp as WhatsAppIcon } from "@mui/icons-material";
import { useWebEditorContext } from "@/contexts/WebEditorContext";
import { logger } from "@/core/logger";
import { AuthService } from "@/services/auth";
import { Config } from "@/config/Config";

type MenuItem = {
  name: string;
  label: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | SvgIconComponent;
  location: LinkProps["to"];
  hidden?: boolean;
  state?: LinkProps["state"];
};

export const LateralMenu = () => {
  const config = Config.getConfig();
  const { whatsAppAssistantEnabled, newUsageHistoryScreen } = useFeatureFlags();
  const location = useLocation();
  const [exitModalParams, setExitModalParams] = useState<{
    title: string;
    message: string;
    action: () => void;
    actionType: "NEW" | "RESTART" | "EXISTING" | "EXIT";
  }>();
  const { waitingForResponse } = useMessagesContext();
  const { isOnboarding } = useDocumentOnboarding();
  const { hasUnsavedTabs } = useWebEditorContext();
  const [isMenuVisible, setMenuVisible] = useState(true);

  const exitConfirmationMessage = useMemo(() => {
    if (hasUnsavedTabs) {
      return "Você tem documentos não salvos. Deseja sair sem salvar?";
    } else if (!!waitingForResponse) {
      return "Você poderá retornar para esta sessão para consultar o resultado de ações em andamento ou informações salvas previamente.";
    }
    return undefined;
  }, [hasUnsavedTabs, waitingForResponse]);

  const handleLogout = () => {
    if (!exitConfirmationMessage) {
      void AuthService.logout();
      return;
    }

    if (hasUnsavedTabs) {
      logger.warn("User tried to logout with unsaved tabs");
    }

    setExitModalParams({
      title: "Sair da conta",
      message: exitConfirmationMessage,
      action: () => AuthService.logout(),
      actionType: "EXIT",
    });
  };

  const allItems = [
    {
      name: "home",
      label: "Início",
      icon: HomeIcon,
      location: ROUTE_PATHS.HOME,
    },
    {
      name: "cases",
      label: "Casos",
      icon: FolderOutlinedIcon,
      location: ROUTE_PATHS.CASES,
      hidden: !whatsAppAssistantEnabled,
    },
    {
      name: "whatsapp",
      label: "Assistente WhatsApp",
      icon: WhatsAppIcon,
      location: ROUTE_PATHS.WHATSAPP_ASSISTANT,
      hidden: !whatsAppAssistantEnabled,
    },
    {
      name: "threads",
      label: "Histórico",
      icon: HistoryIcon,
      location: ROUTE_PATHS.THREAD_HISTORY,
      state: newUsageHistoryScreen
        ? undefined
        : location.state?.previousLocation
          ? undefined
          : { previousLocation: location },
    },
    {
      name: "settings",
      label: "Configurações",
      icon: ConfigIcon,
      location: ROUTE_PATHS.CLIENT_CONFIG,
    },
  ] satisfies MenuItem[];

  const items = allItems.filter((item) => !item.hidden);

  useEffect(() => {
    if (isOnboarding) {
      setMenuVisible(false);
    } else {
      setMenuVisible(true);
    }
  }, [isOnboarding]);

  return (
    <>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          width: "3.5rem",
          height: "100%",
          backgroundColor: "common.white",
          color: "common.black",
          paddingTop: 2,
          paddingBottom: 3,
          boxShadow: "0px 1px 3px 0px #0000001F",
          zIndex: "appBar",
          transition: "all 0.5s ease",
        }}
      >
        {isOnboarding ? (
          <Box
            sx={{
              color: "primary.main",
            }}
          >
            <LexterLogoIcon height={32} />
          </Box>
        ) : (
          <Box
            sx={{
              color: "primary.main",
            }}
            component={Link}
            to={ROUTE_PATHS.HOME}
          >
            <LexterLogoIcon height={32} />
          </Box>
        )}

        {isMenuVisible && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                gap: 1.5,
              }}
            >
              {items.map((item, index) => (
                <LateralMenuItem key={index} {...item} />
              ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
                // Necessary to apply bottom margin because of the Tawk.to widget
                ...(config.isProd && { marginBottom: "60px" }),
              }}
            >
              <Avatar onLogout={handleLogout} />
              <Help />
            </Box>
          </>
        )}
      </Box>

      <ExitConfirmDialog
        open={!!exitModalParams}
        title={exitModalParams?.title || ""}
        message={exitModalParams?.message}
        onConfirm={() => {
          setExitModalParams(undefined);
          exitModalParams?.action();
        }}
        action={exitModalParams?.actionType || "RESTART"}
        onCancel={() => setExitModalParams(undefined)}
      />
    </>
  );
};

const LateralMenuItem = ({ location, state, label, icon: Icon }: MenuItem) => {
  const isActive = window.location.pathname.startsWith(location.toString());

  return (
    <Tooltip
      title={label}
      arrow
      placement="right"
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 10],
              },
            },
          ],
        },
      }}
    >
      <Box
        component={Link}
        to={location}
        state={state}
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: isActive ? "secondary.light" : undefined,
          borderLeftWidth: "2px",
          borderLeftStyle: "solid",
          borderLeftColor: isActive ? "primary.main" : "transparent",
          color: isActive ? "primary.main" : "common.black",
          paddingY: 1.5,
        }}
      >
        <Box
          component={Icon}
          height={24}
          sx={{
            marginLeft: "-2px",
            cursor: "pointer",
          }}
        />
      </Box>
    </Tooltip>
  );
};
