import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useApi } from "@/hooks/useApi";
import axios from "axios";
import { CaseMessage, LexZapChat } from "../types";
import { useSocket } from "@/contexts/WebSocketContext";
import { MessageReceivedUpdate, WhatsappUpdateType } from "@/contexts/WebSocketContext/types/whatsapp.types";
import { useImpersonation } from "@/hooks/useImpersonation";
import { AdminLexZapService } from "@/services/lexZap";
import { useLexZapCompany } from "../useLexZapCompany";
import { getChatsQueryKey } from "../useLexZapChats";

export const getMessagesQueryKey = ({ applicantPhoneNumber }: { applicantPhoneNumber?: string }) => [
  "lexZapMessages",
  applicantPhoneNumber,
];

interface UseLexZapMessagesProps {
  applicantPhoneNumber?: string;
  disabled?: boolean;
}

export const useLexZapMessages = ({ applicantPhoneNumber, disabled }: UseLexZapMessagesProps) => {
  const queryClient = useQueryClient();
  const { getLexZapMessages } = useApi();
  const { impersonatedUser } = useImpersonation();
  const { data: connectionData } = useLexZapCompany();
  const hasWhatsapp = !!connectionData?.phoneNumber;

  useSocket({
    onWhatsAppUpdate: (update) => {
      if (impersonatedUser) {
        return;
      }

      if (update.type === WhatsappUpdateType.MESSAGE_RECEIVED || update.type === WhatsappUpdateType.MESSAGE_CHANGED) {
        const messageApplicantNumber = update.message.applicantPhoneNumber;

        const mergeNewMessage = (oldMessage: CaseMessage, newMessage: CaseMessage): CaseMessage => {
          return {
            ...oldMessage,
            ...newMessage,
            statusUpdateTimestamp: {
              ...oldMessage.statusUpdateTimestamp,
              ...newMessage.statusUpdateTimestamp,
            },
          };
        };

        queryClient.setQueryData<CaseMessage[]>(
          getMessagesQueryKey({ applicantPhoneNumber: messageApplicantNumber }),
          (oldMessages = []) => {
            const existentMessage = oldMessages.find(
              (msg) =>
                msg.id === update.message.id ||
                (!!msg.requestId && (update as MessageReceivedUpdate).requestId === msg.requestId)
            );
            if (existentMessage) {
              return oldMessages.map((oldMessage) =>
                oldMessage.id === existentMessage.id ? mergeNewMessage(oldMessage, update.message) : oldMessage
              );
            } else if (update.type === WhatsappUpdateType.MESSAGE_CHANGED) {
              return oldMessages;
            }
            return [...oldMessages, update.message].sort((a, b) => a.timestamp - b.timestamp);
          }
        );

        const chats = queryClient.getQueryData<LexZapChat[]>(getChatsQueryKey());
        const chat = chats?.find((chat) => chat.endClientNumber === messageApplicantNumber);

        if (!chat?.hasMessagesFetched) {
          void queryClient.invalidateQueries({
            queryKey: getMessagesQueryKey({ applicantPhoneNumber: messageApplicantNumber }),
          });
        }
      }
    },
  });

  return useQuery({
    queryKey: getMessagesQueryKey({ applicantPhoneNumber }),
    queryFn: async () => {
      if (!applicantPhoneNumber) {
        return Promise.resolve([]);
      }

      let messagesResult: CaseMessage[];
      if (impersonatedUser) {
        messagesResult = (
          await AdminLexZapService.getLexZapMessages({ applicantPhoneNumber, companyId: impersonatedUser.companyId })
        ).messages;
      } else {
        messagesResult = (await getLexZapMessages({ applicantPhoneNumber })).messages;
      }

      const messages = await Promise.all(
        messagesResult.map(async (message) => {
          if (!message?.media || !message.media?.url) {
            return message;
          }

          try {
            const { data } = await axios.get(message.media?.url, {
              responseType: "arraybuffer",
              headers: {
                "Content-Type": message.media.mimeType,
              },
            });

            const blob = new Blob([data], {
              type: message.media.mimeType,
            });

            const url = URL.createObjectURL(blob);

            return {
              ...message,
              media: {
                ...message.media,
                url,
              },
            };
          } catch {
            return {
              ...message,
              media: {
                ...message.media,
                error: true,
              },
            };
          }
        })
      );

      queryClient.setQueryData<LexZapChat[]>(getChatsQueryKey(), (oldChats = []) => {
        return oldChats.map((oldChat) =>
          oldChat.endClientNumber === applicantPhoneNumber ? { ...oldChat, hasMessagesFetched: true } : oldChat
        );
      });

      return messages;
    },
    enabled: !!applicantPhoneNumber && !disabled && (hasWhatsapp || !!impersonatedUser),
    staleTime: Infinity,
  });
};
