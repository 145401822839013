import React from "react";
import { Box, Link, Typography } from "@mui/material";
import { Case, Ticket } from "@/hooks/lexZap/types";

import { useCompanyMember } from "@/hooks/company/useCompanyMember";
import { getCasePath } from "@/routes/routePaths";

export const TicketDetails = ({ ticket, ticketCase }: { ticket: Ticket; ticketCase?: Case }) => {
  const { data: createdByMember } = useCompanyMember({ memberId: ticket.createdBy });
  const { data: responsibleMember } = useCompanyMember({ memberId: ticket.responsibleUserId });

  return (
    <Box
      sx={{
        py: 2,
        gap: 4,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <TicketDetailsItem label="Iniciador" value={ticket.createdBy ? createdByMember?.name || "-" : "Cliente"} />
      {!!ticketCase && (
        <TicketDetailsItem
          label="Relacionado ao caso"
          value={
            <Link href={getCasePath({ caseId: ticketCase.id })} color="primary.main">
              {ticketCase.name}
            </Link>
          }
        />
      )}
      {!!ticket.responsibleUserId && (
        <TicketDetailsItem label="Atendido por" value={responsibleMember?.name || ticket.responsibleUserId} />
      )}
    </Box>
  );
};

const TicketDetailsItem = ({ label, value }: { label: string; value: string | React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Typography variant="body1" sx={{ color: "text.secondary", fontWeight: 700 }}>
        {label}
      </Typography>
      <Typography variant="body1" sx={{ color: "text.primary" }}>
        {value}
      </Typography>
    </Box>
  );
};
