import PaywallTrialSvg from "@/assets/svgs/paywallTrial.svg?react";
import PaywallCard from "@/assets/svgs/paywall-card.svg?react";
import { DialogModal } from "@/components/DialogModal";
import React from "react";
import { useCredits } from "@/hooks/credits/useCredits";
import { DateTime } from "luxon";
import { PlanType } from "@/hooks/credits/types";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { CreditsApiResponse } from "@/hooks/useApi/types";
import { Typography } from "@mui/material";

interface PaywallModalProps {
  open: boolean;
  onClose: () => void;
  onOpenBilling: () => void;
}

type PaywallComponentProps = {
  open: boolean;
  onClose: () => void;
  onOpenBilling: () => void;
  onlyUnlimitedPlanEnabled: boolean;
  credits?: CreditsApiResponse;
};

export const PaywallModal = ({ open, onClose, onOpenBilling }: PaywallModalProps) => {
  const { data: credits } = useCredits();
  const { onlyUnlimitedPlanEnabled } = useFeatureFlags();

  return (
    <PaywallComponent
      credits={credits}
      onlyUnlimitedPlanEnabled={onlyUnlimitedPlanEnabled}
      open={open}
      onClose={onClose}
      onOpenBilling={onOpenBilling}
    />
  );
};

export const PaywallComponent = ({
  open,
  onClose,
  onOpenBilling,
  credits,
  onlyUnlimitedPlanEnabled,
}: PaywallComponentProps) => {
  const nextBillingDate = credits?.companyPlan?.nextBillingDate;

  const nextBillingDateFormatted = nextBillingDate
    ? DateTime.fromISO(nextBillingDate).setLocale("pt-BR").toFormat("dd 'de' MMMM 'de' yyyy")
    : nextBillingDate;

  const isFreemium = credits?.companyPlan?.plan === PlanType.FREEMIUM;
  const isTrial = credits?.companyPlan?.plan === PlanType.TRIAL;
  const isFreeUser = isFreemium || isTrial;

  const nextBillingDateElement = <span style={{ whiteSpace: "nowrap" }}>{nextBillingDateFormatted}</span>;

  if (isTrial)
    return (
      <TrialPaywallModal
        open={open}
        onClose={onClose}
        onOpenBilling={onOpenBilling}
        credits={credits}
        onlyUnlimitedPlanEnabled={onlyUnlimitedPlanEnabled}
      />
    );

  return (
    <DialogModal
      sx={{
        width: "320px",
        pb: 2,
        px: 1,
      }}
      description={
        <>
          <Typography variant="h6" mb={2}>
            Seus créditos acabaram!
          </Typography>
          Os créditos disponíveis no seu plano atual chegaram ao fim, e sua próxima recarga acontece em:{" "}
          {nextBillingDateElement}.<br />
          Que tal contratar um plano maior para utilizar ainda mais o Assistente Lexter?
          {onlyUnlimitedPlanEnabled && isFreeUser && (
            <>
              <br />
              <strong>Assine agora e ganhe 30% de desconto no primeiro mês!</strong>
            </>
          )}
        </>
      }
      open={open}
      onClose={onClose}
      buttons={[
        {
          label: !isFreeUser ? "Contratar novo plano" : "Contrate agora",
          onClick: onOpenBilling,
        },
      ]}
      buttonsDirection="column"
      icon={<PaywallCard />}
    />
  );
};

const TrialPaywallModal = ({
  open,
  onClose,
  onOpenBilling,
  credits,
  onlyUnlimitedPlanEnabled,
}: PaywallComponentProps) => {
  const isFreemium = credits?.companyPlan?.plan === PlanType.FREEMIUM;
  const isTrial = credits?.companyPlan?.plan === PlanType.TRIAL;
  const isFreeUser = isFreemium || isTrial;

  return (
    <DialogModal
      sx={{
        width: "320px",
        pb: 2,
        px: 1,
      }}
      description={
        <>
          <Typography variant="h6" mb={2}>
            Seu período de teste chegou ao fim.
          </Typography>
          <span>
            Para continuar utilizando o Assistente Lexter e otimizando as tarefas do seu dia a dia com inteligência
            artificial, clique no botão abaixo e contrate um dos planos disponíveis.
          </span>
          {onlyUnlimitedPlanEnabled && isFreeUser && (
            <>
              <br />
              <strong>Assine agora e ganhe 30% de desconto no primeiro mês!</strong>
            </>
          )}
        </>
      }
      open={open}
      onClose={onClose}
      buttons={[
        {
          label: "Contrate Agora",
          onClick: onOpenBilling,
        },
      ]}
      buttonsDirection="column"
      icon={<PaywallTrialSvg />}
    />
  );
};
