import React from "react";
import {
  Autocomplete,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Button } from "@/components/Button";
import { WebToast } from "@/components/core/Toast";
import { logger } from "@/core/logger";
import { useAuthContext } from "@/contexts/AuthContext";
import { useImportLegalProceedingFromOAB } from "@/hooks/legalProceedings/useImportLegalProceedingFromOAB";
import { useHookFormMask } from "use-mask-input";

const oabSchema = z.object({
  oab: z.string().max(7, { message: "OAB inválida" }),
  uf: z.string().refine((uf) => ufs.map((uf) => uf.value).includes(uf), {
    message: "UF inválida",
  }),
});

type ImportSingleOabModalProps = {
  open: boolean;
  onClose: () => void;
  handleOpenImportingByOAB: () => void;
};

export const ImportSingleOabModal = ({ open, onClose, handleOpenImportingByOAB }: ImportSingleOabModalProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(oabSchema),
    defaultValues: { oab: "", uf: "" },
  });
  const registerWithMask = useHookFormMask(register);

  const { user } = useAuthContext();
  const { mutateAsync: importLegalProceedingFromOAB, isPending: isImporting } = useImportLegalProceedingFromOAB();

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = async (data: { oab: string; uf: string }) => {
    try {
      await importLegalProceedingFromOAB({
        userId: user!.userId,
        oabs: [
          {
            oab: data.oab,
            uf: data.uf,
          },
        ],
      });
      handleOpenImportingByOAB();
      handleClose();
    } catch (err) {
      logger.error(`Error importing legal proceeding from OAB: ${JSON.stringify(err)}`);
      WebToast.error("Erro ao importar processo por OAB. Tente novamente mais tarde.");
    }
  };

  return (
    <Dialog maxWidth="xs" open={open} onClose={handleClose} fullWidth>
      <Box sx={{ pt: 1 }}>
        <DialogTitle>
          <Typography variant="h6" fontWeight="400" component="span">
            Importar processo por OAB
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
            <TextField
              sx={{ mt: 2, width: "100%" }}
              label="Inscrição OAB"
              placeholder="0000000"
              error={!!errors.oab}
              helperText={errors.oab?.message}
              FormHelperTextProps={{
                sx: {
                  fontSize: 12,
                },
              }}
              {...registerWithMask("oab", "*{1,7}", { placeholder: "" })}
              inputProps={{ maxLength: 7 }}
            />
            <Autocomplete
              sx={{ mt: 2, width: "100%" }}
              options={ufs}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...register("uf")}
                  error={!!errors.uf}
                  helperText={errors.uf?.message}
                  label="Seccional (UF)"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="SP"
                />
              )}
              noOptionsText="UF não encontrada"
            />
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            px: 3,
            pb: 3,
          }}
        >
          <Button type="button" onClick={handleClose} disabled={isImporting}>
            Cancelar
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            isLoading={isImporting}
            loaderStyle={{ width: "85px" }}
          >
            Continuar
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export const ufs = [
  { value: "AC", label: "AC" },
  { value: "AL", label: "AL" },
  { value: "AP", label: "AP" },
  { value: "AM", label: "AM" },
  { value: "BA", label: "BA" },
  { value: "CE", label: "CE" },
  { value: "DF", label: "DF" },
  { value: "ES", label: "ES" },
  { value: "GO", label: "GO" },
  { value: "MA", label: "MA" },
  { value: "MT", label: "MT" },
  { value: "MS", label: "MS" },
  { value: "MG", label: "MG" },
  { value: "PA", label: "PA" },
  { value: "PB", label: "PB" },
  { value: "PR", label: "PR" },
  { value: "PE", label: "PE" },
  { value: "PI", label: "PI" },
  { value: "RJ", label: "RJ" },
  { value: "RN", label: "RN" },
  { value: "RS", label: "RS" },
  { value: "RO", label: "RO" },
  { value: "RR", label: "RR" },
  { value: "SC", label: "SC" },
  { value: "SP", label: "SP" },
  { value: "SE", label: "SE" },
  { value: "TO", label: "TO" },
];
