export const getGclid = () => {
  const url = window.location.href;

  const documentUrl = new URL(url);
  const searchParams = documentUrl.searchParams;

  const gclid = searchParams.get("gclid");

  return gclid || undefined;
};
