import {
  ActivateAccountPayload,
  EmailAvailabilityPayload,
  EmailAvailabilityResponse,
  GetSignupSourcePayload,
  GetSignupSourceResponse,
  SendEmailVerificationCodePayload,
  SignupPayload,
  AcceptInvitationPayload,
} from "@/hooks/useApi/types";
import { authenticationCopilotServer, copilotServer } from "@/core/api";
import { GetUserDataPayload } from "@/services/lexterCopilotAuth/types";
import { LoggedInUserData } from "@/contexts/AuthContext";

export const LexterCopilotAuthService = {
  activateAccount: async ({ email, code, recaptchaToken }: ActivateAccountPayload) => {
    return copilotServer
      .post("/signup/activateAccount", { recaptchaToken, email, code, isDesktop: false })
      .then((res) => res.data);
  },
  getEmailAvailability: async ({ email, recaptchaToken }: EmailAvailabilityPayload) => {
    return copilotServer
      .post<EmailAvailabilityResponse>("/signup/emailAvailability", {
        recaptchaToken,
        email,
        isDesktop: false,
      })
      .then((res) => res.data);
  },
  sendEmailVerificationCode: async ({ email, recaptchaToken }: SendEmailVerificationCodePayload) => {
    return copilotServer
      .post("/signup/sendEmailVerificationCode", { recaptchaToken, email, isDesktop: false })
      .then((res) => res.data);
  },
  getSignupSource: async ({ email, recaptchaToken }: GetSignupSourcePayload) => {
    return copilotServer
      .get<GetSignupSourceResponse>("/signup/source", {
        params: { recaptchaToken, email, isDesktop: false },
      })
      .then((res) => res.data);
  },
  signup: async (payload: SignupPayload) => {
    return copilotServer.post("/signup", { ...payload, isDesktop: false }).then((res) => res.data);
  },
  acceptInvitation: async (payload: AcceptInvitationPayload) => {
    return copilotServer.post("/signup/acceptInvitation", payload).then((res) => res.data);
  },
  getUserData: async ({ options }: GetUserDataPayload = {}) => {
    const config = { headers: { "x-authorize-product": false } };
    return authenticationCopilotServer
      .post<LoggedInUserData>(
        `/lexterLogin`,
        {
          googleToken: options?.googleToken,
        },
        config
      )
      .then((res) => res.data);
  },
};
