import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Typography, Snackbar, CircularProgress } from "@mui/material";
import { DataGrid, GridFilterModel, GridPaginationModel, GridRowSelectionModel } from "@mui/x-data-grid";
import { NumberCard } from "./components/NumberCard";
import { ptBR } from "@mui/x-data-grid/locales";
import { CustomColumnMenu } from "./components/DataGridComponents";
import { legalProceedingColumns } from "./components/DataGridComponents/columns";
import { useCredits } from "@/hooks/credits/useCredits";
import { LegalProceedingType } from "@/services/legalProceeding/types";

import { useLexZapCases } from "@/hooks/lexZap/useLexZapCases";
import { useOpenNotificationMutation } from "@/hooks/notifications/useOpenNotificationMutation";
import { useNavigate } from "react-router-dom";
import { useLegalProceedingToCaseMutation } from "@/hooks/legalProceedings/useLegalProceedingToCaseMutation";
import { WebToast } from "@/components/core/Toast";
import { usePaginatedLegalProceedingsQuery } from "@/hooks/legalProceedings/usePaginatedLegalProceedingsQuery";
import { OnNotificationReceivedParams, useSocket } from "@/contexts/WebSocketContext";
import { NotificationService } from "@/services/notification";
import { usePaywallContext } from "@/contexts/PaywallContext";
import { usePlanChips } from "@/hooks/usePlanChips";

type LegalProceedingsTableProps = {
  monitoringColumnEnabled?: boolean;
  onSelectedLegalProceedingsChange?: (legalProceedings: LegalProceedingType[]) => void;
  onSuccess?: () => void;
  onError?: () => void;
};

export const LegalProceedingsTable = ({
  monitoringColumnEnabled,
  onSelectedLegalProceedingsChange,
  onSuccess,
}: LegalProceedingsTableProps) => {
  const navigate = useNavigate();
  const { openUsagePaywall } = usePaywallContext();
  const { isCaseQuotaReached } = usePlanChips();
  const [selectedRows, setSelectedRows] = React.useState<GridRowSelectionModel>([]);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    pageSize: 10,
    page: 0,
  });
  const { data: credits, isLoading: isLoadingCredits } = useCredits();
  const { data: cases, isLoading: isLoadingCase } = useLexZapCases();

  const { mutate: openNotification } = useOpenNotificationMutation();
  const {
    data: paginatedLegalProceedings,
    isLoading: isLoadingLegalProceedings,
    isFetching: isFetchingLegalProceedings,
    refetch: refetchLegalProceedings,
    fetchNextPage: fetchNextPageLegalProceedings,
  } = usePaginatedLegalProceedingsQuery({
    pagination: { pageSize: paginationModel.pageSize },
  });
  const { mutateAsync: importLegalProceedingToCase } = useLegalProceedingToCaseMutation();

  const totalResults = paginatedLegalProceedings?.pages[0]?.totalResults ?? 0;

  const noRows = useMemo(
    () =>
      !isFetchingLegalProceedings &&
      (!paginatedLegalProceedings?.pages[0]?.data || paginatedLegalProceedings?.pages[0]?.data.length === 0),
    [isFetchingLegalProceedings, paginatedLegalProceedings]
  );

  const isImporting = useMemo(
    () => paginatedLegalProceedings?.pages.some((page) => page && page.status === "IMPORTING"),
    [paginatedLegalProceedings]
  );

  const isError = useMemo(
    () => paginatedLegalProceedings?.pages.some((page) => page && page.status === "ERROR"),
    [paginatedLegalProceedings]
  );

  const handleNotificationReceived = useCallback(
    async ({ id }: OnNotificationReceivedParams) => {
      if (!id) return;
      const notification = await NotificationService.getNotificationById(id);
      if (notification.notificationType === "FINISHED_LISTING_LEGAL_PROCEEDINGS") {
        await refetchLegalProceedings();
        openNotification(id);
        onSuccess?.();
      }
    },
    [refetchLegalProceedings, openNotification, onSuccess]
  );

  useSocket({
    onNotificationReceived: handleNotificationReceived,
  });

  const currentPageLegalProceedings = useMemo(() => {
    if (isError) {
      return [];
    }
    return paginatedLegalProceedings?.pages[paginationModel.page]?.data;
  }, [paginatedLegalProceedings, paginationModel.page, isError]);

  const selectedLegalProceedings = useMemo(() => {
    return (
      paginatedLegalProceedings?.pages.flatMap(
        (page) => page?.data.filter((item) => selectedRows.includes(item.cnj)) ?? []
      ) ?? []
    );
  }, [paginatedLegalProceedings, selectedRows]);

  useEffect(() => {
    onSelectedLegalProceedingsChange?.(selectedLegalProceedings);
  }, [selectedLegalProceedings, onSelectedLegalProceedingsChange]);

  const onFilterChange = (model: GridFilterModel) => {
    const query: Record<string, string> = {};

    model.items.forEach((item) => {
      query[item.field] = item.value;
    });
  };

  const totalCasesByPlan = useMemo(() => credits?.companyPlan?.limitQuotaCases ?? 0, [credits]);
  const activeCases = useMemo(() => {
    if (!cases) return 0;
    return cases.filter((c) => c.status === "ACTIVE").length;
  }, [cases]);

  const availableCases = (credits?.companyPlan?.limitQuotaCases ?? 0) - activeCases;
  const aggregateLoading = useMemo(() => {
    return isLoadingLegalProceedings || isLoadingCase || isLoadingCredits;
  }, [isLoadingLegalProceedings, isLoadingCase, isLoadingCredits]);

  const loading = useMemo(() => {
    return isImporting || isLoadingLegalProceedings || isLoadingCase;
  }, [isImporting, isLoadingLegalProceedings, isLoadingCase]);

  const handlePaginationModelChange = (newModel: GridPaginationModel) => {
    setPaginationModel(newModel);
    // As we only navigate 1 page at a time, we always fetch the next page.
    // It will do a unnecessary request if the user is going back but at least we have one page ahead cached already.
    void fetchNextPageLegalProceedings();
  };

  const handleCreateCaseFromLegalProceeding = async (cnj: string) => {
    try {
      await importLegalProceedingToCase({
        cnjs: [cnj],
      });
      WebToast.success("Processo vinculado com sucesso");
    } catch (error) {
      WebToast.error("Erro ao vincular processo");
    }
  };

  return (
    <>
      <Snackbar
        open={isImporting}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{ maxWidth: "500", bgcolor: "secondary.main", borderRadius: 2 }}
      >
        <Box sx={{ display: "flex", gap: 2, pt: 2, px: 2, pb: 1.5 }}>
          <CircularProgress size={16} />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1" fontWeight={700}>
              Buscando processos vinculados
            </Typography>
            <Typography variant="body1">
              Esse processo pode levar alguns minutos, mas você será notificado ao final.
            </Typography>
          </Box>
        </Box>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          gap: 2,
          width: "100%",
        }}
      >
        <NumberCard title="Processos encontrados" actualValue={totalResults} loading={aggregateLoading} />
        <NumberCard
          title="Total de casos ativos"
          actualValue={activeCases}
          rangeValue={totalCasesByPlan}
          loading={aggregateLoading}
        />
        <NumberCard title="Núm. de casos disponíveis" actualValue={availableCases} loading={aggregateLoading} />
      </Box>
      <Box sx={{ width: "100%", mt: 4, mb: 3, height: loading ? "525px" : "auto" }}>
        <DataGrid
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          pageSizeOptions={[paginationModel.pageSize]}
          rows={currentPageLegalProceedings}
          rowCount={totalResults}
          onRowSelectionModelChange={(newSelection) => {
            setSelectedRows(newSelection);
          }}
          rowSelectionModel={selectedRows}
          checkboxSelection={!monitoringColumnEnabled}
          getRowId={(row) => row.cnj}
          columns={legalProceedingColumns({
            monitoringColumnEnabled,
            cases: cases ?? [],
            redirectTo: (path) => navigate(path),
            handleCreateCaseFromLegalProceeding: handleCreateCaseFromLegalProceeding,
            shouldOpenUsagePaywall: isCaseQuotaReached,
            openUsagePaywall,
          })}
          loading={loading}
          keepNonExistentRowsSelected
          disableRowSelectionOnClick
          disableColumnMenu={noRows || isFetchingLegalProceedings}
          localeText={{ ...ptBR.components.MuiDataGrid.defaultProps.localeText, toolbarFilters: "Filtrar" }}
          slots={{
            columnMenu: CustomColumnMenu,
            noRowsOverlay: () => {
              if (isError) {
                return (
                  <Typography textAlign="center" variant="h6" mt={10} fontWeight={500} color="error">
                    Erro ao buscar processos
                  </Typography>
                );
              }
              return (
                <Typography textAlign="center" variant="h6" fontWeight={500} mt={10}>
                  Nenhum processo encontrado
                </Typography>
              );
            },
          }}
          slotProps={{
            loadingOverlay: {
              variant: "skeleton",
              noRowsVariant: "skeleton",
            },
          }}
          sx={{
            "& .MuiDataGrid-virtualScroller": {
              overflow: loading ? "hidden" : "scroll",
            },
          }}
          hideFooterPagination={!totalResults}
          autoHeight={loading ? false : true}
        />
      </Box>
    </>
  );
};
