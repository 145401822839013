import { Box, Typography, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { ChatMode, ChatStage as EChatStage, LexZapChat, TicketStatus } from "@/hooks/lexZap/types";
import { useCompanyMember } from "@/hooks/company/useCompanyMember";
import { getInitials } from "@/utils/getInitials";
import liaImage from "@/assets/images/lia-icon-1.png";

interface ChatStageProps {
  chat: LexZapChat;
}

export const ChatStage = ({ chat }: ChatStageProps) => {
  const { palette } = useTheme();
  const hasResponsible =
    chat.lastTicket?.status === TicketStatus.ACTIVE &&
    (chat.chatMode === ChatMode.AI || !!chat.lastTicket?.responsibleUserId);
  const stageInfo: {
    label: string;
    color: string;
    backgroundColor: string;
    responsibleBackgroundColor: string;
  } | null = useMemo(() => {
    switch (chat.chatStage) {
      case EChatStage.AI_SERVICE:
        return {
          label: "Atendimento IA",
          color: palette.text.primary,
          backgroundColor: palette.common.lightShade!,
          responsibleBackgroundColor: palette.common.lightShade!,
        };
      case EChatStage.INITIAL_TRIAGE:
        return {
          label: "Triagem Inicial",
          color: palette.common.white,
          backgroundColor: palette.success.main,
          responsibleBackgroundColor: palette.success.dark,
        };
      case EChatStage.REDIRECTED:
        return {
          label: "Redirecionado",
          color: palette.common.white,
          backgroundColor: palette.warning.main,
          responsibleBackgroundColor: palette.warning.dark,
        };
      case EChatStage.ABANDONED:
        return {
          label: "Abandonado",
          color: palette.text.primary,
          backgroundColor: palette.info.main,
          responsibleBackgroundColor: palette.info.dark,
        };
      case EChatStage.INACTIVE_AI:
        return {
          label: "IA Inativa",
          color: palette.common.white,
          backgroundColor: palette.grey[800],
          responsibleBackgroundColor: palette.grey[900],
        };
      case EChatStage.ARCHIVED:
        return {
          label: "Arquivada",
          color: palette.common.white,
          backgroundColor: palette.grey[500],
          responsibleBackgroundColor: palette.grey[700],
        };
      case EChatStage.HUMAN_INITIATED:
        return {
          label: "Iniciado Humano",
          color: palette.common.white,
          backgroundColor: palette.warning.main,
          responsibleBackgroundColor: palette.warning.dark,
        };
      default:
        return null;
    }
  }, [chat, palette]);

  if (!stageInfo && !hasResponsible) return null;

  if (!stageInfo) {
    return <ChatResponsible chat={chat} />;
  }

  return (
    <Box
      sx={{
        backgroundColor: stageInfo.backgroundColor,
        paddingRight: "10px",
        paddingLeft: hasResponsible ? "3px" : "10px",
        height: "24px",
        borderRadius: "12px",
        display: "flex",
        alignItems: "center",
        gap: 0.5,
      }}
    >
      {hasResponsible && <ChatResponsible chat={chat} backgroundColor={stageInfo.responsibleBackgroundColor} />}

      <Typography
        variant="body"
        color={stageInfo.color}
        sx={{
          fontSize: "12px",
          fontWeight: 500,
        }}
      >
        {stageInfo.label}
      </Typography>
    </Box>
  );
};

export const ChatResponsible = ({
  chat,
  backgroundColor = "common.mediumShade",
}: ChatStageProps & { backgroundColor?: string }) => {
  const { data: responsibleUser } = useCompanyMember({ memberId: chat.lastTicket?.responsibleUserId });

  return (
    <Box
      sx={{
        width: "18px",
        height: "18px",
        borderRadius: "50%",
        backgroundColor,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      {chat.chatMode === ChatMode.AI ? (
        <img src={liaImage} alt="Lia" width="100%" height="100%" />
      ) : (
        <Typography
          variant="body"
          color="common.white"
          sx={{
            fontSize: "10px",
          }}
        >
          {!!chat.lastTicket?.responsibleUserId && !!responsibleUser
            ? getInitials(responsibleUser.name).toUpperCase()
            : "-"}
        </Typography>
      )}
    </Box>
  );
};
