import { Box, Typography, useTheme } from "@mui/material";
import { FilterSelect } from "@/components/FilterSelect";
import { PageLayout } from "@/components/PageLayout";
import { useApi } from "@/hooks/useApi";
import { CreditTransactionStatus, CreditTransactionType, CreditsHistoryApiResponseItem } from "@/hooks/useApi/types";
import React, { useEffect, useMemo, useState } from "react";
import { CreditsCard } from "./components/CreditsCard";
import { CreditsHistoryList } from "./components/CreditsHistoryList";
import { CreditsHistoryListSkeleton } from "./components/CreditsHistoryList/CreditsHistoryListSkeleton";
import { CreditsHistoryListError } from "./components/CreditsHistoryListError";
import { logger } from "@/core/logger";

enum TypeFilter {
  ALL,
  RECHARGES,
  EXPENSES,
}

const defaultTypeFilter = TypeFilter.ALL;
const defailtDaysFilter = 30;

export const CreditsHistory = () => {
  const { getCreditsHistory } = useApi();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const theme = useTheme();
  const [items, setItems] = useState<CreditsHistoryApiResponseItem[]>([]);
  const [typeFilter, setTypeFilter] = useState(defaultTypeFilter);
  const [daysFilter, setDaysFilter] = useState(defailtDaysFilter);

  const filteredItems = useMemo(() => {
    let filtered = items;

    if (typeFilter === TypeFilter.RECHARGES) {
      filtered = filtered.filter((item) => item.type === CreditTransactionType.RECHARGE);
    } else if (typeFilter === TypeFilter.EXPENSES) {
      filtered = filtered.filter((item) => item.type === CreditTransactionType.EXPENSE);
    }

    const dateFilter = new Date();
    dateFilter.setDate(dateFilter.getDate() - daysFilter);

    filtered = filtered.filter((item) => new Date(item.creationDate) > dateFilter);

    return filtered;
  }, [daysFilter, items, typeFilter]);

  const fetchCreditsHistory = async () => {
    try {
      const response = await getCreditsHistory();
      const responseFiltered = response.filter((item) => item.status !== CreditTransactionStatus.ROLLBACK);
      setItems(responseFiltered);
      setError(false);
    } catch (error) {
      logger.error("fetchCreditsHistory", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCreditsHistory();
  }, []);

  return (
    <PageLayout>
      <Box sx={{ height: "100%", width: "100%", margin: "0 auto" }}>
        <Box
          sx={{
            width: "100%",
            p: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <CreditsCard />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 5,
            }}
          >
            <Typography variant="subtitle1">Histórico de uso</Typography>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 4,
                [theme.breakpoints.up("sm")]: {
                  flexDirection: "row",
                  justifyContent: "space-around",
                  px: "24px",
                },
              }}
            >
              <FilterSelect
                label="Exibir"
                options={[
                  {
                    label: "Últimos 30 dias",
                    value: 30,
                  },
                  {
                    label: "Últimos 60 dias",
                    value: 60,
                  },
                  {
                    label: "Últimos 90 dias",
                    value: 90,
                  },
                  {
                    label: "Últimos 12 meses",
                    value: 365,
                  },
                ]}
                defaultValue={defailtDaysFilter}
                onChange={(value) => setDaysFilter(value as number)}
                disabled={error}
              />

              <FilterSelect
                label="Filtrar por"
                options={[
                  {
                    label: "Mostrar tudo",
                    value: TypeFilter.ALL,
                  },
                  {
                    label: "Recargas de créditos",
                    value: TypeFilter.RECHARGES,
                  },
                  {
                    label: "Gastos de créditos",
                    value: TypeFilter.EXPENSES,
                  },
                ]}
                defaultValue={defaultTypeFilter}
                onChange={(value) => setTypeFilter(value as TypeFilter)}
                disabled={error}
              />
            </Box>

            {error ? (
              <CreditsHistoryListError />
            ) : (
              <>{!loading ? <CreditsHistoryList items={filteredItems} /> : <CreditsHistoryListSkeleton />}</>
            )}
          </Box>
        </Box>
      </Box>
    </PageLayout>
  );
};
