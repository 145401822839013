import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { Ticket } from "@/hooks/lexZap/types";
import { EditNote as EditNoteIcon } from "@mui/icons-material";
import { useNotes } from "@/hooks/notes/useNotes";
import { NoteItemSkeleton } from "./components/NoteItem/skeleton";
import { NoteItem } from "./components/NoteItem";
import { EditNote } from "./components/EditNote";

export const TicketNotes = ({ ticket }: { ticket: Ticket }) => {
  const { data: notes, isLoading } = useNotes({
    ticketId: ticket.id,
  });
  const [isCreatingNewNote, setIsCreatingNewNote] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      {isCreatingNewNote ? (
        <EditNote ticketId={ticket.id} onClose={() => setIsCreatingNewNote(false)} />
      ) : (
        <Button variant="outlined" startIcon={<EditNoteIcon />} onClick={() => setIsCreatingNewNote(true)}>
          Nova anotação
        </Button>
      )}

      {isLoading ? (
        <NoteItemSkeleton />
      ) : (
        notes?.map((note) => <NoteItem key={note.id} note={note} ticketId={ticket.id} />)
      )}
    </Box>
  );
};
