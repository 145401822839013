import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NoteService } from "@/services/note";
import { getNotesQueryKey } from "../useNotes";
import { Note } from "@/services/note/types";

export const useEditNote = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["editNote"],
    mutationFn: async ({
      noteId,
      data,
    }: {
      noteId: string;
      applicantId?: string | null;
      caseId?: string | null;
      ticketId?: string | null;
      data: { content: string };
    }) => await NoteService.update({ noteId, data }),
    onMutate: async ({ noteId, applicantId, caseId, ticketId, data }) => {
      await queryClient.cancelQueries({ queryKey: getNotesQueryKey({ applicantId, caseId, ticketId }) });

      const previousNotes = queryClient.getQueryData(getNotesQueryKey({ applicantId, caseId, ticketId }));

      queryClient.setQueryData<Note[]>(getNotesQueryKey({ applicantId, caseId, ticketId }), (old = []) => {
        return [...old.map((a) => (a.id === noteId ? { ...a, ...data } : a))];
      });

      return { previousNotes };
    },
    onError: (_error, { applicantId, caseId, ticketId }, context) => {
      queryClient.setQueryData(getNotesQueryKey({ applicantId, caseId, ticketId }), context?.previousNotes);
    },
  });
};
