import React from "react";

import { Card } from "../Card";
import { useNotes } from "@/hooks/notes/useNotes";
import { Case } from "@/hooks/lexZap/types";
import { Notes } from "@/components/Notes/notes";

type CaseDetailsNotesProps = {
  loadingCase: boolean;
  caseId: string;
  caseData: Case;
  applicantId?: string;
};

export const CaseDetailsNotes = ({ caseId, applicantId, loadingCase }: CaseDetailsNotesProps) => {
  const { data: notes, isLoading: isLoadingNotes } = useNotes({ applicantId, caseId });

  const isLoading = isLoadingNotes || loadingCase;

  return (
    <Card
      sx={{
        padding: 0,
        gap: 0,
        width: "100%",
      }}
      loadingSize={isLoading ? "200px" : ""}
    >
      <Notes notes={notes} applicantId={applicantId} caseId={caseId} />
    </Card>
  );
};
