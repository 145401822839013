import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NoteService } from "@/services/note";
import { getNotesQueryKey } from "../useNotes";
import { Note, NotePayload } from "@/services/note/types";
import { useAuthContext } from "@/contexts/AuthContext";

export const useCreateNote = () => {
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["createNote"],
    mutationFn: async ({ data }: { data: NotePayload }) => await NoteService.create({ data }),
    onSuccess: async ({ id }, { data }) => {
      await queryClient.cancelQueries({
        queryKey: getNotesQueryKey({ applicantId: data.applicantId, caseId: data.caseId, ticketId: data.ticketId }),
      });
      queryClient.setQueryData(
        getNotesQueryKey({ applicantId: data.applicantId, caseId: data.caseId, ticketId: data.ticketId }),
        (old?: Note[]) => {
          return [...(old || []), { ...data, id, createdAt: new Date().toISOString(), createdByUserId: user?.userId }];
        }
      );
    },
  });
};
