import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  FormControlLabel,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { ModalRight } from "@/components/Modal/ModalRight";
import React, { useEffect, useMemo, useState } from "react";
import { useCompanyTeam } from "@/hooks/company/useCompanyTeam";
import { activityAreaOptions } from "@/hooks/company/types";
import { CloseOutlined } from "@mui/icons-material";
import { AnsweredFilter, CaseStatusFilter, ChatFilters } from "./types";
import { EXTERNAL_URLS } from "@/routes/routePaths";

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  onChange: (filters: ChatFilters) => void;
  filters: ChatFilters;
}

export function FilterModal({ isOpen, onClose, filters: filtersProp, onChange }: FilterModalProps) {
  const [filters, setFilters] = useState<ChatFilters>(filtersProp);
  const { data: companyTeamData = [] } = useCompanyTeam();
  const companyTeam = useMemo(() => companyTeamData.filter((member) => member.isActive), [companyTeamData]);

  const handleApplyFilters = () => {
    onChange(filters);
    onClose();
  };

  const handleTicketStatusOpenCheckboxChange = () => {
    const allChecked = Object.values(filters.ticketStatus.open).every((value) => value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      ticketStatus: {
        ...prevFilters.ticketStatus,
        open: {
          abandoned: !allChecked,
          AIService: !allChecked,
          redirectedHuman: !allChecked,
          initialTriage: !allChecked,
        },
      },
    }));
  };

  const isTicketStatusOpenChecked = Object.values(filters.ticketStatus.open).every((value) => value);
  const isTicketStatusOpenIndeterminate =
    Object.values(filters.ticketStatus.open).some((value) => value) && !isTicketStatusOpenChecked;

  useEffect(() => {
    if (isOpen) {
      setFilters(filtersProp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <ModalRight
      title={"Filtrar conversas"}
      size={"SMALL"}
      onClose={onClose}
      footer={
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            onClick={handleApplyFilters}
            sx={{
              width: "100%",
            }}
          >
            Filtrar
          </Button>
        </Box>
      }
      open={isOpen}
      sx={{
        "& .MuiSvgIcon-fontSizeMedium": {
          width: "20px",
          height: "20px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 3,
          paddingBottom: 3,
        }}
      >
        <FilterSection
          title="Status"
          description="Filtre conversas que pelo status de seu atendimento, você também pode exibir conversas que não estão em atendimento."
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isTicketStatusOpenChecked}
                    indeterminate={isTicketStatusOpenIndeterminate}
                    onChange={handleTicketStatusOpenCheckboxChange}
                  />
                }
                label="Em atendimento"
              />

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  ml: 3,
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.ticketStatus.open.abandoned}
                      onChange={() =>
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          ticketStatus: {
                            ...prevFilters.ticketStatus,
                            open: {
                              ...prevFilters.ticketStatus.open,
                              abandoned: !prevFilters.ticketStatus.open.abandoned,
                            },
                          },
                        }))
                      }
                    />
                  }
                  label="Abandonado"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.ticketStatus.open.AIService}
                      onChange={() =>
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          ticketStatus: {
                            ...prevFilters.ticketStatus,
                            open: {
                              ...prevFilters.ticketStatus.open,
                              AIService: !prevFilters.ticketStatus.open.AIService,
                            },
                          },
                        }))
                      }
                    />
                  }
                  label="Atendimento IA"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.ticketStatus.open.redirectedHuman}
                      onChange={() =>
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          ticketStatus: {
                            ...prevFilters.ticketStatus,
                            open: {
                              ...prevFilters.ticketStatus.open,
                              redirectedHuman: !prevFilters.ticketStatus.open.redirectedHuman,
                            },
                          },
                        }))
                      }
                    />
                  }
                  label="Direcionado humano"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters.ticketStatus.open.initialTriage}
                      onChange={() =>
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          ticketStatus: {
                            ...prevFilters.ticketStatus,
                            open: {
                              ...prevFilters.ticketStatus.open,
                              initialTriage: !prevFilters.ticketStatus.open.initialTriage,
                            },
                          },
                        }))
                      }
                    />
                  }
                  label="Triagem inicial"
                />
              </Box>
            </Box>

            <FormControlLabel
              control={
                <Checkbox
                  checked={filters.ticketStatus.closed}
                  onChange={() =>
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      ticketStatus: { ...prevFilters.ticketStatus, closed: !prevFilters.ticketStatus.closed },
                    }))
                  }
                />
              }
              label="Atendimento encerrado"
            />
          </Box>
        </FilterSection>

        <FilterSection
          title="Status da resposta"
          description="Filtre conversas que já foram ou não respondidas por usuários da sua plataforma Lexter"
        >
          <ButtonGroup size="small">
            <Button
              variant={filters.answered === AnsweredFilter.ANSWERED ? "contained" : "outlined"}
              onClick={() =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  answered: AnsweredFilter.ANSWERED,
                }))
              }
            >
              Respondida
            </Button>
            <Button
              variant={filters.answered === AnsweredFilter.UNANSWERED ? "contained" : "outlined"}
              onClick={() =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  answered: AnsweredFilter.UNANSWERED,
                }))
              }
            >
              Não respondida
            </Button>
            <Button
              variant={filters.answered === AnsweredFilter.ALL ? "contained" : "outlined"}
              onClick={() =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  answered: AnsweredFilter.ALL,
                }))
              }
            >
              Todas
            </Button>
          </ButtonGroup>
        </FilterSection>

        <FilterSection
          title="Caso"
          description={
            <>
              Filtre conversas de clientes com casos ativos na plataforma Lexter. Veja o que são os casos e como
              criá-los{" "}
              <Link href={EXTERNAL_URLS.WHAT_IS_A_CASE} target="_blank" color="primary.main">
                aqui
              </Link>
              .
            </>
          }
        >
          <ButtonGroup size="small">
            <Button
              variant={filters.caseStatus === CaseStatusFilter.ACTIVE ? "contained" : "outlined"}
              onClick={() =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  caseStatus: CaseStatusFilter.ACTIVE,
                }))
              }
            >
              Ativo
            </Button>

            <Button
              variant={filters.caseStatus === CaseStatusFilter.INACTIVE ? "contained" : "outlined"}
              onClick={() =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  caseStatus: CaseStatusFilter.INACTIVE,
                }))
              }
            >
              Não ativo
            </Button>

            <Button
              variant={filters.caseStatus === CaseStatusFilter.ALL ? "contained" : "outlined"}
              onClick={() =>
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  caseStatus: CaseStatusFilter.ALL,
                }))
              }
            >
              Todos
            </Button>
          </ButtonGroup>
        </FilterSection>

        <FilterSection
          title="Responsável"
          description="Exibir apenas conversas que foram marcadas como pertencentes ás pessoas:"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexWrap: "wrap",
              }}
            >
              {filters.responsibles?.map((responsible) => (
                <Chip
                  key={responsible.userId}
                  label={responsible.name}
                  onDelete={() => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      responsibles: prevFilters.responsibles?.filter((item) => item.userId !== responsible.userId),
                    }));
                  }}
                  sx={{
                    backgroundColor: "secondary.main",
                    color: "text.primary",
                  }}
                  deleteIcon={<CloseOutlined />}
                />
              ))}
            </Box>

            <Autocomplete
              renderInput={(params) => <TextField {...params} placeholder="Comece a digitar para buscar" />}
              multiple
              options={companyTeam}
              value={filters.responsibles}
              noOptionsText="Nenhuma opção encontrada"
              disablePortal
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.userId === value.userId}
              onChange={(_, selectedOptions) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  responsibles: selectedOptions,
                }));
              }}
              sx={{
                "& .MuiChip-root": {
                  visibility: "hidden",
                  display: "none",
                  color: "red",
                },
                "& .MuiChip-label": {
                  display: "none",
                  visibility: "hidden",
                  color: "red",
                },
              }}
            />
          </Box>
        </FilterSection>

        <FilterSection
          title="Conversas das áreas"
          description="Exibir apenas conversas que foram marcadas como pertencentes ás areas:"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: 1,
                flexWrap: "wrap",
              }}
            >
              {filters.areas?.map((area) => (
                <Chip
                  key={area.value}
                  label={area.label}
                  onDelete={() => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      areas: prevFilters.areas?.filter((item) => item.value !== area.value),
                    }));
                  }}
                  sx={{
                    backgroundColor: "secondary.main",
                    color: "text.primary",
                  }}
                  deleteIcon={<CloseOutlined />}
                />
              ))}
            </Box>

            <Autocomplete
              renderInput={(params) => <TextField {...params} placeholder="Comece a digitar para buscar" />}
              multiple
              options={activityAreaOptions}
              value={filters.areas}
              noOptionsText="Nenhuma opção encontrada"
              disablePortal
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              onChange={(_, selectedOptions) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  areas: selectedOptions,
                }));
              }}
              sx={{
                "& .MuiChip-root": {
                  visibility: "hidden",
                  display: "none",
                  color: "red",
                },
                "& .MuiChip-label": {
                  display: "none",
                  visibility: "hidden",
                  color: "red",
                },
              }}
            />
          </Box>
        </FilterSection>
      </Box>
    </ModalRight>
  );
}

const FilterSection = ({
  title,
  description,
  children,
}: {
  title: string;
  description: string | React.ReactNode;
  children: React.ReactNode;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
        <Typography
          sx={{
            fontSize: "16px",
          }}
          variant="body1"
        >
          {title}
        </Typography>

        <Typography
          sx={{
            fontSize: "12px",
          }}
          variant="body2"
        >
          {description}
        </Typography>
      </Box>

      <Box>{children}</Box>
    </Box>
  );
};
