import { SkillForm } from "@/core/skillForms/types";
import { createReplySkillStep1 } from "../step1";
import { createReplySkillStep2 } from "../step2";
import { createReplySkillStep3 } from "./step3";
import { createReplySkillStep4 } from "./step4";

export const createReplySkillFormV2: SkillForm = {
  skillId: "create_reply_v2",
  title: "Escrever Réplica",
  steps: [createReplySkillStep1, createReplySkillStep2, createReplySkillStep3, createReplySkillStep4],
};
