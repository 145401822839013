import { useApi } from "@/hooks/useApi";
import { useQuery, useQueries } from "@tanstack/react-query";
import { OrderRecordsBy, OrderRecordsOptions } from "../types";
import { PaginationPayload } from "@/core/api/types";
import { getThreadQueryKey } from "@/hooks/threads/useThread";

export const getRecordsQueryKey = ({ threadId, caseId }: { threadId?: string; caseId?: string }) => [
  ...getThreadQueryKey({ threadId }),
  caseId,
  "records",
];

export const orderRecordsOptions: OrderRecordsOptions = {
  CREATED_AT: "Data de Criação",
  ALPHABET_ASC: "Alfabético - AZ",
  ALPHABET_DESC: "Alfabético - ZA",
  TYPE: "Tipo",
};

const getOrderOptionsParams = (order: OrderRecordsBy) => {
  switch (order) {
    case "CREATED_AT":
      return { sortBy: "date", sortDirection: "desc" };
    case "ALPHABET_ASC":
      return { sortBy: "name", sortDirection: "asc" };
    case "ALPHABET_DESC":
      return { sortBy: "name", sortDirection: "desc" };
    case "TYPE":
      return { sortBy: "type", sortDirection: "asc" };
    default:
      return { sortBy: "date", sortDirection: "desc" };
  }
};

export const useRecords = ({
  caseId,
  threadId,
  search,
  order,
  pagination,
}: {
  caseId?: string;
  threadId?: string | string[];
  search?: string;
  order: OrderRecordsBy;
  pagination?: PaginationPayload;
}) => {
  const api = useApi();
  const params = getOrderOptionsParams(order);
  const threadIds = Array.isArray(threadId) ? threadId : threadId ? [threadId] : [];
  const isMultiThread = threadIds.length > 1;

  const queries = useQueries({
    queries: threadIds
      ? threadIds.map((id) => ({
          queryKey: [...getRecordsQueryKey({ threadId: id }), { order, search, pagination }],
          queryFn: () => api.getRecords({ threadId: id, search, pagination, ...params }),
          enabled: !!id,
          refetchOnWindowFocus: false,
          staleTime: 0,
        }))
      : [],
  });

  const singleQuery = useQuery({
    queryKey: [...getRecordsQueryKey({ threadId: threadIds[0], caseId }), { order, search, pagination }],
    queryFn: async () => {
      if (!caseId && !threadIds[0]) return undefined;
      return api.getRecords({ threadId: threadIds[0], caseId, search, pagination, ...params });
    },
    enabled: !isMultiThread && !!threadIds[0],
    refetchOnWindowFocus: false,
    staleTime: 0,
  });

  if (isMultiThread) {
    return {
      data: queries.map((q) => q.data).filter(Boolean),
      isLoading: queries.some((q) => q.isLoading),
      error: queries.find((q) => q.error)?.error,
    };
  }

  return singleQuery;
};
