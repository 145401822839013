import { Box, Link, Typography } from "@mui/material";
import { Button } from "@/components/Button";
import { Layout } from "@/pages/Signup/Layout";
import { ReCaptchaProvider } from "@/providers/ReCaptchaProvider";
import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { EXTERNAL_URLS, ROUTE_PATHS } from "@/routes/routePaths";
import { useAuthContext } from "@/contexts/AuthContext";
import LexterLogoIcon from "@/assets/svgs/lexter-logo.svg?react";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";

export const SignupSuccess = () => (
  <ReCaptchaProvider>
    <Content />
  </ReCaptchaProvider>
);

const Content = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const flags = useFeatureFlags();
  const [isLoading, setIsLoading] = useState(false);
  const { loginWithEmailAndPassword } = useAuthContext();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await handleContinue();
  };

  const handleContinue = async () => {
    const password = location.state?.password;
    const email = location.state?.email;

    if (!password || !email) {
      return navigate(ROUTE_PATHS.LOGIN, {
        state: { redirectTo: location.state?.redirectTo },
      });
    }

    setIsLoading(true);

    const response = await loginWithEmailAndPassword(email, password, "");

    if (!response.success) {
      return navigate(ROUTE_PATHS.LOGIN, {
        state: { redirectTo: location.state?.redirectTo },
      });
    }

    if (location.state?.redirectTo) {
      return navigate(location.state.redirectTo);
    }

    if (!flags.planScreenV2Enabled) {
      return navigate(ROUTE_PATHS.ONBOARDING_QUESTIONS, {
        replace: true,
      });
    }

    return navigate(ROUTE_PATHS.HOME);
  };

  useEffect(() => {
    const password = location.state?.password;

    if (!password) {
      return navigate(ROUTE_PATHS.SIGNUP);
    }
  }, []);

  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: "320px",
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
          component="form"
          onSubmit={handleSubmit}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              gap: 3,
              mb: 2,
            }}
          >
            <Box
              component={LexterLogoIcon}
              height={54}
              sx={{
                color: "primary.main",
              }}
            />
          </Box>

          <Typography variant="h3" fontWeight={600} align="center">
            Sucesso!
          </Typography>
          <Typography variant="multiLineBody" align="center">
            Sua conta foi criada com sucesso e você já está pronto para utilizar o Assistente Lexter.
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 4,
            }}
          >
            <Button type="submit" variant="contained" fullWidth isLoading={isLoading}>
              Começar a usar
            </Button>
            <Typography variant="multiLineBody" align="center">
              Em caso de dúvidas,{" "}
              <Typography variant="multiLineBody" align="center" display="block">
                acesse nossa <Link href={EXTERNAL_URLS.HELP_CENTER_URL}>Central de Ajuda</Link>.
              </Typography>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};
