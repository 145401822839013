import React, { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import { LegalProceedingsAttached } from "./components/LegalProceedingsAttached";
import { CaseSummary } from "./components/CaseSummary";
import { Case } from "@/hooks/lexZap/types";
import { ApplicantModal } from "@/components/Applicants";
import { EditCaseModal } from "@/components/Cases";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { CaseDetailsChat } from "@/components/Cases/CaseDetailsChat";
import { CaseCard } from "./components/CaseCard";
import { Button } from "@/components/Button";
import { CaseSuggestionSkills } from "@/components/Cases/CaseSuggestionSkills";
import { CaseFiles } from "@/components/Cases/CaseFiles";
import { CaseDetailsNotes } from "@/components/Cases/CaseDetailsNotes";
import { InfoOutlined } from "@mui/icons-material";

interface CaseDetailsProps {
  caseData?: Case;
  caseId: string;
  showNextSteps: boolean;
  loadingCase: boolean;
  onDeleteCase: () => void;
  onArchiveCase: () => void;
  onActivateCase: () => void;
  onUnarchiveCase: () => void;
}

export const CaseDetails = ({
  caseData,
  loadingCase,
  onDeleteCase,
  onArchiveCase,
  onActivateCase,
  onUnarchiveCase,
}: CaseDetailsProps) => {
  const { legalProceedingsPageEnabled, caseSuggestionsEnabled } = useFeatureFlags();
  const [isEditApplicantModalOpen, setIsEditApplicantModalOpen] = React.useState(false);
  const [isEditCaseModalOpen, setIsEditCaseModalOpen] = React.useState(false);

  const openEditApplicantModal = () => {
    setIsEditApplicantModalOpen(true);
  };

  const closeEditApplicantModal = () => {
    setIsEditApplicantModalOpen(false);
  };

  const openEditCaseModal = () => {
    setIsEditCaseModalOpen(true);
  };

  const closeEditCaseModal = () => {
    setIsEditCaseModalOpen(false);
  };

  const isCaseInactive = useMemo(() => caseData?.status === "ARCHIVED", [caseData]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      {isCaseInactive && (
        <Box sx={{ mx: 4, p: 1, bgcolor: "error.main", mt: 2, borderRadius: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: 2,
              bgcolor: "alert.info",
              borderRadius: 0.5,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <InfoOutlined sx={{ color: "info.main", fontSize: 22 }} />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="body1" color="text.secondary" fontWeight={700}>
                  Caso arquivado e com monitoramento pausado
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Este caso foi arquivado dentro da plataforma lexter, movimentações não estão sendo registradas.{" "}
                </Typography>
              </Box>
            </Box>
            <Button
              variant="outlined"
              color="inherit"
              onClick={onActivateCase}
              disabled={loadingCase}
              sx={{
                textTransform: "none",
                mt: 1,
              }}
              size="small"
            >
              Desarquivar
            </Button>
          </Box>
        </Box>
      )}

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1.5fr 1fr",
          gridTemplateAreas: `"main sidebar"`,
          gap: 3,
          alignItems: "start",
          p: 4,
          pt: 2,
        }}
      >
        {caseData && (
          <ApplicantModal
            applicantId={caseData.applicantId}
            isOpen={isEditApplicantModalOpen}
            onClose={closeEditApplicantModal}
          />
        )}

        {caseData?.id && isEditCaseModalOpen && (
          <EditCaseModal caseId={caseData.id} isOpen={isEditCaseModalOpen} onClose={closeEditCaseModal} />
        )}

        <Box sx={{ gridArea: "main", display: "flex", flexDirection: "column", gap: 3 }}>
          <CaseCard
            caseData={caseData}
            loadingCase={loadingCase}
            openEditCaseModal={openEditCaseModal}
            openEditApplicantModal={openEditApplicantModal}
            onDelete={onDeleteCase}
            onArchive={onArchiveCase}
            onUnarchive={onUnarchiveCase}
            archived={caseData?.status === "ARCHIVED"}
          />
          <CaseSummary caseData={caseData} loadingCase={loadingCase} />
          {legalProceedingsPageEnabled && caseData?.id && <LegalProceedingsAttached caseData={caseData} />}
          {caseData && (
            <CaseDetailsNotes
              caseData={caseData}
              loadingCase={loadingCase}
              applicantId={caseData?.applicantId}
              caseId={caseData?.id}
            />
          )}
        </Box>

        <Box sx={{ gridArea: "sidebar", display: "flex", flexDirection: "column", gap: 3 }}>
          {/* {showCaseSuggestions && <CaseNextSteps caseId={caseData!.id} onStepClick={handleStepClick} />} */}
          {caseSuggestionsEnabled && <CaseSuggestionSkills caseData={caseData} loadingCase={loadingCase} />}
          <CaseDetailsChat
            caseData={caseData}
            loadingCase={loadingCase}
            archived={caseData?.status === "ARCHIVED"}
            handleEditApplicantModal={openEditApplicantModal}
          />
          {caseData?.id && <CaseFiles caseData={caseData} />}
        </Box>
      </Box>
    </Box>
  );
};
