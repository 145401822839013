import { authenticatedLexterApi } from "@/core/api";
import { CompleteProfilePayload, GetCompanyDetailsResponse } from "@/hooks/useApi/types";

export const LexterApiService = {
  getCompanyDetailsById: async (companyId: string) => {
    return authenticatedLexterApi
      .get<GetCompanyDetailsResponse>(`/companies/${companyId}`, {
        headers: { "x-company-id": companyId },
      })
      .then((res) => res.data);
  },
  completeProfile: async ({ userId, companyId, token, ...payload }: CompleteProfilePayload) => {
    return authenticatedLexterApi
      .put(
        `/users/${userId}/completeProfile`,
        {
          ...payload,
        },
        {
          headers: { "x-company-id": companyId },
        }
      )
      .then((res) => res.data);
  },
};
