import { Box, Skeleton, Typography } from "@mui/material";
import React from "react";

interface MessageCardSkeletonProps {
  fromMe: boolean;
}
export const MessageCardSkeleton = ({ fromMe }: MessageCardSkeletonProps) => {
  return (
    <Box
      sx={{
        maxWidth: fromMe ? "calc(65% + 32px)" : "65%",
        display: "flex",
        alignItems: "flex-end",
        gap: 1,
      }}
    >
      <Box
        sx={{
          borderRadius: "16px",
          borderBottomRightRadius: fromMe ? 0 : "16px",
          borderBottomLeftRadius: fromMe ? "16px" : 0,
          backgroundColor: fromMe ? "orange.light.10" : "common.white",
          border: fromMe ? undefined : "1px solid",
          borderColor: fromMe ? undefined : "common.lightShade",
          width: "100%",
          paddingX: 3,
          paddingY: 2,
          display: "grid",
          alignItems: "center",

          gridTemplateColumns: "minmax(0, 1fr) min-content",
        }}
      >
        <Skeleton
          variant="text"
          width={Math.floor(200 + Math.random() * 200)}
          sx={{
            maxWidth: "100%",
          }}
        />

        <Typography
          variant="body"
          sx={{
            whiteSpace: "normal",
            marginLeft: 2,
            color: "#756D6A",
          }}
        >
          <Skeleton variant="text" width={40} />
        </Typography>
      </Box>

      {fromMe && (
        <Skeleton
          variant="circular"
          sx={{
            width: "24px",
            height: "24px",
            minWidth: "24px",
            minHeight: "24px",
          }}
        />
      )}
    </Box>
  );
};
