import { useAuthContext } from "@/contexts/AuthContext";
import { WebToast } from "@//components/core/Toast";
import { PlanType } from "@/hooks/credits/types";
import { useCredits } from "@/hooks/credits/useCredits";
import { useApi } from "@/hooks/useApi";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { openUrl } from "@/utils/openUrl";
import { useNavigate } from "react-router-dom";

export const useOpenBilling = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const flags = useFeatureFlags();
  const { data: credits } = useCredits();
  const { getPaymentUserPortal } = useApi();

  const planType = credits?.companyPlan?.plan;

  return async () => {
    if (!user?.isCompanyOwner) {
      WebToast.error("Entre em contato com o administrador da conta para gerenciar o plano!", 6000);
      return;
    }

    if (flags.planScreenV2Enabled) {
      navigate(ROUTE_PATHS.PLAN);
      return;
    }

    if (planType && [PlanType.FREEMIUM, PlanType.TRIAL].includes(planType)) {
      if (flags.onlyUnlimitedPlanEnabled) {
        navigate(ROUTE_PATHS.INTERNAL_PAYMENT);
      } else {
        navigate(ROUTE_PATHS.PLANS);
      }
    } else {
      if (user) {
        const response = await getPaymentUserPortal({ companyId: user.companyId });
        openUrl(response.paymentUserPortal, false);
      }
    }
  };
};
