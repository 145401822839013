import { authenticatedCopilotServer } from "@/core/api";

type CloudHumansClientContact = {
  userName: string;
  email?: string;
  phoneNumber?: string;
  planType?: string;
  isActivePlan: boolean;
};

export const CloudHumansService = {
  async updateClientContact({ data }: { data: CloudHumansClientContact }) {
    const response = await authenticatedCopilotServer.post("/cloudhumans/clientcontact", data);
    return response.data;
  },
};
