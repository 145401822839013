import { Box } from "@mui/material";
import { LateralMenu } from "@/components/LateralMenu";
import React from "react";
import { ROUTE_PATHS } from "@/routes/routePaths";

const LoggedLayout = ({ children }: { children: React.ReactNode }) => {
  const pathname = window.location.pathname;
  const hideLateralMenu =
    pathname === ROUTE_PATHS.ONBOARDING_QUESTIONS || pathname === ROUTE_PATHS.CALL_WITH_SPECIALIST;

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content 1fr",
        height: "100%",
        width: "100%",
      }}
    >
      {!hideLateralMenu && <LateralMenu />}
      <Box
        sx={{
          overflow: "auto",
          gridTemplateRows: "min-content auto",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default LoggedLayout;
