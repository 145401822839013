import { CreateCaseModal } from "@/components/Cases/createCaseModal";
import { CreateOrAttachThread } from "@/components/Cases/createOrAttachThread";
import { useDocumentOnboarding } from "@/hooks/skills/useDocumentOnboarding";
import { Box, Typography } from "@mui/material";
import GoodWorkIllustrationSVG from "assets/svgs/good-work-illustration.svg?react";
import { motion } from "framer-motion";
import React, { useState } from "react";

export const SuccessPage = ({ onClose, threadId }: { onClose: () => void; threadId?: string }) => {
  const [isCreateCaseModalOpen, setIsCreateCaseModalOpen] = useState(false);
  const [isCaseCreated, setIsCaseCreated] = useState(false);

  const { closeOnboarding } = useDocumentOnboarding();

  const continueEditing = () => {
    closeOnboarding();
    onClose();
  };

  const closeCreateCaseModal = () => {
    setIsCreateCaseModalOpen(false);
  };

  const confirmCreateCaseModal = () => {
    setIsCreateCaseModalOpen(false);
    setIsCaseCreated(true);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 4,
        alignItems: "center",
        justifyContent: "center",
      }}
      component={motion.div}
      key="loading_skill"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.5 }}
    >
      <GoodWorkIllustrationSVG />
      <Box display="flex" flexDirection="column" gap={1} textAlign="center">
        <Typography
          sx={{
            color: "#4A4A68",
          }}
          variant="h3"
        >
          Pronto!
        </Typography>
        <Typography sx={{ color: "#4A4A68" }} variant="body">
          Sua peça foi criada
        </Typography>
      </Box>
      {threadId && (
        <>
          <CreateOrAttachThread
            onCreateNewCase={() => setIsCreateCaseModalOpen(true)}
            threadId={threadId}
            onClose={continueEditing}
            isCaseCreated={isCaseCreated}
          />
          {isCreateCaseModalOpen && (
            <CreateCaseModal onClose={closeCreateCaseModal} onConfirm={confirmCreateCaseModal} threadId={threadId} />
          )}
        </>
      )}
    </Box>
  );
};
