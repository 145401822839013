import { Box, Typography } from "@mui/material";
import React, { Dispatch, useEffect } from "react";
import {
  Send as SendIcon,
  Add as AddIcon,
  InsertDriveFile as InsertDriveFileIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { useSendLexZapMessage } from "@/hooks/lexZap/useSendLexZapMessage";
import { useApi } from "@/hooks/useApi";
import { uploadFileToS3 } from "@/contexts/MessagesContext/utils";
import { CaseMessageToSendType, isValidMessageToSendType } from "@/hooks/lexZap/types";
import { SendLexZapMessageParams } from "@/hooks/useApi/types";
import { v4 as uuidV4 } from "uuid";

interface FileProp {
  url: string;
  blob: Blob;
  name: string;
  type: string;
  mimeType: string;
}

export const ChatFilesPreview = ({
  files,
  applicantPhoneNumber,
  onClose,
  removeFile,
  fileSelector,
  openFilesSelector,
}: {
  files: FileProp[];
  applicantPhoneNumber: string | undefined;
  onClose: () => void;
  removeFile: (file: FileProp) => void;
  fileSelector: "document" | "image&video" | null;
  openFilesSelector: () => void;
}) => {
  const { signChatBucketUrl } = useApi();
  const {
    mutate: sendMessage,
    createPreviewMessage,
    setUploadingProgress,
    setFailedStatusMessage,
  } = useSendLexZapMessage();
  const [selected, setSelected] = React.useState<FileProp>(files[0]);

  const handleSubmit = async () => {
    if (applicantPhoneNumber) {
      files.forEach(async (file) => {
        let fileType = isValidMessageToSendType(file.type) ? file.type : CaseMessageToSendType.DOCUMENT;

        if (fileSelector === "document" || fileType === CaseMessageToSendType.TEXT) {
          fileType = CaseMessageToSendType.DOCUMENT;
        }

        const sendMessageParams: SendLexZapMessageParams = {
          applicantPhoneNumber,
          message: {
            type: fileType,
            body: "",
            media: {
              filename: file.name,
              url: file.url,
              mimeType: file.mimeType,
              location: "",
            },
          },
          requestId: uuidV4(),
        };

        createPreviewMessage(sendMessageParams);

        try {
          const responseUpload = await handleUploadMedia({
            fileBlob: file.blob,
            fileName: file.name,
            requestId: sendMessageParams.requestId,
          });

          if (!responseUpload) return;

          const { location } = responseUpload;

          if ("media" in sendMessageParams.message && sendMessageParams.message.media) {
            sendMessageParams.message.media.location = location;
          }

          sendMessage(sendMessageParams);
        } catch {
          setFailedStatusMessage({
            messageId: sendMessageParams.requestId,
            applicantPhoneNumber,
          });
        }
      });
    }
  };

  const handleSubmitFiles = async () => {
    onClose();
    await handleSubmit();
  };

  const handleUploadMedia = async ({
    fileBlob,
    fileName,
    requestId,
  }: {
    fileBlob: Blob;
    fileName: string;
    requestId: string;
  }) => {
    if (!applicantPhoneNumber) return;

    const { signedUrl, location } = await signChatBucketUrl({
      applicantPhoneNumber: applicantPhoneNumber,
      fileName: fileName,
    });

    await uploadFileToS3(signedUrl, fileBlob, (progress) =>
      setUploadingProgress({ applicantPhoneNumber, messageId: requestId, progress })
    );

    return { location, fileName, fileBlob };
  };

  useEffect(() => {
    if (files.some((file) => file.url === selected.url)) return;
    setSelected(files[0]);
  }, [files, selected]);

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowX: "hidden",
        overflowY: "hidden",
        gap: 0.5,
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "100%",
          width: "100%",
          flexDirection: "column",
          paddingX: 2,
          overflowX: "hidden",
        }}
      >
        <Box sx={{ height: "24px", display: "flex", alignItems: "center" }}>
          <CloseIcon
            sx={{
              fontSize: "16px",
              cursor: "pointer",
            }}
            onClick={onClose}
          />
        </Box>
        {fileSelector !== "document" && selected.type === "image" ? (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              overflowY: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={selected.url}
              alt={selected.name}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Box>
        ) : fileSelector !== "document" && selected.type === "video" ? (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              overflowY: "hidden",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <video
              src={selected.url}
              controls
              style={{
                width: "100%",
                height: "100% ",
                objectFit: "contain",
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
              }}
            >
              <InsertDriveFileIcon
                sx={{
                  color: "grey.400",
                  fontSize: "56px",
                }}
              />
              <Typography
                sx={{
                  color: "grey.400",
                  fontSize: "16px",
                }}
              >
                {selected.name}
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <SubmitFilesControl
        files={files}
        selected={selected}
        setSelected={setSelected}
        removeFile={removeFile}
        handleSubmitFiles={handleSubmitFiles}
        openFilesSelector={openFilesSelector}
      />
    </Box>
  );
};

const SubmitFilesControl = ({
  files,
  selected,
  setSelected,
  removeFile,
  handleSubmitFiles,
  openFilesSelector,
}: {
  files: FileProp[];
  selected: FileProp;
  setSelected: Dispatch<React.SetStateAction<FileProp>>;
  removeFile: (file: FileProp) => void;
  handleSubmitFiles: () => void;
  openFilesSelector: () => void;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        paddingX: 3,
        paddingY: 2,
        gap: 3,
        width: "100%",
        alignItems: "center",
        justifyContent: "space-between",
        paddingRight: 11,
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: `auto auto`,
            maxWidth: "100%",
            gap: 1,
            height: "56px",
          }}
        >
          <Box
            sx={{
              gap: 1,
              display: "grid",
              gridAutoFlow: "column",
              width: "100%",
              paddingBottom: 0.6,
              overflowX: "auto",
              overflowY: "hidden",
              "&::-webkit-scrollbar": { height: "6px" },
              "&::-webkit-scrollbar-track": {
                borderRadius: "10px",
                backgroundColor: "common.white",
              },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                backgroundColor: "grey.400",
              },
            }}
          >
            {files?.map((file) => (
              <FileItem
                key={file.url}
                onSelect={() => {
                  setSelected(file);
                }}
                removeFile={() => {
                  removeFile(file);
                }}
                isSelected={selected === file}
              />
            ))}
          </Box>
          <AddMore openFilesSelector={openFilesSelector} />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "56px",
          height: "56px",
          paddingLeft: 0.5,
          borderRadius: "100%",
          backgroundColor: "orange.light.90",
          flexShrink: 0,
          position: "relative",
          boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
          cursor: "pointer",
        }}
        onClick={handleSubmitFiles}
      >
        <Box
          sx={{
            position: "absolute",
            width: "20px",
            height: "20px",
            borderRadius: "100%",
            backgroundColor: "common.white",
            top: 0,
            right: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)",
          }}
        >
          <Typography
            sx={{
              cursor: "default",
            }}
            variant="body1"
          >
            {files.length}
          </Typography>
        </Box>
        <SendIcon
          sx={{
            fontSize: "2rem",
            color: "common.white",
          }}
        />
      </Box>
    </Box>
  );
};

const AddMore = ({ openFilesSelector }: { openFilesSelector: () => void }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "48px",
        height: "48px",
        borderRadius: "8px",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "grey.400",
        cursor: "pointer",
        flexShrink: 0,
      }}
      onClick={openFilesSelector}
    >
      <AddIcon
        sx={{
          color: "grey.400",
        }}
      />
    </Box>
  );
};

const FileItem = ({
  onSelect,
  removeFile,
  isSelected,
}: {
  onSelect: () => void;
  removeFile: () => void;
  isSelected: boolean;
}) => {
  const [showClose, setShowClose] = React.useState(false);

  return (
    <Box
      sx={{
        position: "relative",
      }}
      onMouseEnter={() => setShowClose(true)}
      onMouseLeave={() => setShowClose(false)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "48px",
          height: "48px",
          borderRadius: "8px",
          borderWidth: isSelected ? 2 : 1,
          borderStyle: "solid",
          borderColor: isSelected ? "orange.light.90" : "grey.400",
          cursor: "pointer",
          position: "relative",
          flexShrink: 0,
        }}
        onClick={onSelect}
      >
        <InsertDriveFileIcon
          sx={{
            color: "grey.400",
          }}
        />
      </Box>
      <CloseIcon
        sx={{
          position: "absolute",
          color: "grey.400",
          top: 0,
          right: 0,
          fontSize: "18px",
          zIndex: 100,
          display: showClose ? "block" : "none",
          visibility: showClose ? "visible" : "hidden",
          cursor: "pointer",
        }}
        onClick={removeFile}
      />
    </Box>
  );
};
