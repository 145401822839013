import { PlanType } from "@/hooks/credits/types";
import { calculateDaysToEndPlan } from "@/utils/plans";
import { Chip } from "@mui/material";
import React, { useMemo } from "react";

interface PlanChipProps {
  planType: PlanType;
  nextBillingDate?: string;
}

export const PlanChip = ({ planType, nextBillingDate }: PlanChipProps) => {
  const trialText = useMemo(() => {
    if (nextBillingDate) {
      const daysToEndTrial = calculateDaysToEndPlan(nextBillingDate);
      if (daysToEndTrial < 1) {
        return "Período de teste: Expirado";
      }
      if (daysToEndTrial === 1) {
        return "Período de teste: 1 dia";
      }
      return `Período de teste: ${daysToEndTrial} dias`;
    }
    return "Período de teste";
  }, [nextBillingDate]);

  const { text, color, backgroundColor } = useMemo(() => {
    switch (planType) {
      case PlanType.TRIAL: {
        return {
          backgroundColor: "plan.trial.background",
          color: "plan.trial.text",
          text: trialText,
        };
      }
      case PlanType.INITIAL: {
        return {
          backgroundColor: "plan.initial.background",
          color: "plan.initial.text",
          text: "Plano Inicial",
        };
      }
      case PlanType.BASIC: {
        return {
          backgroundColor: "plan.basic.background",
          color: "plan.basic.text",
          text: "Plano Básico",
        };
      }
      case PlanType.INTERMEDIARY: {
        return {
          backgroundColor: "plan.intermediary.background",
          color: "plan.intermediary.text",
          text: "Plano Intermediário",
        };
      }
      case PlanType.ADVANCED: {
        return {
          backgroundColor: "plan.advanced.background",
          color: "plan.advanced.text",
          text: "Plano Avançado",
        };
      }
      case PlanType.PROFESSIONAL: {
        return {
          backgroundColor: "plan.professional.background",
          color: "plan.professional.text",
          text: "Plano Profissional",
        };
      }
      case PlanType.CUSTOM: {
        return {
          backgroundColor: "plan.custom.background",
          color: "plan.custom.text",
          text: "Plano Personalizado",
        };
      }
      case PlanType.UNLIMITED: {
        return {
          backgroundColor: "plan.unlimited.background",
          color: "plan.unlimited.text",
          text: "Plano Essencial",
        };
      }
      case PlanType.PROFESSIONAL_CUSTOM:
      case PlanType.PROFESSIONAL_WITH_WPP: {
        return {
          backgroundColor: "plan.unlimited.background",
          color: "plan.unlimited.text",
          text: "Plano Profissional",
        };
      }
      case PlanType.PERFORMANCE: {
        return {
          backgroundColor: "plan.unlimited.background",
          color: "plan.unlimited.text",
          text: "Plano Performance",
        };
      }
      default: {
        return {
          backgroundColor: "plan.free.background",
          color: "plan.free.text",
          text: "Plano Cortesia",
        };
      }
    }
  }, [planType]);

  return <Chip label={text} sx={{ backgroundColor, color }} size="small" />;
};
