import { buildSkillStep } from "@/core/skillForms/builder";

export const summarizeDocumentSkillStep1 = buildSkillStep({
  description: "Resuma peças e decisões judiciais com poucos cliques e tenha as informações necessárias em minutos.",
  fields: [
    {
      name: "procedural_document_file",
      overrideField: "procedural_document",
      type: "file",
      required: true,
      ui: {
        label: "Selecione o arquivo a ser resumido",
      },
    },
  ],
  submitButtonText: "Resumir Documento",
});
