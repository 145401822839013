import { useCallback, useEffect, useRef } from "react";

export const useScrollHandler = ({
  onScrollDown,
  onScrollUp,
}: {
  onScrollDown?: () => void;
  onScrollUp?: () => void;
}) => {
  const ref = useRef<HTMLElement>();

  const handleScroll = useCallback(
    (e: WheelEvent) => {
      if (e.deltaY > 0) {
        onScrollDown?.();
      } else if (e.deltaY < 0) {
        onScrollUp?.();
      }
    },
    [onScrollDown, onScrollUp]
  );

  useEffect(() => {
    if (!ref.current) return;

    const element = ref.current;

    element.addEventListener("wheel", handleScroll);
    return () => {
      element.removeEventListener("wheel", handleScroll);
    };
  }, [handleScroll]);

  return { ref };
};
