import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthContext } from "@/contexts/AuthContext";
import { useOabs } from "@/hooks/oabs/useOabs";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { ImportSingleOabModal } from "@/pages/WhatsappAssistantCases/components/ImportSingleOabModal";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Menu, MenuItem } from "@mui/material";
import { Button } from "@/components/Button";
import { LegalProceedingImportByCNJModal } from "@/components/LegalProceedingImportByCNJ";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { usePaywallContext } from "@/contexts/PaywallContext";
import { usePlanChips } from "@/hooks/usePlanChips";

interface NewCasesActionButtonsProps {
  onCreateCase: () => void;
  /**
   * If true, the user will be redirected to the cases page after the case is created.
   * @default false
   */
  shouldRedirectToCases?: boolean;
}

export const NewCasesActionButtons = ({ onCreateCase, shouldRedirectToCases = false }: NewCasesActionButtonsProps) => {
  const navigate = useNavigate();
  const { openUsagePaywall } = usePaywallContext();
  const { isCaseQuotaReached } = usePlanChips();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openImportOAB, setOpenImportOAB] = useState(false);
  const [openImportLegalProceedingByCNJ, setOpenImportLegalProceedingByCNJ] = useState(false);
  const { legalProceedingsPageEnabled, createCaseByCnjEnabled } = useFeatureFlags();
  const { user } = useAuthContext();
  const { data: oabs, isLoading: isLoadingOabs } = useOabs(user!.userId);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isCaseQuotaReached) {
      openUsagePaywall();
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseImportOAB = () => {
    setOpenImportOAB(false);
  };

  const handleOpenImportingByOAB = () => {
    navigate(ROUTE_PATHS.IMPORT_LEGAL_PROCEEDINGS_BY_OAB);
  };

  const handleCloseImportLegalProceedingByCNJ = () => {
    handleCloseImportLegalProceedingByCNJModal();

    if (shouldRedirectToCases) navigate(ROUTE_PATHS.CASES);
  };

  const handleCloseImportLegalProceedingByCNJModal = () => {
    setOpenImportLegalProceedingByCNJ(false);
  };

  const hasOabs = useMemo(() => {
    return oabs?.data && oabs.data.length > 0;
  }, [oabs]);

  const handleOpenImportOAB = () => {
    if (hasOabs) {
      navigate(ROUTE_PATHS.IMPORT_LEGAL_PROCEEDINGS_BY_OAB);
    } else {
      setOpenImportOAB(true);
      handleClose();
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "row", gap: 2, alignItems: "center", justifyContent: "space-between" }}>
      <LegalProceedingImportByCNJModal
        open={openImportLegalProceedingByCNJ}
        onClose={handleCloseImportLegalProceedingByCNJModal}
        onSuccess={handleCloseImportLegalProceedingByCNJ}
      />
      <ImportSingleOabModal
        open={openImportOAB}
        onClose={handleCloseImportOAB}
        handleOpenImportingByOAB={handleOpenImportingByOAB}
      />
      <Button variant="contained" onClick={handleClick} endIcon={KeyboardArrowDown} color="secondary" size="small">
        Abrir novo caso
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          mt: 1,
        }}
      >
        <MenuItem
          onClick={() => {
            onCreateCase();
            handleClose();
          }}
          sx={{
            borderBottom: "1px solid #E0E0E0",
          }}
        >
          Manualmente
        </MenuItem>
        {legalProceedingsPageEnabled && (
          <MenuItem onClick={handleOpenImportOAB} disabled={isLoadingOabs}>
            {hasOabs ? "Visualizar processos da OAB" : "Importar processo por OAB"}
          </MenuItem>
        )}
        {createCaseByCnjEnabled && (
          <MenuItem
            onClick={() => {
              setOpenImportLegalProceedingByCNJ(true);
              handleClose();
            }}
          >
            Importar processo por Número
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
};
