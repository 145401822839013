import React from "react";
import { Typography } from "@mui/material";
import { Ticket } from "@/hooks/lexZap/types";

export const TicketSummary = ({ ticket }: { ticket: Ticket }) => {
  return (
    <Typography variant="body1" sx={{ padding: 3, color: "text.primary", lineHeight: "19px" }}>
      {ticket.summary || "Assunto ainda não foi gerado"}
    </Typography>
  );
};
