import React from "react";
import { Box, Skeleton } from "@mui/material";

export const NoteItemSkeleton = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Skeleton variant="text" width={80} height={20} />
          <Skeleton variant="text" width={50} height={20} />
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          <Skeleton variant="rounded" width={70} height={40} />
          <Skeleton variant="rounded" width={60} height={40} />
        </Box>
      </Box>

      <Box>
        <Skeleton variant="text" width="90%" height={20} />
        <Skeleton variant="text" width="80%" height={20} />
        <Skeleton variant="text" width="95%" height={20} />
      </Box>
    </Box>
  );
};
