import React, { useMemo, useState } from "react";
import { Box, Button, TextField } from "@mui/material";
import { Note } from "@/services/note/types";
import { useEditNote } from "@/hooks/notes/useEditNote";
import { useCreateNote } from "@/hooks/notes/useCreateNote";
import { WebToast } from "@/components/core/Toast";
import { logger } from "@/core/logger";

const noteCharactersLimit = 60000;
export const EditNote = ({ note, ticketId, onClose }: { note?: Note; ticketId: string; onClose: () => void }) => {
  const [noteContent, setNoteContent] = useState(note?.content || "");
  const noteContentExceededLimit = useMemo(() => {
    return noteContent.length > noteCharactersLimit;
  }, [noteContent]);
  const { mutateAsync: updateNote, isPending: isLoadingUpdateNote } = useEditNote();
  const { mutateAsync: createNote, isPending: isLoadingCreateNote } = useCreateNote();
  const isRequestPending = isLoadingUpdateNote || isLoadingCreateNote;

  const handleSave = async () => {
    try {
      if (note) {
        await updateNote({
          noteId: note.id,
          applicantId: note.applicantId,
          caseId: note.caseId,
          ticketId: note.ticketId,
          data: { content: noteContent },
        });
      } else {
        await createNote({ data: { content: noteContent, ticketId } });
      }
      onClose();
      WebToast.success("Anotação salva com sucesso");
    } catch (error) {
      WebToast.error("Erro ao salvar anotação");
      logger.error("Erro ao salvar anotação", error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 1,
        }}
      >
        <Button disabled={isRequestPending} variant="text" onClick={onClose}>
          Cancelar
        </Button>
        <Button
          disabled={isRequestPending || noteContentExceededLimit || !noteContent}
          variant="text"
          onClick={handleSave}
        >
          Salvar
        </Button>
      </Box>

      <TextField
        sx={{
          width: "100%",
        }}
        disabled={isRequestPending}
        onChange={(e) => setNoteContent(e.target.value)}
        placeholder="Faça sua anotação aqui"
        label={note ? "Anotação" : "Nova anotação"}
        multiline
        rows={6}
        maxRows={4}
        value={noteContent}
        error={noteContentExceededLimit}
        helperText={noteContentExceededLimit ? "Deve ter no máximo 60.000 caracteres" : ""}
      />
    </Box>
  );
};
