import { UnderlinedButton } from "@/components/Button";
import { PageLayout } from "@/components/PageLayout";
import { Archive, ArrowOutward, DeleteForever, Edit, Unarchive } from "@mui/icons-material";
import {
  Theme,
  SxProps,
  Typography,
  Skeleton,
  Badge,
  MenuItem,
  InputLabel,
  Select,
  SelectChangeEvent,
  Chip,
  Tooltip,
  CircularProgress,
  Tabs,
  Tab,
  Button,
} from "@mui/material";
import { Box, FormControl } from "@mui/material";
import React, { useMemo } from "react";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { CreateCaseModal } from "@/components/Cases/createCaseModal";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useLexZapCases } from "@/hooks/lexZap/useLexZapCases";
import { ptBR } from "@mui/x-data-grid/locales";
import { getCasePath } from "@/routes/routePaths";
import { useNavigate } from "react-router-dom";
import { useArchiveCases } from "@/hooks/lexZap/useArchiveCases";
import { useDeleteCases } from "@/hooks/lexZap/useDeleteCases";
import { useUnarchiveCases } from "@/hooks/lexZap/useUnarchiveCases";
import { EditCaseModal } from "@/components/Cases";
import { ApplicantModal } from "@/components/Applicants/ApplicantModal";
import { ArchiveCasesModal } from "../components/CardsView/components/ArchiveCasesModal";
import { DeleteCasesModal } from "../components/CardsView/components/DeleteCasesModal";
import { UnarchiveCasesModal } from "../components/CardsView/components/UnarchiveCasesModal";
import { CustomCasesListToolbar } from "./components/DataGridComponents";
import { NewCasesActionButtons } from "./components/NewCasesActionButtons";
import { Case } from "@/hooks/lexZap/types";
import { LegalProceedingImportByCNJModal } from "@/components/LegalProceedingImportByCNJ";
import { useNotificationsQuery } from "@/hooks/notifications/useNotificationsQuery";
import { LexterDate } from "@/core/LexterDate";
import { useOpenNotificationMutation } from "@/hooks/notifications/useOpenNotificationMutation";
import { Notification } from "@/services/notification/types";
import { WebToastColoredWithTitle } from "@/components/core/Toast";

export const NewCasesPage = () => {
  const [tab, setTab] = React.useState("ACTIVE");
  const { data: casesData, isLoading: isLoadingCases } = useLexZapCases();
  const { mutateAsync: archiveCases, isPending: isArchivingCases } = useArchiveCases();
  const { mutateAsync: deleteCases, isPending: isDeletingCases } = useDeleteCases();
  const { mutateAsync: unarchiveCases, isPending: isUnarchivingCases } = useUnarchiveCases();
  const navigate = useNavigate();
  const { legalProceedingsPageEnabled } = useFeatureFlags();
  const [queryCases, setQueryCases] = React.useState<string>("");
  const { data: notifications, isLoading: isLoadingNotifications } = useNotificationsQuery();

  const cases = useMemo(() => {
    if (!casesData) return [];

    return casesData
      .sort((a, b) => {
        const dateA = b.lastModifiedAt || b.createdAt;
        const dateB = a.lastModifiedAt || a.createdAt;
        return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
      })
      .map((caseData) => ({
        ...caseData,
        isProcessingLegalProceedings: caseData.cnjs && caseData.cnjs.length > 0 && caseData.name === "",
      }));
  }, [casesData]);

  const activeCases = useMemo(() => {
    return cases.filter((caseItem) => caseItem.status === "ACTIVE");
  }, [cases]);

  const filteredCases = useMemo(() => {
    if (!cases) return [];

    const searchTerm = queryCases.toLowerCase().trim();

    return cases.filter(
      (caseItem) =>
        caseItem.status === tab &&
        (caseItem.name?.toLowerCase().includes(searchTerm) ||
          caseItem.applicantName?.toLowerCase().includes(searchTerm) ||
          caseItem.cnjs?.some((cnj) => cnj.toLowerCase().includes(searchTerm)))
    );
  }, [cases, queryCases, tab]);

  const [isOpenDeleteCases, setIsOpenDeleteCases] = React.useState(false);
  const [isOpenArchiveCases, setIsOpenArchiveCases] = React.useState(false);
  const [isOpenUnarchiveCases, setIsOpenUnarchiveCases] = React.useState(false);
  const [isOpenCreateCase, setIsOpenCreateCase] = React.useState(false);
  const [isEditCaseModalOpen, setIsEditCaseModalOpen] = React.useState(false);
  const [isEditApplicantModalOpen, setIsEditApplicantModalOpen] = React.useState(false);
  const [currentEditingCase, setCurrentEditingCase] = React.useState<string | null>(null);
  const [currentEditingApplicant, setCurrentEditingApplicant] = React.useState<string | null>(null);

  const handleOpenEditCase = (caseId: string) => {
    setCurrentEditingCase(caseId);
    setIsEditCaseModalOpen(true);
  };

  const handleCloseEditCase = () => {
    setIsEditCaseModalOpen(false);
    setCurrentEditingCase(null);
  };

  const handleOpenDeleteCase = (caseId: string) => {
    setCurrentEditingCase(caseId);
    setIsOpenDeleteCases(true);
  };

  const handleCloseDeleteCases = () => {
    setIsOpenDeleteCases(false);
  };

  const handleOpenUnarchiveCases = (caseId: string) => {
    setCurrentEditingCase(caseId);
    setIsOpenUnarchiveCases(true);
  };

  const handleOpenArchiveCase = (caseId: string) => {
    setCurrentEditingCase(caseId);
    setIsOpenArchiveCases(true);
  };

  const handleCloseArchiveCases = () => {
    setIsOpenArchiveCases(false);
  };

  const handleCloseUnarchiveCases = () => {
    setIsOpenUnarchiveCases(false);
  };

  const handleOpenCreateCase = () => {
    setIsOpenCreateCase(true);
  };

  const handleCloseCreateCase = () => {
    setIsOpenCreateCase(false);
  };

  const handleDeleteCases = async () => {
    await deleteCases({ casesIds: [currentEditingCase!] });
    handleCloseDeleteCases();
  };

  const handleArchiveCases = async () => {
    try {
      await archiveCases({ casesIds: [currentEditingCase!] });
      WebToastColoredWithTitle.success("Caso arquivado", {
        position: "bottom-center",
        actions: (
          <Button
            color="inherit"
            size="small"
            sx={{ ml: 2, color: "common.black", textTransform: "none" }}
            onClick={handleUnarchiveCases}
          >
            Desfazer
          </Button>
        ),
      });
    } catch (e) {
      WebToastColoredWithTitle.error(
        "Erro ao arquivar caso",
        "Infelizmente não foi possível completar a operação. Tente novamente mais tarde."
      );
    } finally {
      handleCloseArchiveCases();
    }
  };

  const handleUnarchiveCases = async () => {
    try {
      await unarchiveCases({ casesIds: [currentEditingCase!] });
      WebToastColoredWithTitle.success("Caso desarquivado", {
        position: "bottom-center",
      });
    } catch (e) {
      WebToastColoredWithTitle.error(
        "Erro ao desarquivar caso",
        "Infelizmente não foi possível completar a operação. Tente novamente mais tarde."
      );
    } finally {
      handleCloseUnarchiveCases();
    }
  };

  const handleCloseEditApplicantModal = () => {
    setIsEditApplicantModalOpen(false);
    setCurrentEditingApplicant(null);
  };

  const casesWithUpdates = useMemo(
    () =>
      activeCases.filter((c) =>
        notifications?.some((n) => n.notificationType === "CASE_UPDATED" && !n.visualizedAt && c.id === n.data.caseId)
      ),
    [notifications, activeCases]
  );

  const customToolbar = React.useCallback(
    () => <CustomCasesListToolbar disabled={false} onSearch={setQueryCases} />,
    [setQueryCases]
  );

  const handleTabChange = (_: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  const handleOpenArchiveOrUnarchiveCase = (caseData: Case) => {
    if (caseData.status === "ARCHIVED") {
      handleOpenUnarchiveCases(caseData.id);
    } else {
      handleOpenArchiveCase(caseData.id);
    }
  };

  return (
    <PageLayout
      contentProps={{
        fullWidth: true,
      }}
      sx={{
        "& .page-layout-container": {
          padding: 5,
        },
      }}
      variant="secondary"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          alignItems: "center",
          justifyContent: "space-between",
          mb: 4,
        }}
      >
        <Typography variant="h5">Casos</Typography>

        <NewCasesActionButtons onCreateCase={handleOpenCreateCase} />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 2,
          alignItems: "stretch",
          minHeight: "120px",
          mb: 4,
        }}
      >
        <InfoCard title="Total de casos abertos" value={activeCases.length.toString()} loading={isLoadingCases} />
        {legalProceedingsPageEnabled && (
          <InfoCard
            title="Casos com atualizações"
            value={`${casesWithUpdates.length}`}
            loading={isLoadingCases || isLoadingNotifications}
          />
        )}
      </Box>
      <Box
        sx={{
          height: isLoadingCases ? "525px" : "auto",
          border: "1px solid",
          borderColor: "divider",
          borderRadius: 2,
          mt: 2,
        }}
      >
        <Tabs
          value={tab}
          onChange={handleTabChange}
          sx={{
            ml: 2,
            "& .MuiTab-root": {
              textTransform: "none",
              pb: 1,
              pt: 1,
              minHeight: "48px",
              fontWeight: 400,
            },
            "& .Mui-selected": {
              fontWeight: "600 !important",
            },
            "& .MuiSvgIcon-root": {
              fontSize: "20px",
            },
          }}
        >
          <Tab label="Abertos" value="ACTIVE" />
          <Tab label="Arquivados" value="ARCHIVED" />
        </Tabs>
        <DataGrid
          pageSizeOptions={[10, 25, 50, 100]}
          initialState={{
            columns: {
              columnVisibilityModel: {
                status: false,
              },
            },
            sorting: {
              sortModel: [{ field: "Monitoramento", sort: "desc" }],
            },
          }}
          columns={[
            {
              field: "Monitoramento",
              headerName: "",
              resizable: false,
              align: "center",
              width: 80,
              filterable: false,
              valueGetter: (_, row) => {
                return (
                  notifications?.filter(
                    (n) => n.notificationType === "CASE_UPDATED" && !n.visualizedAt && n.data.caseId === row.id
                  ).length ?? 0
                );
              },
              renderCell: (params) => {
                return (
                  <MonitoringIcon
                    id={params.row.id}
                    notifications={notifications ?? []}
                    numberOfNotifications={params.value}
                    disabled={params.row.isProcessingLegalProceedings}
                  />
                );
              },
            },
            {
              field: "name",
              headerName: "Caso",
              flex: 1,
              renderCell: (params) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      height: "100%",
                      gap: 0.5,
                    }}
                  >
                    {params.row.isProcessingLegalProceedings ? (
                      <Typography variant="body1" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        Importando dados do processo
                        <span>
                          <CircularProgress size={14} thickness={2} />
                        </span>
                      </Typography>
                    ) : (
                      <Typography
                        variant="body1"
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate(getCasePath({ caseId: params.row.id }))}
                      >
                        {params.row.name}
                      </Typography>
                    )}
                    {legalProceedingsPageEnabled && (
                      <LegalProocedingCNJs caseItem={params.row} loading={isLoadingCases} />
                    )}
                  </Box>
                );
              },
            },
            {
              field: "legalArea",
              headerName: "Área",
              flex: 0.75,
              renderCell: (params) => {
                return params.value ? (
                  <Tooltip placement="top" title={params.value}>
                    <Chip
                      sx={{
                        backgroundColor: "orange.light.30",
                        textOverflow: "ellipsis",
                      }}
                      label={params.value}
                      variant="filled"
                    />
                  </Tooltip>
                ) : (
                  <UnderlinedButton
                    sx={{ minWidth: "auto" }}
                    onClick={() => handleOpenEditCase(params.row.id)}
                    disabled={params.row.isProcessingLegalProceedings}
                  >
                    Definir
                  </UnderlinedButton>
                );
              },
            },
            {
              field: "applicantName",
              headerName: "Cliente",
              maxWidth: 250,
              flex: 0.5,
              renderCell: (params) => {
                if (params.row.applicantName) {
                  return <div>{params.row.applicantName}</div>;
                }

                return (
                  <UnderlinedButton
                    disabled={params.row.isProcessingLegalProceedings}
                    onClick={() => handleOpenEditCase(params.row.id)}
                  >
                    Vincular cliente
                  </UnderlinedButton>
                );
              },
            },
            {
              field: "lastModifiedAt",
              headerName: "Última atualização",
              width: 150,
              valueGetter: (_, row) => {
                const dateStr = row.lastModifiedAt || row.createdAt;
                if (dateStr) {
                  const date = LexterDate.fromCortexString(dateStr);
                  if (date.isSuccess) return date.getValue().toTimestamp();
                }
                return 0;
              },
              renderCell: (params) => {
                const dateStr = params.value;
                if (dateStr) {
                  const date = LexterDate.fromTimestamp(dateStr);
                  if (date.isSuccess) return <span>{date.getValue().toDisplayString()}</span>;
                }
                return null;
              },
            },
            // TODO: Implementar responsável quando lógica estiver pronta
            // {
            //   field: "responsible",
            //   headerName: "Responsável",
            //   flex: 1,
            // },
            {
              field: "Ações",
              type: "actions",
              headerName: "",
              width: 50,
              resizable: false,
              align: "right",
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              getActions: ({ row }) => {
                return [
                  <GridActionsCellItem
                    key={`${row.id}-access`}
                    label="Acessar"
                    onClick={() => navigate(getCasePath({ caseId: row.id }))}
                    icon={<ArrowOutward fontSize="small" />}
                    disabled={row.isProcessingLegalProceedings}
                    showInMenu
                  />,
                  <GridActionsCellItem
                    key={`${row.id}-edit`}
                    label="Editar"
                    onClick={() => handleOpenEditCase(row.id)}
                    icon={<Edit fontSize="small" />}
                    disabled={row.isProcessingLegalProceedings}
                    showInMenu
                  />,
                  <GridActionsCellItem
                    key={`${row.id}-archive`}
                    label={row.status === "ARCHIVED" ? "Desarquivar" : "Arquivar"}
                    onClick={() => handleOpenArchiveOrUnarchiveCase(row)}
                    icon={row.status === "ARCHIVED" ? <Unarchive fontSize="small" /> : <Archive fontSize="small" />}
                    showInMenu
                  />,
                  <GridActionsCellItem
                    key={`${row.id}-delete`}
                    label="Deletar"
                    onClick={() => handleOpenDeleteCase(row.id)}
                    icon={<DeleteForever fontSize="small" />}
                    showInMenu
                  />,
                ];
              },
            },
            {
              field: "status",
              headerName: "Status",
              hideable: false,
              filterOperators: [
                {
                  value: "is",
                  getApplyFilterFn: () => () => true,
                  InputComponent: () => {
                    return (
                      <FormControl variant="standard" sx={{ height: "100%", justifyContent: "flex-end" }}>
                        <InputLabel>Valor</InputLabel>
                        <Select
                          value={["ACTIVE", "ARCHIVED"].includes(queryCases) ? queryCases : "ACTIVE"}
                          defaultValue="ACTIVE"
                          onChange={(e: SelectChangeEvent) => {
                            setQueryCases(e.target.value);
                          }}
                          placeholder="Filtrar valor"
                          size="small"
                        >
                          <MenuItem value="ACTIVE">Ativo</MenuItem>
                          <MenuItem value="ARCHIVED">Arquivado</MenuItem>
                        </Select>
                      </FormControl>
                    );
                  },
                },
              ],
              renderCell: (params) => {
                const statusMap = {
                  ACTIVE: "Ativo",
                  INACTIVE: "Inativo",
                };
                return <div>{statusMap[params.value as keyof typeof statusMap] || params.value}</div>;
              },
            },
          ]}
          rows={filteredCases}
          getRowHeight={() => 70}
          loading={isLoadingCases}
          disableRowSelectionOnClick
          disableColumnFilter
          localeText={{ ...ptBR.components.MuiDataGrid.defaultProps.localeText, toolbarFilters: "Filtrar" }}
          slots={{
            noRowsOverlay: () => (
              <Typography textAlign="center" variant="body1" mt={10}>
                Nenhum caso encontrado
              </Typography>
            ),
            toolbar: customToolbar,
          }}
          slotProps={{
            loadingOverlay: {
              variant: "skeleton",
              noRowsVariant: "skeleton",
            },
          }}
          sx={{
            "& .MuiDataGrid-virtualScroller": {
              overflow: isLoadingCases ? "hidden" : "scroll",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: 700,
            },
          }}
          autoHeight={isLoadingCases ? false : true}
        />
      </Box>
      <EditCaseModal isOpen={isEditCaseModalOpen} caseId={currentEditingCase!} onClose={handleCloseEditCase} />

      <DeleteCasesModal
        isOpen={isOpenDeleteCases}
        onConfirm={handleDeleteCases}
        onCancel={handleCloseDeleteCases}
        isLoading={isDeletingCases}
        amount={1}
      />

      <ArchiveCasesModal
        isOpen={isOpenArchiveCases}
        onConfirm={handleArchiveCases}
        onCancel={handleCloseArchiveCases}
        isLoading={isArchivingCases}
        amount={1}
      />

      <UnarchiveCasesModal
        isOpen={isOpenUnarchiveCases}
        onConfirm={handleUnarchiveCases}
        onCancel={handleCloseUnarchiveCases}
        isLoading={isUnarchivingCases}
        amount={1}
      />
      {currentEditingApplicant && (
        <ApplicantModal
          applicantId={currentEditingApplicant}
          isOpen={isEditApplicantModalOpen}
          onClose={handleCloseEditApplicantModal}
        />
      )}
      {isOpenCreateCase && <CreateCaseModal onClose={handleCloseCreateCase} />}
    </PageLayout>
  );
};

const LegalProocedingCNJs = ({ caseItem, loading }: { caseItem: Case; loading: boolean }) => {
  const [open, setOpen] = React.useState(false);
  if (loading) return <Skeleton variant="text" width="150px" height={32} />;

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  if (!caseItem.cnjs || caseItem.cnjs.length === 0) {
    return (
      <>
        <UnderlinedButton onClick={handleOpen}>Vincular processo </UnderlinedButton>
        <LegalProceedingImportByCNJModal
          open={open}
          onClose={handleClose}
          onSuccess={handleClose}
          caseData={caseItem}
        />
      </>
    );
  }

  return (
    <Typography variant="body1" color="text.disabled">
      {caseItem.cnjs?.join(", ")}
    </Typography>
  );
};

interface InfoCardProps {
  title: string;
  value: string;
  loading?: boolean;
  sx?: SxProps<Theme>;
}

const InfoCard = ({ title, value, sx, loading }: InfoCardProps) => {
  return (
    <Box
      sx={{
        bgcolor: "grey.50",
        borderRadius: 2,
        px: 2,
        py: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flex: 1,
        ...sx,
      }}
    >
      <Typography variant="subtitle2">{title}</Typography>
      {loading ? <Skeleton variant="text" width="150px" height={32} /> : <Typography variant="h5">{value}</Typography>}
    </Box>
  );
};

const MonitoringIcon = ({
  id,
  notifications,
  numberOfNotifications,
  disabled,
}: {
  id: string;
  notifications: Notification[];
  numberOfNotifications: number;
  disabled?: boolean;
}) => {
  const { mutateAsync: openNotification } = useOpenNotificationMutation();
  const navigate = useNavigate();

  const handleNotificationClick = (caseId: string) => {
    if (notifications) {
      for (const notification of notifications) {
        if (
          notification.notificationType === "CASE_UPDATED" &&
          !notification.visualizedAt &&
          notification.data.caseId === caseId
        ) {
          void openNotification(notification.id);
        }
      }
    }
    navigate(getCasePath({ caseId }));
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        cursor: numberOfNotifications > 0 ? "pointer" : "default",
      }}
      onClick={disabled ? undefined : () => handleNotificationClick(id)}
      component="span"
    >
      <Badge
        badgeContent={numberOfNotifications}
        color="primary"
        sx={{
          "& .MuiBadge-badge": {
            color: "common.white",
            fontWeight: "700",
            fontSize: "12px",
            height: "20px",
            minWidth: "20px",
          },
        }}
      ></Badge>
    </Box>
  );
};
