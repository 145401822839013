import { Badge, Box, IconButton, Tab, Tabs } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Search } from "./components/Search";
import { ChatItem } from "./components/ChatItem";
import { EmptyList } from "./components/EmptyList";
import { ChatItemSkeleton } from "./components/ChatItem/skeleton";
import { ChatStatus, LexZapChat } from "@/hooks/lexZap/types";
import { FilterList as FilterIcon } from "@mui/icons-material";
import { useLexZapActiveChat } from "@/hooks/lexZap/useLexZapActiveChat";
import { useLexZapChats } from "@/hooks/lexZap/useLexZapChats";
import { scrollbarStyles } from "@/theme/mixins/scrollbar";
import { FilterModal } from "./components/FilterModal";
import { AnsweredFilter, CaseStatusFilter, ChatFilters } from "./components/FilterModal/types";
import { applyChatFilters } from "./utils/applyChatFilters";
import { calculateFilterChanges } from "./utils/calculateFilterChanges";
import { useSearchParams } from "react-router-dom";

const defaultFilters: ChatFilters = {
  ticketStatus: {
    open: {
      abandoned: true,
      AIService: true,
      redirectedHuman: true,
      initialTriage: true,
    },
    closed: true,
  },
  answered: AnsweredFilter.ALL,
  caseStatus: CaseStatusFilter.ALL,
  responsibles: [],
  areas: [],
};

const WHATSAPP_CHATS_FILTERS_KEY = "whatsapp-assistant-filters";

interface ChatListProps {
  chats?: LexZapChat[];
  isLoading: boolean;
}

export const ChatList = ({ chats = [], isLoading }: ChatListProps) => {
  const { activeChat, setActiveChat } = useLexZapActiveChat();
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState<ChatFilters>(() => {
    const savedFilters = localStorage.getItem(WHATSAPP_CHATS_FILTERS_KEY);

    if (!savedFilters) {
      return defaultFilters;
    }

    return JSON.parse(savedFilters);
  });
  const [searchParams] = useSearchParams();
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [tab, setTab] = useState<ChatStatus>(ChatStatus.ACTIVE);
  useLexZapChats();

  const handleFiltersChange = (filters: ChatFilters) => {
    setFilters(filters);
    localStorage.setItem(WHATSAPP_CHATS_FILTERS_KEY, JSON.stringify(filters));
  };

  const filteredChats = useMemo(() => {
    return applyChatFilters({ chats, status: tab, searchTerm, filters });
  }, [chats, searchTerm, tab, filters]);

  const filterChanges = useMemo(() => {
    return calculateFilterChanges({ filters, defaultFilters });
  }, [filters]);

  useEffect(() => {
    if (isLoading) return;

    let newActiveChat: LexZapChat | undefined;

    const applicantPhoneNumber = searchParams.get("phoneNumber");
    if (applicantPhoneNumber) {
      newActiveChat = chats?.find((chat) => chat.endClientNumber === applicantPhoneNumber);
    }

    if (!newActiveChat && filteredChats?.length) {
      newActiveChat = filteredChats[0];
    }

    if (newActiveChat) {
      setActiveChat(newActiveChat);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "common.white",
        display: "flex",
        flexDirection: "column",
        borderRight: "1px solid",
        borderColor: "common.lightShade",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          alignItems: "center",
          padding: 2,
        }}
      >
        <Search onChange={setSearchTerm} label="Pesquisar" placeholder="Buscar conversa" />

        <Badge badgeContent={filterChanges} color="primary">
          <IconButton
            onClick={() => setIsFilterModalOpen(true)}
            sx={{
              m: -1,
            }}
          >
            <FilterIcon
              sx={{
                color: "common.coral",
                width: "24px",
                height: "24px",
                cursor: "pointer",
              }}
            />
          </IconButton>
        </Badge>
        <FilterModal
          isOpen={isFilterModalOpen}
          onClose={() => setIsFilterModalOpen(false)}
          filters={filters}
          onChange={handleFiltersChange}
        />
      </Box>

      <Tabs
        value={tab}
        onChange={(_, newValue) => setTab(newValue)}
        sx={{
          width: "100%",
          px: 1,
          "& .MuiTab-root": {
            textTransform: "none",
            flex: 1,
            py: 1,
            minHeight: "48px",
            fontWeight: 600,
          },
        }}
      >
        <Tab label="Conversas" value={ChatStatus.ACTIVE} />
        <Tab label="Arquivados" value={ChatStatus.ARCHIVED} />
      </Tabs>

      {isLoading ? (
        <ListContainer>
          {Array.from({ length: 10 }, (_, index) => (
            <ChatItemSkeleton key={index} isActive={index === 0} />
          ))}
        </ListContainer>
      ) : (
        <>
          {!filteredChats?.length ? (
            <EmptyList />
          ) : (
            <ListContainer>
              {filteredChats.map((item) => (
                <ChatItem
                  key={item.endClientNumber}
                  onClick={() => setActiveChat(item)}
                  chat={item}
                  isActive={item.endClientNumber === activeChat?.endClientNumber}
                />
              ))}
            </ListContainer>
          )}
        </>
      )}
    </Box>
  );
};

const ListContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        paddingX: 1,
        paddingY: 1,
        gap: 1,
        ...scrollbarStyles,
      }}
    >
      {children}
    </Box>
  );
};
