import PaywallCard from "@/assets/svgs/paywall-card.svg?react";
import { DialogModal } from "@/components/DialogModal";
import React from "react";
import { useCredits } from "@/hooks/credits/useCredits";
import { Typography } from "@mui/material";

interface CasePaywallModalProps {
  open: boolean;
  onClose: () => void;
  onOpenBilling: () => void;
}

export const CasePaywallModal = ({ open, onClose, onOpenBilling }: CasePaywallModalProps) => {
  const { data: credits } = useCredits();
  const limitQuotaCases = credits?.companyPlan?.limitQuotaCases;

  return (
    <DialogModal
      sx={{
        width: "320px",
        pb: 2,
        px: 1,
      }}
      description={
        <>
          <Typography variant="h6" mb={2}>
            Você atingiu o limite de casos ativos do seu plano
          </Typography>
          Seu plano atual permite {limitQuotaCases} casos, e todos eles já foram utilizados. Para ativar esse você
          precisa de um plano com mais possições.
        </>
      }
      open={open}
      onClose={onClose}
      buttons={[
        {
          label: "Gerenciar plano",
          onClick: onOpenBilling,
        },
      ]}
      buttonsDirection="column"
      icon={<PaywallCard />}
    />
  );
};
