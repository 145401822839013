import React, { useMemo } from "react";

import { Cases } from "../Cases";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { NewCasesPage } from "../Cases/NewCasesPage";
import { useCredits } from "@/hooks/credits/useCredits";
import { PlanType } from "@/hooks/credits/types";
import { WhatsappAssistantLayout } from "../WhatsappAssistantLayout";

export const CasesLayout = () => {
  const { newCasesPageEnabled, legalProceedingsPageEnabled } = useFeatureFlags();
  const { data: credits } = useCredits();
  const hasCaseAccess = useMemo(
    () =>
      [PlanType.CUSTOM, PlanType.PROFESSIONAL_WITH_WPP, PlanType.PROFESSIONAL_CUSTOM].includes(
        credits?.companyPlan?.plan as PlanType
      ),
    [credits?.companyPlan?.plan]
  );

  if (newCasesPageEnabled && legalProceedingsPageEnabled) {
    return <NewCasesPage />;
  }

  if (!legalProceedingsPageEnabled && !hasCaseAccess) {
    return <WhatsappAssistantLayout />;
  }

  return <Cases />;
};
