import "firebase/compat/auth";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as logger from "../../../core/logger";

import { SignUpSource, SignupPayload, AcceptInvitationPayload } from "@/hooks/useApi/types";
import { useGtag } from "@/hooks/useGtag";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { SignUpContext } from "./context";
import { SignUpData, SignUpSteps } from "./types";
import { getGclid } from "@/utils/getGclid";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { getParam } from "@/utils/getParams";
import { usePlatform } from "@/hooks/usePlatform";
import { LexterCopilotAuthService } from "@/services/lexterCopilotAuth";

interface SignUpProviderProps {
  children: React.ReactNode;
}

export const SignUpProvider = ({ children }: SignUpProviderProps) => {
  const [searchParams] = useSearchParams();
  const invitationEmail = searchParams.get("email") || undefined;
  const invitationToken = searchParams.get("invitation_token");
  const redirectTo = searchParams.get("redirectTo");
  const { state } = useLocation();
  const steps = [SignUpSteps.BASIC_INFO, SignUpSteps.PASSWORD];
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const [signUpData, setSignUpData] = useState<SignUpData>(() => (invitationEmail ? { email: invitationEmail } : {}));
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { sendEvent } = useGtag();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const plataform = usePlatform();

  const nextStep = () => {
    setCurrentStep((prev) => {
      const nextStepIndex = steps.indexOf(prev) + 1;
      if (nextStepIndex === steps.length) return prev;
      return steps[nextStepIndex];
    });
  };

  const previousStep = () => {
    setCurrentStep((prev) => {
      const previousStepIndex = steps.indexOf(prev) - 1;
      if (previousStepIndex < 0) return prev;
      return steps[previousStepIndex];
    });
  };

  const changeStep = (step: number) => {
    setCurrentStep(steps[step]);
  };

  const setData = (data: SignUpData) => {
    setSignUpData((prev) => ({ ...prev, ...data }));
  };

  const submitData = async (data: SignUpData) => {
    const fullData = { ...signUpData, ...data };

    if (invitationToken && invitationEmail) {
      return acceptInvitation({
        ...fullData,
        recaptchaToken: await executeRecaptcha!(),
      } as SignupPayload);
    }

    setIsLoading(true);

    sendEvent("conversion", "AW-16462471594/lj4nCP6Ip5UZEKrD9ak9");

    const source = SignUpSource.COPILOT_WEB;

    const payload = {
      ...fullData,
      signUpUtm: getSignUpUtm(),
      referralCode: getParam("referralCode"),
      gclid: getGclid(),
      source,
      recaptchaToken: await executeRecaptcha!(),
      firstDevice: plataform,
    } as SignupPayload;

    try {
      logger.debug(`Submitting sign up to API with email: ${fullData.email}, url: ${window.location.href}`);
      await LexterCopilotAuthService.signup(payload);
      logger.debug("Successfully submitted sign up to API");
      return navigate(ROUTE_PATHS.ACTIVATE_ACCOUNT, {
        state: { ...state, email: payload.email, password: payload.password },
        replace: true,
      });
    } catch (e) {
      if (e instanceof Object && "message" in e) {
        logger.error(`Failed to submit sign up to API: ${e.message || "no message"}`);
      }
      return navigate(ROUTE_PATHS.SIGNUP_FAILURE);
    }
  };

  const acceptInvitation = async (data: SignUpData) => {
    setIsLoading(true);

    const payload = {
      name: data.name,
      password: data.password,
      phone: data.phone,
      invitationToken,
      recaptchaToken: await executeRecaptcha!(),
    } as AcceptInvitationPayload;

    try {
      logger.debug(
        `Submitting sign up with invitation to API with email: ${invitationEmail}, url: ${window.location.href}`
      );

      await LexterCopilotAuthService.acceptInvitation(payload);
      logger.debug("Successfully submitted sign up with invitation to API");

      return navigate(ROUTE_PATHS.LOGIN, {
        state: { fromInvitation: true },
      });
    } catch (e) {
      if (e instanceof Object && "message" in e) {
        logger.error(`Failed to submit sign up to API: ${e.message || "no message"}`);
      }
      return navigate(ROUTE_PATHS.SIGNUP_FAILURE);
    }
  };

  const getSignUpUtm = () => {
    const signUpUtm: Record<string, string> = {
      source: "word-addin",
      campaign: "organic",
    };

    const url = window.location.href;

    const documentUrl = new URL(url);
    const searchParams = documentUrl.searchParams;

    searchParams.forEach((value, key) => {
      if (key.startsWith("utm_") && key.length > 4) {
        const cleanedKey = key.substring(4);
        signUpUtm[cleanedKey] = value;
      }
    });

    return signUpUtm;
  };

  useEffect(() => {
    if (redirectTo) {
      navigate("", {
        replace: true,
        state: {
          ...state,
          redirectTo,
        },
      });
    }
  }, [state, navigate, redirectTo]);

  return (
    <SignUpContext.Provider
      value={{
        steps,
        currentStep,
        nextStep,
        previousStep,
        changeStep,
        data: signUpData,
        setData,
        submitData,
        isLoading,
        invitationEmail,
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
};
