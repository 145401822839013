import { buildSkillStep, createUploadFileField } from "@/core/skillForms/builder";
import { FileTypes } from "@/core/skills/types";

export const createInitialPetitionSkillStep3 = buildSkillStep({
  title: "Provas",
  description: "Anexar Provas",
  fields: [
    createUploadFileField({
      name: "evidence_documents",
      required: false,
      ui: {
        label: "Anexar provas",
        description:
          "Anexe os arquivos referentes às provas, que serão utilizados para aprimorar a argumentação do caso. \n**Recomendamos organizar cada prova em arquivos separados, em vez de consolidá-las em um único documento.**",
        enableMarkdownInDescription: true,
        options: {
          multiple: true,
          accept: [FileTypes.PNG, FileTypes.JPEG, FileTypes.TIFF, FileTypes.PDF],
        },
      },
    }),
  ],
  submitButtonText: "Criar Petição Inicial",
});
