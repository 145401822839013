import { AdminLexZapService } from "@/services/lexZap";
import { useQuery } from "@tanstack/react-query";

export const lexZapChannelsQueryKey = ["lexZapChannels"];

export const useLexZapChannels = () => {
  return useQuery({
    queryKey: lexZapChannelsQueryKey,
    queryFn: AdminLexZapService.getLexZapChannels,
  });
};
