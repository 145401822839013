import { SkillId } from "@/core/skills/types";
import { useQuery } from "@tanstack/react-query";
import { SkillForms } from "@/core/skillForms";

export const useSkillForm = ({ skillId }: { skillId?: SkillId }) => {
  return useQuery({
    queryKey: ["skillForm", skillId],
    queryFn: async () => {
      const form = await SkillForms.getSkillForm(skillId!);

      if (!form) {
        return null;
      }

      return form;
    },
    enabled: Boolean(skillId),
  });
};
