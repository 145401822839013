import { Box, SxProps } from "@mui/material";
import { Header } from "@/components/PageLayout/Header";
import React from "react";
import { HeaderVariant } from "./Header/constants";

interface PageLayoutProps {
  children?: React.ReactNode;
  contentSx?: SxProps;
  sx?: SxProps;
  headerProps?: {
    variant?: HeaderVariant;
    extraContent?: React.ReactNode;
  };
  contentProps?: {
    disablePadding?: boolean;
    fullWidth?: boolean;
  };
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
  /**
   * @default "primary"
   * @description
   * - primary: gray background, gray header
   * - secondary: white background, white header
   * - tertiary: gray background, white header
   */
  variant?: "primary" | "secondary" | "tertiary";
}
export const PageLayout = ({
  children,
  headerProps = {},
  contentSx,
  sx,
  contentProps = {},
  variant = "primary",
  onScroll,
}: PageLayoutProps) => {
  const { variant: headerVariant = HeaderVariant.GO_BACK, extraContent: headerExtraContent } = headerProps;
  const { disablePadding, fullWidth } = contentProps;

  let pageBackgroundColor = "common.dorian";
  switch (variant) {
    case "secondary":
      pageBackgroundColor = "common.white";
      break;
  }

  let headerBackgroundColor = "common.dorian";
  switch (variant) {
    case "secondary":
    case "tertiary":
      headerBackgroundColor = "common.white";
  }

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateRows: `min-content ${headerExtraContent ? "min-content" : ""} 1fr`,
        height: "100%",
        ...sx,
      }}
    >
      <Header variant={headerVariant} sx={{ backgroundColor: headerBackgroundColor }} />
      {headerExtraContent}

      <Box
        className="page-layout-container"
        sx={{
          height: "100%",
          width: "100%",
          backgroundColor: pageBackgroundColor,
          paddingY: disablePadding ? 0 : 6,
          paddingX: disablePadding ? 0 : 2,
          overflow: "auto",
        }}
        onScroll={onScroll}
      >
        <Box
          sx={
            fullWidth
              ? {
                  width: "100%",
                  ...contentSx,
                }
              : {
                  marginX: "auto",
                  width: "clamp(612px, 50%, 100%)",
                  maxWidth: "100%",
                  ...contentSx,
                }
          }
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
