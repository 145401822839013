import { ChatStage, TicketStatus } from "@/hooks/lexZap/types";

import { ChatStatus, LexZapChat } from "@/hooks/lexZap/types";
import { CaseStatusFilter, AnsweredFilter, ChatFilters } from "../../components/FilterModal/types";

export const applyChatFilters = ({
  chats,
  status,
  searchTerm,
  filters,
}: {
  chats: LexZapChat[];
  status: ChatStatus;
  searchTerm: string;
  filters: ChatFilters;
}) => {
  let filtered = chats.filter((item) => item.status === status);

  if (searchTerm) {
    filtered = filtered.filter(
      (item) =>
        item.applicantName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.endClientNumber.includes(searchTerm)
    );
  }

  if (!filters.ticketStatus.open.abandoned) {
    filtered = filtered.filter((item) => item.chatStage !== ChatStage.ABANDONED);
  }

  if (!filters.ticketStatus.open.AIService) {
    filtered = filtered.filter((item) => item.chatStage !== ChatStage.AI_SERVICE);
  }

  if (!filters.ticketStatus.open.redirectedHuman) {
    filtered = filtered.filter((item) => item.chatStage !== ChatStage.REDIRECTED);
  }

  if (!filters.ticketStatus.open.initialTriage) {
    filtered = filtered.filter((item) => item.chatStage !== ChatStage.INITIAL_TRIAGE);
  }

  if (!filters.ticketStatus.closed) {
    filtered = filtered.filter((item) => item.lastTicket?.status !== TicketStatus.CLOSED);
  }

  if (filters.answered === AnsweredFilter.ANSWERED) {
    filtered = filtered.filter((item) => !item.unansweredMessagesCount);
  }

  if (filters.answered === AnsweredFilter.UNANSWERED) {
    filtered = filtered.filter((item) => !!item.unansweredMessagesCount);
  }

  if (filters.caseStatus === CaseStatusFilter.ACTIVE) {
    filtered = filtered.filter((item) => item.cases?.some((chatCase) => chatCase.status === "ACTIVE"));
  }

  if (filters.caseStatus === CaseStatusFilter.INACTIVE) {
    filtered = filtered.filter((item) => item.lastCase?.status === "INACTIVE");
  }

  if (filters.responsibles.length) {
    const responsibleIds = filters.responsibles.map((item) => item.userId);
    filtered = filtered.filter(
      (item) =>
        item.lastTicket?.status === TicketStatus.ACTIVE &&
        item.lastTicket?.responsibleUserId &&
        responsibleIds.includes(item.lastTicket.responsibleUserId)
    );
  }

  if (filters.areas.length) {
    const activityAreas = filters.areas.map((item) => item.value);
    filtered = filtered.filter(
      (item) =>
        item.lastTicket?.status === TicketStatus.ACTIVE &&
        item.lastTicket?.activityAreas?.some((area) => activityAreas.includes(area))
    );
  }

  return filtered;
};
