import { CheckCircleOutline, ErrorOutline } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React, { ReactNode } from "react";
import toast, { ToastPosition } from "react-hot-toast";

export class Toast {
  static error(msg: string, duration?: number, position?: ToastPosition) {
    toast.error(msg, { duration, position });
  }

  static success(msg: string, duration?: number, position?: ToastPosition) {
    toast.success(msg, { duration, position });
  }

  static warn(msg: string, duration?: number) {
    toast(msg, { duration });
  }
}

export class WebToast {
  static error(msg: string, duration?: number) {
    toast.error(msg, { duration, position: "bottom-left" });
  }

  static success(msg: string, duration?: number) {
    toast.success(msg, { duration, position: "bottom-left" });
  }

  static warn(msg: string, duration?: number) {
    toast(msg, { duration, position: "bottom-left" });
  }
}

export class WebToastColoredWithTitle {
  static error(title: string, msg: string, duration?: number) {
    toast.error(
      <Box sx={{ display: "flex", gap: 1 }}>
        <ErrorOutline sx={{ color: "error.main", fontSize: 22 }} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              color: "error.dark",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: "error.dark",
            }}
          >
            {msg}
          </Typography>
        </Box>
      </Box>,
      { duration, position: "bottom-left", icon: null }
    );
  }

  static success(
    title: string,
    options?: { msg?: string; duration?: number; position?: ToastPosition; actions?: ReactNode }
  ) {
    toast.success(
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <CheckCircleOutline sx={{ color: "success.main", fontSize: 22 }} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "bold",
              color: "success.dark",
            }}
          >
            {title}
          </Typography>
          {options?.msg && (
            <Typography
              sx={{
                fontSize: "14px",
                color: "success.dark",
              }}
            >
              {options?.msg}
            </Typography>
          )}
        </Box>
        {options?.actions}
      </Box>,
      {
        duration: options?.duration,
        position: options?.position ?? "bottom-left",
        icon: null,
        style: { backgroundColor: "#EDF7ED" },
      }
    );
  }
}
