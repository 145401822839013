import axios from "axios";
import { MessageStatus, ThreadMessage, ThreadMessageErrorType } from "@/contexts/WebSocketContext";
import { DateTime } from "luxon";
import { v4 as uuidV4 } from "uuid";
import {
  ActionId,
  ChatFile,
  CortexSuccessResponse,
  CreateExtrajudicialNoticeAction,
  CreateInterviewScriptAction,
  CreatePieceAction,
  ErrorMessage,
  FlowMessage,
  FlowMessageAction,
  InMessageFile,
  Message,
  MessageDTO,
} from "../types";
import { logger } from "@/core/logger";
import { dateTimeFromString } from "@/utils/dates/dateTimeFromString";

export async function uploadFileToS3(
  url: string,
  file: File | Blob,
  onUploadProgress?: (progress: number) => void
): Promise<void> {
  try {
    await axios.put(url, file, {
      headers: { "Content-Type": file.type },
      transformRequest: [
        (data, headers) => {
          if (headers) {
            delete headers.Authorization;
          }
          return data;
        },
      ],
      onUploadProgress: (p) => {
        if (onUploadProgress) {
          onUploadProgress((p.loaded / file.size) * 100);
        }
      },
    });
  } catch (e) {
    logger.error("Erro no upload do arquivo", e);
    return Promise.reject("Erro no upload do arquivo");
  }
}

export const threadMessagesToChatMessages = (threadMessages: ThreadMessage[]): Message[] => {
  const dateTimeFromStr = (str: string) => {
    return dateTimeFromString(str);
  };

  const handleThreadMessageError = (threadMessage: ThreadMessage) => {
    switch (threadMessage.errorType) {
      case ThreadMessageErrorType.INSUFFICIENT_CREDITS: {
        return createErrorMessage({
          text: "Créditos insuficientes",
          date: dateTimeFromStr(threadMessage.createdAt),
        });
      }
      default: {
        return createErrorMessage({
          text: "Ops! Houve um erro ao analisar os dados enviados.",
          date: dateTimeFromStr(threadMessage.createdAt),
        });
      }
    }
  };

  return threadMessages.reduce((chatMessages, threadMessage) => {
    const isUserMessage = threadMessage.author !== "Lexter.ai";

    chatMessages.push({
      id: threadMessage.id,
      type: "FLOW",
      direction: isUserMessage ? "SENT" : "RECEIVED",
      author: isUserMessage ? "Current User" : "Lexter.ai",
      date: dateTimeFromStr(threadMessage.createdAt),
      status: "READ",
      text: threadMessage.text,
      context: threadMessage.context ? threadMessage.context : undefined,
      evaluation: threadMessage.evaluation,
      files: threadMessage.files.map((file) => ({
        type: "UPLOADED",
        id: file.id,
        cortexId: file.cortexId,
        url: file.url,
        name: file.name,
      })),
      actions: [threadMessage.payload].filter((action) => action),
      actionId: threadMessage.actionId,
    } as FlowMessage);

    switch (threadMessage.status) {
      case MessageStatus.CANCELED: {
        chatMessages.push({
          id: uuidV4(),
          type: "TEXT",
          direction: "RECEIVED",
          author: "Lexter.ai",
          date: dateTimeFromStr(threadMessage.canceledAt!),
          status: "READ",
          text: "Operação cancelada com sucesso.",
        });
        break;
      }
      case MessageStatus.ERROR: {
        handleThreadMessageError(threadMessage);
        break;
      }
    }

    return chatMessages;
  }, [] as Message[]);
};

export function createErrorMessage(
  { text, date }: { text: string; date?: DateTime },
  options?: { retry?: () => void; cancel?: () => void }
): ErrorMessage {
  return {
    id: uuidV4(),
    type: "ERROR",
    direction: "RECEIVED",
    author: "Lexter.ai",
    date: date || DateTime.now(),
    status: "READ",
    text,
    actions: [],
    retry: options?.retry,
    cancel: options?.cancel,
  };
}

export function createEvidenceAndRequiredDocumentMessage({
  cortexResponse,
  showSuggestedSkillsMessage,
}: {
  cortexResponse: CortexSuccessResponse;
  showSuggestedSkillsMessage: boolean;
}): Message {
  const { payload } = cortexResponse.replyTo;
  const { case_breakdown, reference_piece } = payload;

  const payloadFiles: InMessageFile[] = [];
  if (case_breakdown?.source === "FILE" && case_breakdown.file) {
    payloadFiles.push(case_breakdown.file as InMessageFile);
  }
  if (reference_piece?.source === "FILE" && reference_piece.file) {
    payloadFiles.push(reference_piece.file as InMessageFile);
  }

  const messageText = showSuggestedSkillsMessage ? "Gerar sugestão de provas e documentos para protocolo" : "Sim";

  return {
    id: uuidV4(),
    type: "FLOW",
    direction: "SENT",
    author: "Current User",
    date: DateTime.now(),
    status: "READ",
    text: messageText,
    actions: [
      {
        ...payload,
        id: ActionId.CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT,
        text: undefined,
      },
    ],
    files: payloadFiles,
    hideFiles: true,
  };
}

export function messageToDTO(message: Message): MessageDTO {
  const files: ChatFile[] = [];
  if (message.type === "FILE" || message.type === "FLOW") {
    message.files.forEach((file) => {
      if (file.type === "UPLOADED") {
        files.push(file);
      }
    });
  }

  let actions: FlowMessageAction[] | undefined = undefined;
  if (message.type === "FLOW") {
    actions = message.actions.map((action): FlowMessageAction => {
      if (action.id === ActionId.CREATE_ONE_PIECE) {
        return {
          ...action,
          case_breakdown: action.case_breakdown
            ? action.case_breakdown.file
              ? {
                  source: action.case_breakdown.source,
                  file: message.files.find((file) => file.id === action.case_breakdown?.file?.id),
                }
              : action.case_breakdown
            : undefined,
          reference_piece: action.reference_piece
            ? action.reference_piece.file
              ? {
                  source: action.reference_piece.source,
                  file: message.files.find((file) => file.id === action.reference_piece?.file?.id),
                }
              : action.reference_piece
            : undefined,
          evidence_documents: action.evidence_documents
            ? action.evidence_documents.map((evidence_document) => ({
                source: evidence_document.source,
                file: message.files.find((file) => file.id === evidence_document.file?.id),
              }))
            : undefined,
        } as CreatePieceAction;
      }

      if (action.id === ActionId.CREATE_INTERVIEW_SCRIPT_ACTION) {
        return {
          ...action,
          case_breakdown: {
            ...action.case_breakdown,
            file: message.files.find((file) => file.id === action.case_breakdown.file?.id),
          },
        } as CreateInterviewScriptAction;
      }

      if (action.id === ActionId.CREATE_NOTICE_ACTION) {
        return {
          ...action,
          case_breakdown: action.case_breakdown,
          reference_piece: action.reference_piece
            ? action.reference_piece.file
              ? {
                  source: action.reference_piece.source,
                  file: message.files.find((file) => file.id === action.reference_piece?.file?.id),
                }
              : action.reference_piece
            : undefined,
        } as CreateExtrajudicialNoticeAction;
      }

      return action;
    });
  }

  return {
    id: message.id,
    author: message.author,
    date: message.date.toISO() || "",
    text: message.text,
    context: message.context,
    files,
    actions,
  };
}

export function removeUrlSignature(url: string) {
  const [urlWithoutSignature] = url.split("?");
  return urlWithoutSignature;
}

export function getDeviceMetadata(): string {
  return navigator.userAgent;
}
