import { Typography, Box, Tabs, Tab } from "@mui/material";
import React, { useState } from "react";
import { ArrowForwardIos } from "@mui/icons-material";
import { LegalProceedingType } from "@/services/legalProceeding/types";
import { useLegalProceedingHistory } from "@/hooks/legalProceedings/useLegalProceedingHistory";
import { DataGrid } from "@mui/x-data-grid";
import { FullScreenModal } from "@/components/FullScreenModal";
import { formatLegalProceedingCnj } from "@/utils/formatLegalProceedingCnj";
import { LexterDate } from "@/core/LexterDate";

type LegalProceedingsAttachedModalProps = {
  open: boolean;
  onClose: () => void;
  legalProceedings: LegalProceedingType[];
  selectedLegalProceeding: LegalProceedingType | null;
};

export const LegalProceedingsAttachedModal = ({
  open,
  onClose,
  legalProceedings,
  selectedLegalProceeding,
}: LegalProceedingsAttachedModalProps) => {
  const getLegalProceedingValue = (legalProceeding: LegalProceedingType) => {
    return `${legalProceeding.cnj}-${legalProceeding.instance}`;
  };

  const [legalProceedingSelected, setLegalProceedingSelected] = React.useState<string | false>(
    selectedLegalProceeding ? getLegalProceedingValue(selectedLegalProceeding) : false
  );

  React.useEffect(() => {
    if (selectedLegalProceeding) {
      setLegalProceedingSelected(getLegalProceedingValue(selectedLegalProceeding));
    }
  }, [selectedLegalProceeding]);

  const handleChange = (_: React.SyntheticEvent, newValue: string) => {
    setLegalProceedingSelected(newValue);
  };

  return (
    <FullScreenModal
      open={open}
      onClose={onClose}
      title="Atividades do processo"
      sx={{ p: 0, maxHeight: "100vh", overflow: "hidden" }}
    >
      <Box sx={{ display: "grid", gridTemplateColumns: "1.5fr 3fr", height: "100%" }}>
        <Box sx={{ height: "100%", borderRight: "1px solid", borderColor: "divider", overflow: "auto" }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            scrollButtons="auto"
            value={legalProceedingSelected}
            onChange={handleChange}
            sx={{
              minWidth: "200px",
              width: "100%",
            }}
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
          >
            {legalProceedings.map((legalProceeding) => (
              <Tab
                key={getLegalProceedingValue(legalProceeding)}
                label={<LegalProceedingTabLabel legalProceeding={legalProceeding} />}
                sx={{
                  py: 2,
                  alignItems: "flex-start",
                  maxWidth: "100%",
                  backgroundColor:
                    legalProceedingSelected === getLegalProceedingValue(legalProceeding) ? "grey.50" : "transparent",
                  color: "text.primary",
                  "&.Mui-selected": {
                    color: "inherit",
                  },
                }}
                value={getLegalProceedingValue(legalProceeding)}
              />
            ))}
          </Tabs>
        </Box>
        <Box sx={{ width: "100%" }}>
          {legalProceedings.map((legalProceeding) => (
            <LegalProceedingsAttachedMovementList
              key={getLegalProceedingValue(legalProceeding)}
              legalProceeding={legalProceeding}
              hidden={legalProceedingSelected !== getLegalProceedingValue(legalProceeding)}
            />
          ))}
        </Box>
      </Box>
    </FullScreenModal>
  );
};

const LegalProceedingsAttachedMovementList = ({
  legalProceeding,
  hidden,
}: {
  legalProceeding: LegalProceedingType;
  hidden: boolean;
}) => {
  const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 12 });

  React.useEffect(() => {
    setPaginationModel({ page: 0, pageSize: 12 });
  }, [legalProceeding.cnj, legalProceeding.instance]);

  const {
    data: legalProceedingHistory,
    isLoading: isLoadingLegalProceedingHistory,
    isError: isErrorLegalProceedingHistory,
  } = useLegalProceedingHistory({
    cnj: legalProceeding.cnj,
    instance: legalProceeding.instance,
  });

  if (!legalProceeding || hidden) return null;
  if (isErrorLegalProceedingHistory)
    return (
      <Typography variant="h6" color="text.secondary" sx={{ textAlign: "center", p: 2 }}>
        Erro ao carregar histórico do processo
      </Typography>
    );

  return (
    <Box
      sx={{
        height: isLoadingLegalProceedingHistory ? "615px" : "90vh",
      }}
    >
      <DataGrid
        rows={legalProceedingHistory ?? []}
        loading={isLoadingLegalProceedingHistory}
        getRowHeight={() => "auto"}
        paginationModel={paginationModel}
        onPaginationModelChange={(paginationModel) => {
          setPaginationModel(paginationModel);
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: "date", sort: "desc" }],
          },
        }}
        columns={[
          {
            field: "date",
            headerName: "Movimentado em",
            minWidth: 200,
            valueGetter: (value) => {
              const date = LexterDate.fromLegalProceedingDateString(value);
              if (date.isSuccess) return date.getValue().toTimestamp();
              return null;
            },
            renderCell: (params) => {
              if (params.value) {
                const date = LexterDate.fromTimestamp(params.value);
                if (date.isSuccess) return <span>{date.getValue().toDisplayString()}</span>;
              }
              return null;
            },
            resizable: false,
          },
          {
            field: "content",
            headerName: "Descrição",
            minWidth: 300,
            resizable: false,
            flex: 1,
            renderCell: (params) => (
              <Typography
                component="span"
                sx={{
                  textWrap: "wrap",
                  fontSize: 14,
                  fontWeight: 400,
                  color: "text.secondary",
                }}
                variant="body"
              >
                {params.value}
              </Typography>
            ),
          },
        ]}
        sx={{
          overflow: "hidden",
          "& .MuiDataGrid-cell": {
            backgroundColor: "#FCFBFB",
            pl: 2,
            fontSize: 14,
            fontWeight: 400,
            color: "text.secondary",
            py: 1,
            overflow: "hidden",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            pl: 1,
            fontSize: 14,
            fontWeight: 500,
          },
        }}
        slotProps={{
          loadingOverlay: {
            variant: "skeleton",
            noRowsVariant: "skeleton",
          },
        }}
        slots={{
          noRowsOverlay: () => (
            <Typography variant="h6" color="text.secondary" sx={{ textAlign: "center", p: 2, mt: 4 }}>
              Nenhuma movimentação encontrada
            </Typography>
          ),
        }}
        disableRowSelectionOnClick
      />
    </Box>
  );
};

const LegalProceedingTabLabel = ({ legalProceeding }: { legalProceeding: LegalProceedingType }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        alignItems: "center",
        textTransform: "none",
        pr: 2,
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          alignItems: "start",
          flex: 1,
        }}
      >
        <Typography variant="body2">
          {legalProceeding.tribunalAcronym} -{" "}
          <Typography variant="body2" component="span" color="text.secondary">
            {formatLegalProceedingCnj(legalProceeding.cnj)} - {legalProceeding.instance}ª Instância
          </Typography>
        </Typography>
        <Typography variant="body1" fontWeight={700} textAlign="left">
          {legalProceeding.name}
        </Typography>
      </Box>
      <ArrowForwardIos sx={{ fontSize: 12, color: "text.secondary" }} />
    </Box>
  );
};
