import { SkillForm } from "@/core/skillForms/types";
import { createDefenseSkillStep1 } from "../step1";
import { createDefenseSkillStep2 } from "../step2";
import { createDefenseSkillStep3 } from "./step3";
import { createDefenseSkillStep4 } from "./step4";

export const createDefenseSkillFormV2: SkillForm = {
  skillId: "create_contestation_v2",
  title: "Contestação",
  steps: [createDefenseSkillStep1, createDefenseSkillStep2, createDefenseSkillStep3, createDefenseSkillStep4],
};
