import { PageLayout } from "@/components/PageLayout";

import { Chat, DeleteForever, Edit, Business, VpnKey, Add, Refresh } from "@mui/icons-material";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import React, { useMemo, useState } from "react";

import { WebToast } from "@//components/core/Toast";
import { useDeleteLexZapChannel } from "@/hooks/lexZap/admin/useDeleteLexZapChannel";
import { useLexZapChannels } from "@/hooks/lexZap/admin/useLexZapChannels";
import { LexZapCompany } from "@/hooks/lexZap/types";
import { useAlertModal } from "@/hooks/useAlertModal";
import { Box, Button, Tooltip, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { EditCompanyModal } from "./components/EditCompanyModal";
import { useImpersonation } from "@/hooks/useImpersonation";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { UpdateChannelCompanyModal } from "./components/UpdateChannelCompanyModal";
import { UpdateChannelApiKeyModal } from "./components/UpdateChannelApiKeyModal";
import { LexZapChannel } from "@/services/lexZap/types";
import { getChannelStatus } from "./utils/getChannelStatus";
import { getTemplateStatus } from "./utils/getTemplateStatus";
import { CreateChannelModal } from "./components/CreateChannelModal";

export const ManageWhatsappUsers = () => {
  const { data: channelsData, isLoading, refetch, isFetching } = useLexZapChannels();
  const channels = useMemo(() => {
    return channelsData?.sort((a, b) => b.createdAt - a.createdAt);
  }, [channelsData]);

  const [companyToEdit, setCompanyToEdit] = useState<LexZapCompany>();
  const [channelToEditCompany, setChannelToEditCompany] = useState<LexZapChannel>();
  const [channelToEditApiKey, setChannelToEditApiKey] = useState<LexZapChannel>();
  const [openCreateChannelModal, setOpenCreateChannelModal] = useState(false);

  const { openAlertModal } = useAlertModal();
  const { mutateAsync: deleteChannel } = useDeleteLexZapChannel();
  const { setImpersonatedUser } = useImpersonation();
  const navigate = useNavigate();

  const handleDeleteChannel = (channelId: string) => {
    openAlertModal({
      title: "Tem certeza que deseja deletar esta company?",
      description: "Ao deletar esta company, todas as conversas e mensagens associadas a ela serão perdidas.",
      primaryButton: {
        label: "Continuar",
        onClick: async () => {
          try {
            await deleteChannel({ channelId });
            WebToast.success("Company deletada com sucesso");
          } catch {
            WebToast.error("Erro ao deletar company");
          }
        },
      },
      secondaryButton: {
        label: "Cancelar",
        onClick: () => {},
      },
    });
  };

  const handleAccessCompany = (company: LexZapCompany) => {
    setImpersonatedUser({
      companyId: company.companyId,
      companyName: company.companyName,
      whatsappNumber: company.phoneNumber,
      companyEmail: company.email,
    });
    navigate(ROUTE_PATHS.WHATSAPP_ASSISTANT_CHATS);
  };

  return (
    <PageLayout
      contentProps={{
        disablePadding: true,
        fullWidth: true,
      }}
      contentSx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 4,
          gap: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="pageTitle">Usuários do Assistente WhatsApp</Typography>

          <Box>
            <Button
              variant="text"
              startIcon={<Refresh />}
              disabled={isFetching}
              onClick={() => refetch()}
              sx={{ marginRight: 2 }}
            >
              Atualizar
            </Button>
            <Button variant="contained" startIcon={<Add />} onClick={() => setOpenCreateChannelModal(true)}>
              Adicionar Canal
            </Button>
          </Box>
        </Box>

        <DataGrid
          rows={channels || []}
          getRowId={(row) => row.id}
          loading={isLoading}
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          columns={[
            {
              field: "companyId",
              headerName: "Company Id",
              flex: 1,
              renderCell: ({ row: channel }) => channel.company?.companyId,
            },
            {
              field: "companyName",
              headerName: "Nome da empresa",
              flex: 1,
              renderCell: ({ row: channel }) => channel.company?.companyName,
            },
            {
              field: "assistantName",
              headerName: "Nome do assistente",
              flex: 1,
              renderCell: ({ row: channel }) => channel.company?.assistantName,
            },
            {
              field: "email360",
              headerName: "Email 360",
              flex: 1,
              renderCell: ({ row: channel }) => channel.client.contact_info.email,
            },
            {
              field: "emailLexter",
              headerName: "Email Lexter",
              flex: 1,
              renderCell: ({ row: channel }) => channel.company?.email,
            },
            {
              field: "phoneNumber",
              headerName: "Número do whatsapp",
              flex: 1,
              renderCell: ({ row: channel }) => channel.setup_info.phone_number,
            },
            {
              field: "createdAt",
              headerName: "Data de criação",
              flex: 1,
              renderCell: ({ row: channel }) => DateTime.fromMillis(channel.createdAt).toFormat("dd/MM/yyyy HH:mm"),
            },
            {
              field: "status",
              headerName: "Status do Canal",
              flex: 1,
              renderCell: ({ row: channel }) => {
                const { color, details } = getChannelStatus(channel);
                return (
                  <Tooltip placement="top" title={details}>
                    <Box
                      sx={{
                        display: "inline-flex",
                        gap: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: 16,
                          height: 16,
                          borderRadius: "50%",
                          backgroundColor: color,
                          display: "inline-block",
                        }}
                        title={details}
                      />
                      {!!channel.waba_account.status && (
                        <Typography variant="body2" sx={{ color }}>
                          {channel.waba_account.status}{" "}
                        </Typography>
                      )}
                    </Box>
                  </Tooltip>
                );
              },
              valueGetter: (_value, channel) => {
                return getChannelStatus(channel).color;
              },
            },
            {
              field: "templateStatus",
              headerName: "Status dos Templates",
              flex: 1,
              renderCell: ({ row: channel }) => {
                const { color, details } = getTemplateStatus(channel);
                return (
                  <Tooltip placement="top" title={details}>
                    <Box
                      sx={{
                        width: 16,
                        height: 16,
                        borderRadius: "50%",
                        backgroundColor: color,
                        display: "inline-block",
                      }}
                      title={details}
                    />
                  </Tooltip>
                );
              },
              valueGetter: (_value, channel) => {
                return getTemplateStatus(channel).color;
              },
            },
            {
              field: "actions",
              type: "actions",
              headerName: "Ações",
              flex: 1,
              getActions: ({ row: channel }) => [
                <GridActionsCellItem
                  key={`${channel.id}-edit`}
                  icon={<Edit />}
                  label="Customizar Assistente"
                  onClick={() => channel.company && setCompanyToEdit(channel.company)}
                  showInMenu
                  disabled={!channel.company}
                />,
                <GridActionsCellItem
                  key={`${channel.id}-update-company`}
                  icon={<Business />}
                  label={channel.company ? "Vincular a outra company" : "Vincular company"}
                  onClick={() => setChannelToEditCompany(channel)}
                  showInMenu
                  hidden={!!channel.company}
                />,
                <GridActionsCellItem
                  key={`${channel.id}-update-api-key`}
                  icon={<VpnKey />}
                  label="Alterar API Key"
                  onClick={() => setChannelToEditApiKey(channel)}
                  showInMenu
                />,
                <GridActionsCellItem
                  key={`${channel.id}-access-chat`}
                  icon={<Chat />}
                  label="Acessar conversas"
                  onClick={() => channel.company && handleAccessCompany(channel.company)}
                  showInMenu
                  disabled={!channel.company}
                />,
                <GridActionsCellItem
                  key={`${channel.id}-delete`}
                  icon={<DeleteForever />}
                  label="Deletar"
                  onClick={() => handleDeleteChannel(channel.id)}
                  showInMenu
                />,
              ],
            },
          ]}
          rowSelection={false}
        />

        <EditCompanyModal
          isOpen={!!companyToEdit}
          onClose={() => setCompanyToEdit(undefined)}
          company={companyToEdit}
        />
        <UpdateChannelCompanyModal
          isOpen={!!channelToEditCompany}
          onClose={() => setChannelToEditCompany(undefined)}
          channel={channelToEditCompany}
        />
        <UpdateChannelApiKeyModal
          isOpen={!!channelToEditApiKey}
          onClose={() => setChannelToEditApiKey(undefined)}
          channel={channelToEditApiKey}
        />
        <CreateChannelModal isOpen={openCreateChannelModal} onClose={() => setOpenCreateChannelModal(false)} />
      </Box>
    </PageLayout>
  );
};
