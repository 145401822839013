import React from "react";

import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { NewPlansPage } from "./NewPlansPage";
import { PlansPageWithUnlimited } from "./PlansPageWithUnlimited";

export const Plans = () => {
  const { unlimitedPlanEnabled } = useFeatureFlags();

  if (unlimitedPlanEnabled) return <PlansPageWithUnlimited />;
  return <NewPlansPage />;
};
