import { useQuery, useQueryClient } from "@tanstack/react-query";
import { LexZapChat } from "../types";
import { useLexZapChats } from "../useLexZapChats";
import { useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const ACTIVE_CHAT_KEY = ["activeLexZapChat"];

interface UseLexZapActiveChatProps {
  disabled?: boolean;
}

export const useLexZapActiveChat = (props?: UseLexZapActiveChatProps) => {
  const queryClient = useQueryClient();
  const { data: chats } = useLexZapChats({ disabled: props?.disabled });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const setActiveChat = useCallback(
    (chat: LexZapChat) => {
      queryClient.setQueryData<LexZapChat>(ACTIVE_CHAT_KEY, chat);

      searchParams.set("phoneNumber", chat.endClientNumber);
      navigate(`?${searchParams.toString()}`, { replace: true });
    },
    [queryClient, navigate, searchParams]
  );

  const { data: activeChatData } = useQuery({
    queryKey: ACTIVE_CHAT_KEY,
    queryFn: () => queryClient.getQueryData<LexZapChat>(ACTIVE_CHAT_KEY) || null,
    enabled: !props?.disabled,
  });

  const activeChat = useMemo((): LexZapChat | undefined => {
    if (!activeChatData) return undefined;

    const existentChat = chats?.find((chat) => chat.endClientNumber === activeChatData.endClientNumber);

    return {
      ...activeChatData,
      ...existentChat,
    };
  }, [activeChatData, chats]);

  return {
    activeChat,
    setActiveChat,
  };
};
