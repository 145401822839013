import React from "react";
import { Box, Button } from "@mui/material";

export const ModalFooter = ({
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
  saveButtonDisabled,
}: {
  confirmLabel: string;
  cancelLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
  saveButtonDisabled?: boolean;
}) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "auto auto",
        gap: "8px",
        margin: "0 auto",
      }}
    >
      <Button onClick={onCancel}>{cancelLabel}</Button>
      <Button variant="contained" disabled={saveButtonDisabled} onClick={onConfirm}>
        {confirmLabel}
      </Button>
    </Box>
  );
};
