import React, { ReactNode } from "react";
import { Box, ButtonBase, Typography, styled } from "@mui/material";

interface ChipProps {
  title: string;
  children: ReactNode;
  onClick?: () => void;
  fullWidth?: boolean;
  color?: string;
}

export const Chip = ({ title, children, onClick, fullWidth, color }: ChipProps) => {
  let Container: typeof ChipContainer | typeof ChipButtonBaseContainer = ChipContainer;

  if (onClick) {
    Container = ChipButtonBaseContainer;
  }

  return (
    <Container onClick={onClick} fullWidth={fullWidth}>
      <ChipTitle
        variant="subtitle2"
        sx={{
          color: color,
        }}
      >
        {title}
      </ChipTitle>
      {children}
    </Container>
  );
};

const ChipContainer = styled(Box)<{ fullWidth?: boolean }>(({ theme, fullWidth }) => ({
  backgroundColor: theme.palette.common.dorian,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: theme.spacing(4),
  padding: theme.spacing(2),
  minWidth: "250px",
  flex: fullWidth ? 1 : "none",
  borderRadius: theme.shape.borderRadius,
}));

const ChipButtonBaseContainer = styled(ButtonBase)<{ fullWidth?: boolean }>(({ theme, fullWidth }) => ({
  backgroundColor: theme.palette.common.dorian,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  gap: theme.spacing(4),
  padding: theme.spacing(2),
  minWidth: "250px",
  flex: fullWidth ? 1 : "none",
  borderRadius: theme.shape.borderRadius,
  transition: "background-color 0.3s ease",
  "&:hover": {
    backgroundColor: theme.palette.grey[200],
  },
}));

const ChipTitle = styled(Typography)({
  fontWeight: 700,
});
