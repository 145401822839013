import { Box, Divider, Skeleton, Typography } from "@mui/material";
import React, { useMemo, useRef } from "react";

import { ChatMessages } from "./components/ChatMessages";
import { useLexZapMessages } from "@/hooks/lexZap/useLexZapMessages";
import { Case } from "@/hooks/lexZap/types";
import { Applicant } from "@/services/applicant/types";
import { TimeSince } from "./components/TimeSince";
import { Card } from "../Card";
import { Button } from "@/components/Button";
import { Add, ArrowForward } from "@mui/icons-material";
import { CaseChatInput } from "./components/CaseChatInput";
import { useCredits } from "@/hooks/credits/useCredits";
import { PlanType } from "@/hooks/credits/types";
import { useLexZapCompany } from "@/hooks/lexZap/useLexZapCompany";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import LiaCircleSVG from "@/assets/svgs/Liacircle.svg?react";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";

type CaseDetailsChatProps = {
  archived: boolean;
  caseData?: Case;
  applicant?: Applicant;
  loadingCase: boolean;
  handleEditApplicantModal: () => void;
};

export const CaseDetailsChat = ({
  archived,
  caseData,
  applicant,
  loadingCase,
  handleEditApplicantModal,
}: CaseDetailsChatProps) => {
  const { data: messages, isLoading: isLoadingMessages } = useLexZapMessages({
    applicantPhoneNumber: applicant?.phoneNumber || caseData?.applicantPhoneNumber,
    disabled: !applicant?.phoneNumber && !caseData?.applicantPhoneNumber,
  });
  const { data: connectionData, isLoading: isLoadingConnectionData } = useLexZapCompany();
  const { data: credits, isLoading: isLoadingCredits } = useCredits();
  const { legalProceedingsPageEnabled } = useFeatureFlags();

  const navigate = useNavigate();

  const lastMessage = useMemo(() => {
    if (!messages || !messages.length) return null;

    return messages[messages.length - 1];
  }, [messages]);

  const isLoadingChat = loadingCase || isLoadingMessages;
  const isLoading = isLoadingConnectionData || isLoadingCredits;
  const hasWhatsapp = connectionData?.phoneNumber;
  const hasWhatsappPlan = useMemo(
    () =>
      [PlanType.PERFORMANCE, PlanType.PROFESSIONAL_WITH_WPP, PlanType.CUSTOM, PlanType.PROFESSIONAL_CUSTOM].includes(
        credits?.companyPlan?.plan as PlanType
      ),
    [credits]
  );

  const messagesContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = React.useCallback(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messagesContainerRef]);

  return (
    <Card
      sx={{
        padding: 0,
        gap: 0,
      }}
      loadingSize={isLoading ? "600px" : ""}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
        }}
      >
        <Box>
          <Typography variant="subtitle1" fontWeight={700}>
            Canal de comunicação
          </Typography>
          {(applicant?.phoneNumber || caseData?.applicantPhoneNumber) && hasWhatsapp && (
            <>
              {lastMessage ? (
                <Typography variant="body2">
                  Última mensagem há <TimeSince timestamp={lastMessage?.timestamp} />
                </Typography>
              ) : (
                <Skeleton variant="text" width={200} />
              )}
            </>
          )}
        </Box>
        <Divider />
      </Box>

      {(() => {
        if ((!hasWhatsappPlan || !hasWhatsapp) && legalProceedingsPageEnabled) {
          return (
            <ChatCard>
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>
                <LiaCircleSVG />
                <Typography variant="subtitle1" fontWeight={700}>
                  Conheça a Lia
                </Typography>
                <Typography variant="body1" maxWidth={266} textAlign="center">
                  Automatize o atendimento e a comunicação com seus clientes com a nossa Especialista em Relacionamento
                </Typography>
              </Box>
              <Button
                endIcon={ArrowForward}
                variant="outlined"
                color="primary"
                onClick={() => navigate(ROUTE_PATHS.WHATSAPP_ASSISTANT)}
              >
                Conhecer a Lia
              </Button>
            </ChatCard>
          );
        }

        if (!hasWhatsappPlan || !hasWhatsapp) {
          return (
            <ChatCard>
              {" "}
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 3 }}>
                <Typography variant="subtitle1" fontWeight={700}>
                  Contrate o plano profissional
                </Typography>
                <Typography variant="body1" maxWidth={400} textAlign="center">
                  Aumente a eficiência e a satisfação dos seus clientes com o atendimento via WhatsApp
                </Typography>
                <Button
                  endIcon={ArrowForward}
                  variant="outlined"
                  color="primary"
                  onClick={() => navigate(ROUTE_PATHS.WHATSAPP_ASSISTANT)}
                >
                  Assinar agora
                </Button>
              </Box>
            </ChatCard>
          );
        }

        if (applicant?.phoneNumber || caseData?.applicantPhoneNumber) {
          return (
            <Box
              sx={{
                height: "450px",
                borderBottom: "1px solid",
                borderTop: "1px solid",
                borderColor: "divider",
                bgcolor: "grey.50",
              }}
            >
              <ChatMessages
                messages={messages}
                isLoading={isLoadingChat}
                messagesContainerRef={messagesContainerRef}
                scrollToBottom={scrollToBottom}
              />
            </Box>
          );
        }

        return (
          <ChatCard>
            <Typography variant="subtitle1" fontWeight={700}>
              Adicione o número de contato do cliente
            </Typography>
            <Button variant="outlined" color="primary" startIcon={Add} onClick={handleEditApplicantModal}>
              Adicionar contato
            </Button>
          </ChatCard>
        );
      })()}

      <Box
        sx={{
          padding: 3,
        }}
      >
        <CaseChatInput caseData={caseData} applicant={applicant} disabled={archived} />
      </Box>
    </Card>
  );
};

const ChatCard = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        bgcolor: "grey.50",
        gap: 2,
        p: 3,
        borderTop: "1px solid",
        borderBottom: "1px solid",
        borderColor: "divider",
        height: "450px",
      }}
    >
      {children}
    </Box>
  );
};
