import { SkillForm } from "@/core/skillForms/types";

import { createOtherTypeOfPieceSkillStep1 } from "../step1";
import { createOtherTypeOfPieceSkillStep2Response } from "../step2-conditional-response";
import { createOtherTypeOfPieceSkillStep2Appeal } from "../step2-conditional-appeal";
import { createOtherTypeOfPieceSkillStep3Appeal } from "../step3-conditional-appeal";
import { createOtherTypeOfPieceSkillStep2Initial } from "./step2-conditional-initial";
import { createOtherTypeOfPieceSkillStep3Response } from "./step3-conditional-response";
import { createOtherTypeOfPieceSkillStep3Initial } from "./step3-conditional-initial";
import { createOtherTypeOfPieceSkillStep4Response } from "./step4-conditional-response";

export const createOtherTypeOfPieceSkillFormV2: SkillForm = {
  skillId: "create_other_type_of_piece_v2",
  title: "Outro tipo de peça",
  subtitle: "Preencha a lista a seguir com detalhes essenciais para a criação da peça:",
  steps: [
    createOtherTypeOfPieceSkillStep1,
    [
      createOtherTypeOfPieceSkillStep2Initial,
      createOtherTypeOfPieceSkillStep2Response,
      createOtherTypeOfPieceSkillStep2Appeal,
    ],
    [
      createOtherTypeOfPieceSkillStep3Appeal,
      createOtherTypeOfPieceSkillStep3Response,
      createOtherTypeOfPieceSkillStep3Initial,
    ],
    [createOtherTypeOfPieceSkillStep4Response],
  ],
};
