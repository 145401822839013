import React from "react";

import { Box, Stack, Typography, styled } from "@mui/material";
import { ArrowOutwardOutlined } from "@mui/icons-material";

import { useFeatureFlags } from "@/hooks/useFeatureFlags";

import { Chip } from "../Chip";
import { calculateDaysToEndPlan, getPlanDaysLeftDescription } from "@/utils/plans";

export const PlanChips = ({
  planName,
  isTestingPlan,
  nextBillingDate,
  activeCasesCount,
  activeCasesQuota,
  handleOpenCasesScreen,
  usersCount,
  usersQuota,
  handleOpenUsersScreen,
  fullWidth,
}: {
  planName: string | undefined;
  isTestingPlan: boolean;
  nextBillingDate: string | undefined;
  activeCasesCount: number;
  activeCasesQuota: number;
  handleOpenCasesScreen: () => void;
  usersCount: number;
  usersQuota: number;
  handleOpenUsersScreen: () => void;
  fullWidth?: boolean;
}) => {
  const isPlanInactive = calculateDaysToEndPlan(nextBillingDate) < 0 && !isTestingPlan;

  return (
    <ChipRow>
      {planName && (
        <PlanChip
          planName={planName}
          showDaysLeft={isTestingPlan}
          nextBillingDate={nextBillingDate}
          fullWidth={fullWidth}
          isPlanInactive={isPlanInactive}
        />
      )}
      {/* This is commented because we don't have the next bill price yet */}
      {/* {!isTestingPlan && nextBillingDate && <NextBillChip nextBillDate={nextBillingDate} price={price} />} */}
      <ActiveCasesChip
        activeCasesCount={activeCasesCount}
        activeCasesQuota={activeCasesQuota}
        onOpenCasesScreen={handleOpenCasesScreen}
        fullWidth={fullWidth}
        isPlanInactive={isPlanInactive}
      />
      <UsersChip
        usersCount={usersCount}
        usersQuota={usersQuota}
        onOpenUsersScreen={handleOpenUsersScreen}
        fullWidth={fullWidth}
        isPlanInactive={isPlanInactive}
      />
    </ChipRow>
  );
};

const ChipInRow = styled(Stack)(() => ({
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}));

const PlanChip = ({
  planName,
  showDaysLeft,
  nextBillingDate,
  fullWidth,
  isPlanInactive,
}: {
  planName: string;
  showDaysLeft: boolean;
  nextBillingDate: string | undefined;
  fullWidth?: boolean;
  isPlanInactive: boolean;
}) => {
  const daysLeft = getPlanDaysLeftDescription(nextBillingDate);

  const daysToEndPlan = calculateDaysToEndPlan(nextBillingDate);
  const color = daysToEndPlan < 0 || isPlanInactive ? "error.main" : "inherit";

  return (
    <Chip title="Plano atual" fullWidth={fullWidth} color={color}>
      <ChipContent>
        <Typography
          variant="h6"
          fontWeight={400}
          sx={{
            color: color,
          }}
        >
          {planName}
        </Typography>
        {showDaysLeft && (
          <Typography
            sx={{
              color: color,
            }}
            variant="subtitle2"
          >
            {daysLeft}
          </Typography>
        )}
      </ChipContent>
    </Chip>
  );
};

const ChipRow = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  justifyContent: "space-between",
  gap: theme.spacing(2),
  width: "100%",
  flexWrap: "wrap",
}));

const ChipContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(1),
  width: "100%",
}));

// const NextBillChip = ({ price, nextBillDate }: { price: number; nextBillDate: string }) => {
//   return (
//     <Chip title="Próxima cobrança">
//       <ChipContent>
//         <Stack>
//           <Typography variant="h6" fontWeight={400}>
//             R$ {price},00
//           </Typography>
//           <Typography variant="subtitle2">Vence em: {nextBillDate}</Typography>
//         </Stack>
//       </ChipContent>
//     </Chip>
//   );
// };

const ActiveCasesChip = ({
  activeCasesCount,
  activeCasesQuota,
  onOpenCasesScreen,
  fullWidth,
  isPlanInactive,
}: {
  activeCasesCount: number;
  activeCasesQuota: number;
  onOpenCasesScreen: () => void;
  fullWidth?: boolean;
  isPlanInactive: boolean;
}) => {
  const { shouldValidateCasesQuota } = useFeatureFlags();

  const color = activeCasesCount >= activeCasesQuota || isPlanInactive ? "error.main" : "inherit";

  return (
    <Chip title="Casos ativos" onClick={onOpenCasesScreen} fullWidth={fullWidth} color={color}>
      <ChipContent>
        <ChipInRow>
          <Typography
            variant="h6"
            fontWeight={600}
            sx={{
              color: color,
            }}
          >
            {activeCasesCount}
            {shouldValidateCasesQuota && ` de ${activeCasesQuota}`}
          </Typography>
          <ArrowOutwardOutlined
            sx={{
              color: color,
            }}
          />
        </ChipInRow>
      </ChipContent>
    </Chip>
  );
};

const UsersChip = ({
  usersCount,
  usersQuota,
  onOpenUsersScreen,
  fullWidth,
  isPlanInactive,
}: {
  usersCount: number;
  usersQuota: number;
  onOpenUsersScreen: () => void;
  fullWidth?: boolean;
  isPlanInactive: boolean;
}) => {
  const color = isPlanInactive ? "error.main" : "inherit";

  return (
    <Chip title="Qtde. de usuários" onClick={onOpenUsersScreen} fullWidth={fullWidth} color={color}>
      <ChipContent>
        <ChipInRow>
          <Typography variant="h6" fontWeight={400} sx={{ color: color }}>
            {usersCount} de {usersQuota}
          </Typography>
          <ArrowOutwardOutlined sx={{ color: color }} />
        </ChipInRow>
      </ChipContent>
    </Chip>
  );
};
