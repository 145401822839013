import React from "react";
import { GridColumnMenuProps, GridColumnMenu } from "@mui/x-data-grid";

export const CustomColumnMenu = (props: GridColumnMenuProps) => {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        columnMenuColumnsItem: null,
      }}
    />
  );
};
