import { Box, SxProps, Typography, CircularProgress } from "@mui/material";
import React, { ReactNode } from "react";
import { DateTime } from "luxon";
import { CaseMessage } from "@/hooks/lexZap/types";
import { MessageStatus } from "@/components/LexZap/MessageStatus";
import { Sender } from "./Sender";
import { red } from "@mui/material/colors";

interface MessageCardProps {
  children: ReactNode;
  message: CaseMessage;
  sx?: SxProps;
  statusSx?: SxProps;
  statusColor?: string;
  containerSx?: SxProps;
}

export const MessageCard = ({
  children,
  message,
  sx,
  statusSx,
  containerSx,
  statusColor = "grey.600",
}: MessageCardProps) => {
  return (
    <Box
      sx={{
        maxWidth: message.fromLawyer ? "calc(65% + 32px)" : "65%",
        display: "flex",
        alignItems: "flex-end",
        gap: 1,
        ...containerSx,
      }}
    >
      <Box
        sx={{
          borderRadius: "16px",
          borderBottomRightRadius: message.fromLawyer ? 0 : "16px",
          borderBottomLeftRadius: message.fromLawyer ? "16px" : 0,
          backgroundColor: message.statusUpdateTimestamp?.failed
            ? red[100]
            : message.fromLawyer
              ? "orange.light.10"
              : "common.white",
          border: message.fromLawyer ? undefined : "1px solid",
          borderColor: message.fromLawyer ? undefined : "common.lightShade",
          width: "100%",
          paddingX: 3,
          paddingY: 2,
          alignItems: "start",
          position: "relative",
          marginBottom: !!message.reactions?.length ? 2 : 0,
          ...sx,
        }}
      >
        {children}

        {!!message.isSending && !!message.media && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <CircularProgress
              variant={message.media.uploadingProgress ? "determinate" : "indeterminate"}
              value={message.media.uploadingProgress}
            />
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            gap: 0.5,
            alignItems: "center",
            color: statusColor,
            position: "absolute",
            bottom: "4px",
            right: "10px",
            ...statusSx,
          }}
        >
          <Typography
            variant="body"
            sx={{
              whiteSpace: "pre-line",
            }}
          >
            {message.timestamp && DateTime.fromMillis(message.timestamp).toFormat("HH:mm")}
          </Typography>

          <MessageStatus message={message} defaultColor={statusColor} />
        </Box>

        {!!message.reactions?.length && (
          <Box
            sx={{
              display: "flex",
              zIndex: 2,
              alignItems: "center",
              position: "absolute",
              bottom: "8px",
              transform: "translateY(100%)",
              left: !message.fromLawyer ? "10px" : undefined,
              right: message.fromLawyer ? "10px" : undefined,
              padding: 0.5,
              borderRadius: "50%",
              backgroundColor: "common.white",
            }}
          >
            <Typography variant="body">{message.reactions[0].emoji}</Typography>
          </Box>
        )}
      </Box>

      <Sender message={message} />
    </Box>
  );
};
