import { ActionId, Message } from "@/contexts/MessagesContext";
import type { TObject, Static } from "@sinclair/typebox";
import { SkillsPayload } from "@/contexts/WebSocketContext";
import { Tag } from "../actions";
import { PlanType } from "@/hooks/credits/types";
import { Flags } from "@/hooks/useFeatureFlags/types";

export type SkillId =
  | "create_new_document"
  | "create_initial_petition"
  | "create_initial_petition_v2"
  | "create_civil_initial_petition"
  | "create_labor_initial_petition"
  | "create_criminal_initial_petition"
  | "create_pension_initial_petition"
  | "create_labor_complaint"
  | "create_contract"
  | "create_evidence_and_required_documents"
  | "create_notice"
  | "create_interview_script"
  | "search_precedent"
  | "create_contestation"
  | "create_reply"
  | "create_appeal"
  | "create_ordinary_appeal"
  | "create_extraordinary_appeal"
  | "create_special_appeal"
  | "create_unnamed_appeal"
  | "create_review_appeal"
  | "create_interlocutory_appeal"
  | "create_internal_appeal"
  | "create_appeal_in_strict_sense"
  | "legal_questions"
  | "create_case_strategy"
  | "summary_document"
  | "intercurrent_motion"
  | "legal_advice"
  | "fee_agreement"
  | "settlement_offer"
  | "hearings_script"
  | "edit_document"
  | "create_other_type_of_piece"
  | "adapt_document"
  | "create_other_type_of_piece_v2"
  | "create_reply_v2"
  | "create_contestation_v2"
  | "create_labor_complaint_v2";

type SkillType = "message" | "payload";

interface BaseSkill<SkillInputSchema extends TObject = TObject> {
  id: SkillId;
  name: string;
  description?: string;
  credits: CreditPerSkill;
  inputSchema: SkillInputSchema;
  actionId: ActionId;
  initialValue?: Partial<Static<SkillInputSchema>>;
  tags?: Tag[];
  flag?: keyof Flags;
  plans?: PlanType[];
  customForm?: boolean;
  type: SkillType;
}

export type Skill<SkillInputSchema extends TObject = TObject> =
  | (BaseSkill<SkillInputSchema> & { type: "message"; getMessage: (input: Static<SkillInputSchema>) => Message })
  | (BaseSkill<SkillInputSchema> & { type: "payload"; getPayload: (input: Static<SkillInputSchema>) => SkillsPayload });

export enum CreditPerSkill {
  ESSENTIAL_SKILL = 20,
  BASIC_SKILL = 50,
  INTERMEDIARY_SKILL = 100,
  ADVANCED_SKILL = 200,
}

export enum FileTypes {
  PDF = "application/pdf",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  JPEG = "image/jpeg",
  PNG = "image/png",
  TIFF = "image/tiff",
}
