import { useCallback } from "react";
import { create } from "zustand";
import { persist, StorageValue, subscribeWithSelector } from "zustand/middleware";

type BackupKey = `${string}:${string}`;

const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;

interface SkillBackupState {
  backups: Map<BackupKey, { data: Record<string, string | string[]>; updatedAt: number }>;
  clearBackup: ({ skillId, threadId }: { skillId: string; threadId: string }) => void;
  clearOldBackups: () => void;
  setBackup: ({
    skillId,
    threadId,
    data,
  }: {
    skillId: string;
    threadId: string;
    data: Record<string, string | string[]>;
  }) => void;
}

const getBackupKey = ({ skillId, threadId }: { skillId: string; threadId: string }): BackupKey =>
  `${skillId}:${threadId}`;

export const useSkillBackupStore = create<SkillBackupState>()(
  subscribeWithSelector(
    persist(
      (set) => ({
        backups: new Map(),
        clearBackup: ({ skillId, threadId }) => {
          set((state) => {
            const newBackups = new Map(state.backups);
            newBackups.delete(getBackupKey({ skillId, threadId }));
            return {
              ...state,
              backups: newBackups,
            };
          });
        },
        clearOldBackups: () => {
          set((state) => {
            const newBackups = new Map(state.backups);
            newBackups.forEach((value, key) => {
              if (Date.now() - value.updatedAt > ONE_DAY_IN_MS) {
                newBackups.delete(key);
              }
            });
            return {
              ...state,
              backups: newBackups,
            };
          });
        },
        setBackup: ({ skillId, threadId, data }) => {
          set((state) => {
            const newBackups = new Map(state.backups);
            newBackups.set(getBackupKey({ skillId, threadId }), { data, updatedAt: Date.now() });
            return {
              ...state,
              backups: newBackups,
            };
          });
        },
      }),
      {
        name: "skill-backup-store",
        onRehydrateStorage: () => {
          return (state) => {
            if (state) {
              state.clearOldBackups();
            }
          };
        },
        storage: {
          getItem: (name) => {
            const str = localStorage.getItem(name);
            if (!str) return null;
            const existingValue = JSON.parse(str);
            return {
              ...existingValue,
              state: {
                ...existingValue.state,
                backups: new Map(existingValue.state.backups),
              },
            };
          },
          setItem: (name, newValue: StorageValue<SkillBackupState>) => {
            const str = JSON.stringify({
              ...newValue,
              state: {
                ...newValue.state,
                backups: Array.from(newValue.state.backups.entries()),
              },
            });
            localStorage.setItem(name, str);
          },
          removeItem: (name) => localStorage.removeItem(name),
        },
      }
    )
  )
);

export const useSkillBackup = ({ skillId, threadId }: { skillId: string; threadId: string }) => {
  const backup = useSkillBackupStore((state) => state.backups.get(getBackupKey({ skillId, threadId })));
  return {
    data: backup?.data,
    clearBackup: useCallback(
      () => useSkillBackupStore.getState().clearBackup({ skillId, threadId }),
      [skillId, threadId]
    ),
    setBackup: useCallback(
      (data: Record<string, string | string[]>) =>
        useSkillBackupStore.getState().setBackup({ skillId, threadId, data }),
      [skillId, threadId]
    ),
  };
};
