import { Applicant } from "@/services/applicant/types";
import { useApplicants } from "../useApplicants";

interface UseApplicantProps {
  applicantId?: string;
  applicantPhoneNumber?: string;
}

interface UseLexZapCaseReturn {
  data?: Applicant;
  isLoading: boolean;
  isError: boolean;
}

export const useApplicant = ({ applicantId, applicantPhoneNumber }: UseApplicantProps): UseLexZapCaseReturn => {
  const { data: applicants, isLoading, isError } = useApplicants();

  return {
    data: applicants?.find(
      (item) => item.id === applicantId || (applicantPhoneNumber && item.phoneNumber === applicantPhoneNumber)
    ),
    isLoading,
    isError,
  };
};
