import WeGotAProblemSvg from "@/assets/svgs/we-got-a-problem.svg?react";
import { DialogModal } from "@/components/DialogModal";
import React from "react";
import { PlanType } from "@/hooks/credits/types";
import { Typography } from "@mui/material";
import { getPlanName } from "@/utils/getPlanName";

type SkillPaywallModalProps = {
  open: boolean;
  onClose: () => void;
  onOpenBilling: () => void;
  skillName: string;
  availabilityPlans: PlanType[];
};

export const SkillPaywallModal = ({
  open,
  onClose,
  onOpenBilling,
  skillName,
  availabilityPlans,
}: SkillPaywallModalProps) => {
  return (
    <DialogModal
      sx={{
        pb: 2,
        px: 1,
      }}
      description={
        <>
          <Typography variant="h6" mb={2}>
            {`${skillName} está indisponível no seu plano`}
          </Typography>
          <span>
            Para acessar a função é necessário contratar ou atualizar seu plano para uma das seguintes opções:
          </span>
          <br />
          <span>
            {availabilityPlans.reduce(
              (currentComponent, planType, index, plans) => (
                <>
                  {currentComponent}
                  {index ? (index < plans.length - 1 ? ", " : " ou ") : undefined}
                  <strong key={planType}>{getPlanName(planType)}</strong>
                </>
              ),
              <></>
            )}
          </span>
        </>
      }
      open={open}
      onClose={onClose}
      buttons={[
        {
          label: "Contrate Agora",
          onClick: onOpenBilling,
        },
      ]}
      buttonsDirection="column"
      icon={<WeGotAProblemSvg />}
    />
  );
};
