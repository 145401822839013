export const scrollbarStyles = {
  overflow: "auto",
  "&::-webkit-scrollbar": { width: "6px" },
  "&::-webkit-scrollbar-track": {
    backgroundColor: "grey.50",
  },
  "&::-webkit-scrollbar-thumb": {
    borderRadius: "10px",
    backgroundColor: "grey.200",
  },
} as const;
