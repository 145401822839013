import { Box, Typography } from "@mui/material";
import React from "react";
import { CaseMessage } from "@/hooks/lexZap/types";
import { useCompanyMember } from "@/hooks/company/useCompanyMember";
import liaImage from "@/assets/images/lia-icon-2.png";
import { getInitials } from "@/utils/getInitials";

export const Sender = ({ message }: { message: CaseMessage }) => {
  const { data: responsibleUser } = useCompanyMember({ memberId: message.lawyerId });

  if (!message.fromLawyer) {
    return null;
  }

  return (
    <Box
      sx={{
        width: "24px",
        height: "24px",
        minWidth: "24px",
        minHeight: "24px",
        borderRadius: "50%",
        backgroundColor: "common.mediumShade",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      {message.fromAssistant ? (
        <img src={liaImage} alt="Lia" width="100%" height="100%" />
      ) : (
        <Typography
          variant="body"
          color="common.white"
          sx={{
            fontSize: "10px",
          }}
          title={responsibleUser?.name}
        >
          {!!message.lawyerId && !!responsibleUser ? getInitials(responsibleUser.name).toUpperCase() : "-"}
        </Typography>
      )}
    </Box>
  );
};
