import { ReadOnlyAlert } from "@/components/Warnings";
import { usePaywallContext } from "@/contexts/PaywallContext";
import {
  ConnectionState,
  MessageStatus,
  SkillsPayload,
  SkillsResponse,
  UserInputDTO,
  UserInputSource,
  WebsocketMessageType,
  isUserInput,
  useSocket,
} from "@/contexts/WebSocketContext";
import * as logger from "@/core/logger";
import { useApi } from "@/hooks/useApi";
import { useEditor } from "@/hooks/useEditor";
import { EditorEvent } from "@/hooks/useEditor/types";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { EXTERNAL_URLS, getCasePath, ROUTE_PATHS } from "@/routes/routePaths";
import { Toast, WebToast } from "@//components/core/Toast";
import { DateTime } from "luxon";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useSearchParams } from "react-router-dom";
import { v4 as uuidV4 } from "uuid";
import {
  DOWNLOAD_ASSISTANT_ANSWER_ACTION_ID,
  INITIAL_PAGINATION_MESSAGES,
  MESSAGE_LENGTH_LIMIT,
  REPLACE_ENTIRE_DOCUMENT_ACTION_ID,
  REPLACE_SELECTION_ACTION_ID,
  TEXT_SELECTION_MAX_LENGTH,
} from "./constants";
import { MessagesContext } from "./context";
import {
  Action,
  ActionDTO,
  ActionId,
  ActionMessage,
  ChatFlow,
  ChatOption,
  ErrorFile,
  ExecutionStatus,
  HandleEvaluation,
  InMessageFile,
  LoadingState,
  Message,
  MessageCortex,
  ResponseMessage,
  SkillExecutionStatus,
  SuggestedSkillsMap,
  TOAST_MESSAGES,
  TextMessage,
  UploadedFile,
  UploadingFile,
  WaitingForResponse,
  WaitingForResponseType,
  isCortexResponseMessage,
} from "./types";
import {
  createErrorMessage,
  createEvidenceAndRequiredDocumentMessage,
  messageToDTO,
  removeUrlSignature,
  threadMessagesToChatMessages,
  uploadFileToS3,
} from "./utils";
import { shouldSuggestCreateEvidence } from "./utils/shouldSuggestCreateEvidence";
import { Result } from "@/core/Result";
import { waitingTypeFromSkillId } from "./utils/waitingTypeFromSkillId";
import { useSkills } from "@/hooks/skills/useSkills";
import { useCredits } from "@/hooks/credits/useCredits";
import { useCreditsHistory } from "@/hooks/credits/useCreditsHistory";
import { useCheckCredits } from "@/hooks/credits/useCheckCredit";
import { useSendMessage } from "@/hooks/threads/useSendMessage";
import { useStartSkillProcess } from "@/hooks/threads/useStartSkillProcess";
import { Skill, SkillId } from "@/core/skills/types";
import { useThreadContext } from "@/contexts/ThreadContext";
import { extractDataFromMessages } from "@/contexts/MessagesContext/utils/extractDataFromMessage";
import { HeapService } from "@/services/heap";
import { ClarityService } from "@/services/clarity";
import { Link } from "@mui/material";
import { useDocumentOnboarding } from "@/hooks/skills/useDocumentOnboarding";
import { isFreeUser } from "@/utils/plans";
import { PaginationPayload } from "@/core/api/types";
import { PlanType } from "@/hooks/credits/types";
import { canAccessSkill, isSkillAvailable } from "@/core/actions";
import { InsufficientCreditsError } from "@/services/thread/errors";
import { router } from "@/routes/router";

const DEFAULT_ERROR_MESSAGE =
  "Ops! Houve um erro ao analisar os dados enviados. Por favor, clique no botão abaixo e tente novamente.";

const INSUFFICIENT_CREDITS_ERROR_MESSAGE = "Créditos insuficientes";

interface MessagesProviderProps {
  children: ReactNode;
}
export const MessagesProvider = ({ children }: MessagesProviderProps) => {
  const location = useLocation();
  const isOnboarding = localStorage.getItem("isOnboarding") === "true";
  const { closeOnboarding } = useDocumentOnboarding();
  const { openPaywall, openSkillPaywall } = usePaywallContext();
  const { data: credits } = useCredits();

  const { getSignedUrl, createDocument, cancelExecution, getMessages, evaluateMessage } = useApi();

  const { currentThreadId, currentThread, createThread, generateThreadName, threadConnectionState } =
    useThreadContext();
  const { editor, allTabsAreEmpty: editorIsEmpty } = useEditor();
  const [searchParams, setSearchParams] = useSearchParams();

  const [messages, setMessages] = useState<Message[]>([]);
  const [waitingForResponse, setWaitingForResponse] = useState<WaitingForResponse>();
  const [inputContext, setInputContext] = useState("");
  const [inputContextFormatted, setInputContextFormatted] = useState("");
  const [loadingState, setLoadingState] = useState<LoadingState>("LOADING");
  const [skillExecutionStatus, setSkillExecutionStatus] = useState<SkillExecutionStatus>(undefined);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [paginationModel, setPaginationModel] = React.useState(INITIAL_PAGINATION_MESSAGES);

  const currentThreadCaseId = currentThread?.caseId;

  const skillId = searchParams.get("skill") as SkillId | null;
  const userPlan = credits?.companyPlan?.plan ?? PlanType.FREEMIUM;

  const { data: skills } = useSkills();

  const sendMessageMutation = useSendMessage();
  const startSkillProcessMutation = useStartSkillProcess();

  const socket = useSocket({
    onMessageReceived: ({ type, data }) => {
      switch (type) {
        case WebsocketMessageType.MESSAGE:
          handleMessage(data);
          break;
        case WebsocketMessageType.SKILLS:
          handleSkillsResponse(data);
          break;
      }
    },
  });

  const onSkillClick = ({ skill }: { skill: Skill }) => {
    if (canAccessSkill({ skill, userPlan })) {
      setSearchParams({ skill: skill.id });
    } else {
      openSkillPaywall({
        skillName: skill.name,
        availabilityPlans: skill.plans ?? [],
      });
    }
  };

  const skillToChatOption = (skill: Skill): ChatOption => ({
    id: skill.id,
    label: skill.name,
    onClick: () => onSkillClick({ skill }),
  });

  const getChatOption = ({ skill: skillId }: { skill: SkillId }): ChatOption => {
    const skill = skills?.find((skill) => skill.id === skillId);
    return skill
      ? skillToChatOption(skill)
      : {
          label: "",
          hidden: true,
        };
  };

  const handleFinishExecution = () => {
    setSkillExecutionStatus(undefined);
    setLoadingState("FINISHED");
  };

  const loadMessages = async ({ threadId, pagination }: { threadId: string; pagination: PaginationPayload }) => {
    setLoadingState("LOADING");
    try {
      const messagesResponse = await getMessages({ threadId, pagination });

      const processingMessage = messagesResponse.data.find((message) => message.status === MessageStatus.PROCESSING);
      if (processingMessage) {
        setWaitingForResponse({
          type: WaitingForResponseType.GENERIC,
          executionId: processingMessage.id,
        });
      }

      setMessages(threadMessagesToChatMessages(messagesResponse.data));

      setTotalResults(messagesResponse.totalResults);

      if (!skillId) {
        addMessage(getWelcomeMessage());
        setCurrentFlow(initialChatFlow);
      }

      setLoadingState("FINISHED");
    } catch (error) {
      setLoadingState("FINISHED");
      WebToast.error("Não foi possível carregar suas mensagens");
    }
  };

  const refetchMessages = async ({ threadId, pagination }: { threadId: string; pagination: PaginationPayload }) => {
    setLoadingState("REFETCHING");
    try {
      const messagesResponse = await getMessages({ threadId, pagination });
      const messages = threadMessagesToChatMessages(messagesResponse.data);
      setMessages((prev) => [...messages, ...prev]);
      setTotalResults(messagesResponse.totalResults);
      setLoadingState("FINISHED");
    } catch (error) {
      setLoadingState("FINISHED");
      WebToast.error("Não foi possível carregar suas mensagens");
    }
  };

  useEffect(() => {
    if (currentThreadId) {
      loadMessages({ threadId: currentThreadId, pagination: INITIAL_PAGINATION_MESSAGES });
      setWaitingForResponse(undefined);
      setSkillExecutionStatus(undefined);
    }
  }, [currentThreadId]);

  useEffect(() => {
    const shouldDisplayExitConfirmation = !!waitingForResponse;
    if (!shouldDisplayExitConfirmation) return;

    window.onbeforeunload = (event: BeforeUnloadEvent) => {
      event.returnValue = true;
      return "";
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, [waitingForResponse, editorIsEmpty]);

  const [isDocumentReadOnlyOpen, setIsDocumentReadOnlyOpen] = useState<boolean>(false);
  const [uploadingFiles, setUploadingFiles] = useState<{ [fileId: string]: number }>({});
  const [error, setError] = useState("");
  const flags = useFeatureFlags();

  const { data: creditsHistory } = useCreditsHistory();

  const filteredCommittedActions = useMemo(() => {
    return creditsHistory?.filter((item) => item.action?.startsWith("ESCREVER") && item.status === "COMMITTED");
  }, [creditsHistory]);

  const freeUser = isFreeUser(credits);

  const loading = !!waitingForResponse || loadingState === "LOADING";

  const { mutateAsync: checkCredit } = useCheckCredits();

  const checkCreditAvailability = async (actionId: ActionId) => {
    try {
      const { hasCredits } = await checkCredit(actionId);

      if (!hasCredits) {
        openPaywall();
      }
    } catch (error) {
      logger.error("checkCreditAvailability", error);
    }
  };

  const startSkill = async ({ skillId, actionId }: { skillId: string; actionId: ActionId }) => {
    const readOnly = await checkIfReadOnly();
    if (readOnly) return;

    try {
      HeapService.track("Skill Iniciada", { skillId });
      ClarityService.track("skill_started", skillId);
    } catch (e) {
      logger.debug(`Tracking error: ${JSON.stringify(e)}`);
    }

    void checkCreditAvailability(actionId);
  };

  const checkIfReadOnly = async (): Promise<boolean> => {
    if (!flags.warnReadOnlyDocument) {
      return false;
    }

    const isDocumentReadOnly = await editor?.checkIfDocumentIsReadOnly();
    if (isDocumentReadOnly) {
      setIsDocumentReadOnlyOpen(true);
    }

    return Boolean(isDocumentReadOnly);
  };

  const mergeSkillsWithChatOptions = (
    oldOptions: ChatOption[],
    skills?: Skill[],
    options?: { replaceMatchingSkillsOnly?: boolean }
  ): ChatOption[] => {
    const { replaceMatchingSkillsOnly = false } = options || {};

    const enabledSkills = skills?.filter((skill) => isSkillAvailable({ skill, userFlags: flags })) || [];

    if (replaceMatchingSkillsOnly) {
      return oldOptions.map((option) => {
        const newSkill = enabledSkills.find((skill) => skill.id === option.id);

        return newSkill ? skillToChatOption(newSkill) : option;
      });
    }

    const enabledSkillIds = enabledSkills.map((skill) => skill.id);

    const oldOptionsWithoutNewSkills = oldOptions.filter(
      (option) => !option.id || !enabledSkillIds.includes(option.id)
    );

    const newSkillsChatOptions: ChatOption[] = enabledSkills.map(skillToChatOption);

    return [...oldOptionsWithoutNewSkills, ...newSkillsChatOptions];
  };

  const documentCreationText = "Escrever nova peça";

  const enabledSkills = skills?.filter((skill) => isSkillAvailable({ skill, userFlags: flags })) || [];

  const actions = enabledSkills.map((skill) => ({
    id: skill.id,
    text: skill.name,
    onClick: () => onSkillClick({ skill }),
  }));

  const seeMoreOptions = () => {
    setCurrentFlow({
      ...initialChatFlow,
      options: enabledSkills.map(skillToChatOption),
    });
  };

  const initialChatFlow: ChatFlow = {
    message: "Para começar, selecione uma das minhas habilidades abaixo:",
    options: mergeSkillsWithChatOptions(
      [
        getChatOption({ skill: "create_contract" }),
        getChatOption({ skill: "legal_questions" }),
        {
          label: "Ver mais",
          onClick: seeMoreOptions,
        },
      ],
      skills,
      { replaceMatchingSkillsOnly: true }
    ),
  };

  const DEFAULT_SUGGESTION_MESSAGE = (
    <>
      Seguem algumas sugestões do que você pode fazer agora.
      <br />
      Se tiver alguma dúvida, acesse nossa{" "}
      <Link href={EXTERNAL_URLS.HELP_CENTER_URL} target="_blank" rel="noreferrer">
        Central de Ajuda
      </Link>
      .
    </>
  );

  const legalDocumentCreatedOptions: ChatOption[] = [
    getChatOption({ skill: "search_precedent" }),
    getChatOption({ skill: "edit_document" }),
    getChatOption({ skill: "legal_questions" }),
  ];

  const suggestedSkillsMap: SuggestedSkillsMap = {
    [ActionId.CREATE_INTERVIEW_SCRIPT_ACTION]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [getChatOption({ skill: "legal_questions" }), getChatOption({ skill: "create_case_strategy" })],
    },
    [ActionId.CREATE_ONE_PIECE]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: legalDocumentCreatedOptions,
    },
    [ActionId.CREATE_CONTRACT]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [
        getChatOption({ skill: "legal_questions" }),
        getChatOption({ skill: "edit_document" }),
        getChatOption({ skill: "fee_agreement" }),
      ],
    },
    [ActionId.LEGAL_QUESTION]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [
        getChatOption({ skill: "legal_questions" }),
        getChatOption({ skill: "create_reply" }),
        getChatOption({ skill: "create_contract" }),
        getChatOption({ skill: "legal_advice" }),
      ],
    },
    [ActionId.CREATE_STRATEGY]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [
        getChatOption({ skill: "create_reply" }),
        getChatOption({ skill: "search_precedent" }),
        getChatOption({ skill: "legal_questions" }),
      ],
    },
    [ActionId.SUMMARIZE_DOCUMENT]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [
        getChatOption({ skill: "create_case_strategy" }),
        getChatOption({ skill: "create_reply" }),
        getChatOption({ skill: "legal_questions" }),
      ],
    },
    [ActionId.LEGAL_ADVICE]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [
        getChatOption({ skill: "legal_questions" }),
        getChatOption({ skill: "search_precedent" }),
        getChatOption({ skill: "fee_agreement" }),
        getChatOption({ skill: "edit_document" }),
      ],
    },
    [ActionId.FEE_AGREEMENT]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [
        getChatOption({ skill: "create_case_strategy" }),
        getChatOption({ skill: "legal_questions" }),
        getChatOption({ skill: "edit_document" }),
      ],
    },
    [ActionId.SETTLEMENT_OFFER]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [
        getChatOption({ skill: "legal_questions" }),
        getChatOption({ skill: "search_precedent" }),
        getChatOption({ skill: "intercurrent_motion" }),
        getChatOption({ skill: "create_case_strategy" }),
      ],
    },
    [ActionId.SEARCH_PRECEDENT]: {
      message: "",
      options: [],
    },
    [ActionId.EDIT_DOCUMENT]: {
      message: "",
      options: [],
    },
    [ActionId.INTERCURRENT_MOTION]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: legalDocumentCreatedOptions,
    },
    [ActionId.CREATE_NEW_DOCUMENT]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: legalDocumentCreatedOptions,
    },
    [ActionId.CREATE_PETITION_SUMMARY]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [
        getChatOption({ skill: "create_case_strategy" }),
        getChatOption({ skill: "create_reply" }),
        getChatOption({ skill: "legal_questions" }),
      ],
    },
    [ActionId.CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: legalDocumentCreatedOptions,
    },
    [ActionId.CREATE_NOTICE_ACTION]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [
        getChatOption({ skill: "legal_questions" }),
        getChatOption({ skill: "create_case_strategy" }),
        getChatOption({ skill: "search_precedent" }),
        getChatOption({ skill: "fee_agreement" }),
      ],
    },
    [ActionId.HEARINGS_SCRIPT]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: legalDocumentCreatedOptions,
    },
    [ActionId.UPDATE_DOCUMENTS]: {
      message: DEFAULT_SUGGESTION_MESSAGE,
      options: [
        getChatOption({ skill: "legal_questions" }),
        getChatOption({ skill: "search_precedent" }),
        getChatOption({ skill: "create_reply" }),
        getChatOption({ skill: "edit_document" }),
      ],
    },
  };

  const getSuggestedSkills = (actionId: ActionId): ChatFlow => {
    const baseSuggestedSkills = suggestedSkillsMap[actionId];

    const activeSkillsOptions = baseSuggestedSkills?.options
      ? mergeSkillsWithChatOptions(baseSuggestedSkills?.options, skills, { replaceMatchingSkillsOnly: true })
      : baseSuggestedSkills?.options;

    return {
      ...baseSuggestedSkills,
      options: activeSkillsOptions,
    };
  };

  const [currentFlow, setCurrentFlow] = useState<ChatFlow>();

  const handleOptionSelect = (option: ChatOption) => {
    if (option.response) {
      addMessage({
        id: uuidV4(),
        type: "TEXT",
        direction: "SENT",
        author: "Current User",
        date: DateTime.now(),
        status: "READ",
        text: option.label,
      });

      setCurrentFlow(option.response);
    } else if (option.onClick) {
      option.onClick();
    }
  };

  useEffect(() => {
    if (!currentFlow) return;

    addMessage({
      id: uuidV4(),
      type: "ACTION",
      author: "Lexter.ai",
      direction: "RECEIVED",
      date: DateTime.now(),
      status: "READ",
      text: currentFlow.message,
      actions:
        currentFlow.options?.map((option) => ({
          id: option.id,
          text: option.label,
          onClick: () => handleOptionSelect(option),
          hidden: option.hidden,
          disabled: option.disabled,
        })) || [],
    });
  }, [currentFlow]);

  useEffect(() => {
    if (!editor) return;

    editor.addEventListener(EditorEvent.SELECTION_CHANGE, async () => {
      try {
        const selection = await editor.getSelection();
        let formattedSelection = "";

        try {
          formattedSelection = await editor.getHtmlSelection();
        } catch (error) {
          logger.warn("Error getting formatted selection:", error);
          formattedSelection = selection;
        }

        if (selection.length > TEXT_SELECTION_MAX_LENGTH) {
          return Toast.warn(
            `O limite de ${TEXT_SELECTION_MAX_LENGTH.toLocaleString(
              "pt-BR"
            )} caracteres para seleção do texto foi ultrapassado. Ajuste o tamanho do texto selecionado para ter uma melhor performance.`
          );
        }

        setInputContext(selection);
        setInputContextFormatted(formattedSelection);
      } catch (error) {
        logger.error("Error during selection change:", error);
        toast.error("Erro ao selecionar texto");
      }
    });
  }, [editor]);

  useEffect(() => {
    if (location.pathname === ROUTE_PATHS.HOME) {
      setInputVisible(false);
    }
  }, [location.pathname]);

  const clearContext = async () => {
    setInputContext("");
    setInputContextFormatted("");
    await editor?.clearSelection();
  };

  const addMessage = (message: Message) => {
    setMessages((previous) => [...previous, message]);
  };

  const replaceDocumentAction = async (
    action: ActionDTO,
    newDocument: { id?: number; name?: string }
  ): Promise<{ errorMessage?: string }> => {
    if (!editor) return {};

    if (action.id !== REPLACE_ENTIRE_DOCUMENT_ACTION_ID) {
      return { errorMessage: "Ação inválida" };
    }

    if (action.type === "TEXT") {
      return editor.replaceBody(action.text || "");
    } else if (action.type === "FORMATTED") {
      return editor.openFormattedText({
        document: {
          id: newDocument.id,
          name: newDocument.name,
          formattedText: { lines: action.lines, stylesMap: action.stylesMap },
        },
      });
    }

    return { errorMessage: "Ação inválida" };
  };

  const showPaywallWithDiscountForFreemiumUsersInSecondAction = () => {
    if (freeUser && filteredCommittedActions && filteredCommittedActions.length === 2) {
      window.userGuiding.previewGuide(118722);
    }
  };

  const handleMessage = (msg: ResponseMessage) => {
    const retryMessageId = waitingForResponse?.executionId;

    try {
      if (isCortexResponseMessage(msg)) {
        setLoadingState("FINISHED");
        if (!msg.success) {
          logger.error("Message processing failed", { retryMessageId });
          throw new Error("Erro ao processar a mensagem");
        } else if (!msg.document) {
          setSkillExecutionStatus("SUCCESS_WITHOUT_DOCUMENT");
        } else {
          setSkillExecutionStatus("SUCCESS");
        }

        const skillId = msg.replyTo.actionId;
        const suggestedSkills = getSuggestedSkills(skillId);
        const showSuggestedSkillsFlow = !!suggestedSkills;

        const newMessages: Message[] = msg.messages
          .map((m: MessageCortex) => {
            const actions: Action[] = [];
            m.actions?.forEach((action) => {
              //The only action we will take instantly is the replace entire
              //document action. All other actions will be displayed to the user
              if (action.id === REPLACE_ENTIRE_DOCUMENT_ACTION_ID) {
                replaceDocumentAction(action, { id: msg.document?.id, name: msg.document?.name }).then(
                  ({ errorMessage }) => {
                    if (errorMessage) {
                      addMessage(createErrorMessage({ text: errorMessage }));
                    } else {
                      showPaywallWithDiscountForFreemiumUsersInSecondAction();
                    }
                  }
                );
              } else if (action.id === REPLACE_SELECTION_ACTION_ID) {
                actions.push({
                  text: "Substituir",
                  // onClick: () => replaceSelectionAction(action),
                  onClick: (message: Message) => editor?.replaceSelection(message.text.toString()),
                });
                actions.push({
                  text: "Inserir",
                  // onClick: () => replaceSelectionAction(action),
                  onClick: (message: Message) => editor?.replaceSelection(message.text.toString()),
                });
              } else if (action.id === DOWNLOAD_ASSISTANT_ANSWER_ACTION_ID) {
                editor?.openFormattedText({
                  document: {
                    id: msg.document?.id,
                    name: msg.document?.name,
                    formattedText: { lines: action.lines, stylesMap: action.stylesMap },
                  },
                });
              }
            });

            if (currentThreadCaseId && !m.actions?.length) {
              actions.push({
                text: "Voltar para a página do caso",
                onClick: () => {
                  void router.navigate(getCasePath({ caseId: currentThreadCaseId }));
                },
              });
            }

            const hasActions = actions.length > 0;
            const copyable = !Boolean(msg.replyTo.actionId);

            return {
              id: m.id,
              type: hasActions ? "ACTION" : "TEXT",
              direction: "RECEIVED",
              author: "Lexter.ai",
              date: DateTime.now(),
              status: "READ",
              text: m.text,
              actions: hasActions ? actions : undefined,
              copyable,
            } as Message;
          })
          .filter((m): m is Message => m !== null);

        setMessages((prev) => [...prev, ...newMessages]);

        const documentToOpenInNewWindow = msg.messages.some((m) =>
          m.actions?.some((action) => action.id === DOWNLOAD_ASSISTANT_ANSWER_ACTION_ID)
        );

        const editorInstructionText =
          'Caso queira editar algum trecho, basta selecionar "Editar texto" abaixo e seguir as instruções. Você também pode escolher outras ações:';

        if (showSuggestedSkillsFlow && suggestedSkills?.options) {
          const suggestionsMessage: Message = {
            id: uuidV4(),
            type: "ACTION",
            direction: "RECEIVED",
            author: "Lexter.ai",
            date: DateTime.now(),
            status: "READ",
            text: suggestedSkills.message,
            actions: suggestedSkills?.options.map((option) => ({
              text: option.label,
              id: option.id,
              hidden: option.hidden,
              onClick: () => handleOptionSelect(option),
            })),
          };
          if (shouldSuggestCreateEvidence(msg.replyTo)) {
            suggestionsMessage.actions.unshift({
              text: "Sugestor de provas e documentos para protocolo",
              id: "create_evidence_and_required_documents",
              onClick: () => {
                sendMessage(
                  createEvidenceAndRequiredDocumentMessage({
                    cortexResponse: msg,
                    showSuggestedSkillsMessage: showSuggestedSkillsFlow,
                  }),
                  {
                    editorContent: msg.replyTo.editorContent,
                  }
                );
              },
            });
          }
          addMessage(suggestionsMessage);
        }

        if (shouldSuggestCreateEvidence(msg.replyTo) && !showSuggestedSkillsFlow) {
          addMessage({
            id: uuidV4(),
            type: "ACTION",
            direction: "RECEIVED",
            author: "Lexter.ai",
            date: DateTime.now(),
            status: "READ",
            text: "Você gostaria de ver a sugestão de provas e documentos necessários para protocolo?",
            actions: [
              {
                text: "Sim",
                onClick: () => {
                  sendMessage(
                    createEvidenceAndRequiredDocumentMessage({
                      cortexResponse: msg,
                      showSuggestedSkillsMessage: showSuggestedSkillsFlow,
                    }),
                    {
                      editorContent: msg.replyTo.editorContent,
                    }
                  );
                },
              },
              {
                text: "Não",
                onClick: () => {
                  setCurrentFlow({
                    ...initialChatFlow,
                    message: editorInstructionText,
                  });
                },
              },
            ],
          });
        } else if (msg.document && !documentToOpenInNewWindow && !showSuggestedSkillsFlow) {
          setCurrentFlow({
            ...initialChatFlow,
            message: editorInstructionText,
          });
        }
      } else {
        logger.error(
          `ACTION ERROR - retryMessageId ${retryMessageId} - !isCortexResponseMessage(msg), msg.text: ${msg.text}`
        );
        setMessages((prev) => [
          ...prev,
          createErrorMessage(
            { text: msg.text },
            { retry: retryMessageId ? () => retryByMessageId(retryMessageId) : undefined }
          ),
        ]);

        if (msg.insufficientCredits) {
          logger.warn("handleMessage - User has insufficient credits", { retryMessageId });
          openPaywall();
        }
        setSkillExecutionStatus(undefined);
      }
    } catch (e) {
      if (e instanceof Object && "message" in e) {
        logger.error("Unexpected error in handleMessage", {
          retryMessageId,
          error: e instanceof Error ? e.message : "Unknown error",
          stack: e instanceof Error ? e.stack : undefined,
        });
      }
      setMessages((prev) => [
        ...prev,
        createErrorMessage(
          { text: DEFAULT_ERROR_MESSAGE },
          {
            retry: retryMessageId ? () => retryByMessageId(retryMessageId) : undefined,
            cancel: () =>
              setCurrentFlow({
                ...initialChatFlow,
                message: "Criação de peça cancelada. Deseja começar uma nova tarefa?",
              }),
          }
        ),
      ]);
      setSkillExecutionStatus(undefined);
    }

    setWaitingForResponse(undefined);
  };

  const uploadFile = async (file: UploadingFile): Promise<UploadedFile> => {
    const fileName = file.file.name;

    logger.info("Starting file upload:", {
      fileId: file.id,
      fileName,
      fileSize: file.file.size,
    });

    try {
      const { url, location } = await getSignedUrl({ name: fileName });

      await uploadFileToS3(url, file.file, (progress) => {
        setUploadingFiles((prev) => ({ ...prev, [file.id]: progress }));
        logger.debug(`Upload progress: fileId=${file.id}, progress=${progress}`);
      });

      const cortexId = await createDocument({ name: fileName, location });

      setUploadingFiles((prev) => {
        Reflect.deleteProperty(prev, file.id);
        return { ...prev };
      });

      return {
        id: file.id,
        type: "UPLOADED",
        cortexId,
        url: removeUrlSignature(url),
        name: file.file.name,
      };
    } catch (error) {
      logger.error("Error uploading file:", {
        fileId: file.id,
        fileName,
        error,
      });
      throw error;
    }
  };

  const uploadFiles = async (files: UploadingFile[]) => {
    logger.info("Starting batch file upload:", {
      numberOfFiles: files.length,
      fileIds: files.map((f) => f.id),
    });

    const uploadedFiles = await Promise.all(
      files.map(async (file) => {
        return uploadFile(file)
          .then((value) => value)
          .catch((err): ErrorFile => {
            logger.error("Individual file upload failed:", {
              fileId: file.id,
              fileName: file.name,
              error: err,
            });
            return {
              id: file.id,
              type: "ERROR",
              name: file.name,
              error: `Erro no upload: ${err}`,
            };
          });
      })
    );

    const successCount = uploadedFiles.filter((f) => f.type === "UPLOADED").length;
    const errorCount = uploadedFiles.filter((f) => f.type === "ERROR").length;

    logger.info("Batch upload completed:", {
      totalFiles: files.length,
      successCount,
      errorCount,
    });

    return { uploadedFiles };
  };

  const sendMessage = async (
    message: Message,
    options?: {
      editorContent?: string;
    }
  ) => {
    logger.info("Sending message:", {
      messageId: message.id,
      messageType: message.type,
      messageText: message.text,
    });

    closeOnboarding();

    if (threadConnectionState !== ConnectionState.OPEN) {
      logger.warn("Thread connection not open, aborting send");
      return;
    }
    setSkillExecutionStatus("RUNNING");

    const messagesToSend = (messages: Message[]) => {
      return messages
        .filter((m) => {
          if (m.type === "INITIAL") return false;
          if (m.type === "ERROR") return false;

          const hasFileError = m.type === "FLOW" && m.files?.some((f) => f.type === "ERROR");
          return !hasFileError;
        })
        .map(messageToDTO);
    };

    const chatMessages = messagesToSend(messages);

    const messageLength = message.type === "TEXT" ? (message.text?.length || 0) + (message.context?.length || 0) : 0;

    const isOverLimit = messageLength > MESSAGE_LENGTH_LIMIT;

    if (isOverLimit) {
      logger.warn("Message exceeds length limit", {
        messageLength,
        limit: MESSAGE_LENGTH_LIMIT,
      });
      return setError(`A mensagem excede o limite de ${MESSAGE_LENGTH_LIMIT} caracteres permitidos.`);
    }

    addMessage(message);

    if (message.type === "FILE" || message.type === "FLOW") {
      const { uploadedFiles } = await uploadFiles(
        message.files.filter((file: InMessageFile): file is UploadingFile => file.type === "UPLOADING")
      );
      const updatedFiles = message.files.map((file) => {
        return uploadedFiles.find((f) => f.id === file.id) || file;
      });

      setMessages((previous) =>
        previous.map((m) => {
          if (m.id === message.id) {
            return {
              ...m,
              files: updatedFiles,
            };
          }
          return m;
        })
      );

      chatMessages.push(
        messageToDTO({
          ...message,
          files: updatedFiles,
        } as Message)
      );

      const errorFiles = updatedFiles.filter((file): file is ErrorFile => file.type === "ERROR");
      if (errorFiles.length) {
        logger.warn("Some files failed to upload:", {
          errorCount: errorFiles.length,
          errorFiles: errorFiles.map((f) => f.name),
        });
        addMessage(
          createErrorMessage(
            {
              text: `Não foi possível fazer o upload ${errorFiles.length > 1 ? "dos arquivos" : "do arquivo"}: ${errorFiles
                .map((file) => `'${file.name}'`)
                .join(", ")}.`,
            },
            {
              retry: () => {
                const id = uuidV4();
                logger.info(`ACTION RETRY (DOWNLOAD FAILED) - id ${id}`);
                return sendMessage({
                  ...message,
                  id,
                  files: message.files.map((file) => {
                    const uploadedFile = updatedFiles.find((f) => f.id === file.id);

                    if (uploadedFile) {
                      if (uploadedFile.type === "ERROR") {
                        return {
                          ...file,
                          type: "UPLOADING",
                        } as UploadingFile;
                      }
                      return uploadedFile;
                    }

                    return file;
                  }),
                });
              },
            }
          )
        );
        handleFinishExecution();
        return;
      }
    } else {
      chatMessages.push(messageToDTO(message));
    }

    const editorBody = await editor?.getBody();

    if (!currentThreadId) {
      logger.error("Attempting to send message without thread ID");
      return;
    }

    try {
      await sendMessageMutation.mutateAsync({
        threadId: currentThreadId,
        messages: chatMessages,
        isOnboarding,
        content: options?.editorContent || editorBody || "",
      });
    } catch (error) {
      logger.error("Error sending message:", error);

      if (error instanceof InsufficientCreditsError) {
        logger.warn("Insufficient Credits Error:", error);

        addMessage(
          createErrorMessage(
            { text: INSUFFICIENT_CREDITS_ERROR_MESSAGE },
            {
              retry: () => sendMessage(message, options),
              cancel: () => {
                setLastSkillPayload(undefined);
                setCurrentFlow({
                  ...initialChatFlow,
                  message: "Processo cancelado. Deseja começar uma nova tarefa?",
                });
              },
            }
          )
        );

        openPaywall();
      } else {
        addMessage(
          createErrorMessage(
            { text: DEFAULT_ERROR_MESSAGE },
            {
              retry: () => sendMessage(message, options),
              cancel: () => {
                setLastSkillPayload(undefined);
                setCurrentFlow({
                  ...initialChatFlow,
                  message: "Processo cancelado. Deseja começar uma nova tarefa?",
                });
              },
            }
          )
        );
      }

      handleFinishExecution();
      setWaitingForResponse(undefined);
      setSkillExecutionStatus(undefined);
      return;
    }

    const messageActionId = message?.type === "FLOW" && message.actions.length && (message.actions[0].id as ActionId);

    let waitingType: WaitingForResponseType = WaitingForResponseType.INTERACTION_WITH_CHAT;

    if (messageActionId) {
      if ([ActionId.CREATE_EVIDENCE_AND_REQUIRED_DOCUMENT, ActionId.CREATE_CONTRACT].includes(messageActionId)) {
        waitingType = WaitingForResponseType.CREATE_DOCUMENT;
      } else if (messageActionId === ActionId.CREATE_PETITION_SUMMARY) {
        waitingType = WaitingForResponseType.CREATE_SUMMARY;
      } else {
        waitingType = WaitingForResponseType.CREATE_MOTION;
      }
    }

    setWaitingForResponse({ type: waitingType, executionId: message.id });

    const data = extractDataFromMessages(chatMessages);
    if (data) {
      generateThreadName({ data });
    }
  };

  const [lastSkillPayload, setLastSkillPayload] = useState<SkillsPayload>();
  const startSkillProcess = async (skillPayload: SkillsPayload) => {
    closeOnboarding();

    if (threadConnectionState !== ConnectionState.OPEN) {
      logger.warn("Thread connection not open, aborting skill process");
      return;
    }

    setSkillExecutionStatus("RUNNING");

    if (skillPayload.messageToSave) {
      const message: TextMessage = {
        id: skillPayload.requestId,
        type: "TEXT",
        direction: "SENT",
        author: "Current User",
        date: DateTime.now(),
        status: "READ",
        text: skillPayload.messageToSave,
      };

      setMessages((prev) => [...prev, message]);
    }

    const skillDTOResult = await skillWithUploadedFiles(skillPayload);

    if (skillDTOResult.isFailure) {
      logger.error("Failed to upload skill files");
      addMessage(
        createErrorMessage(
          {
            text: `Não foi possível fazer o upload dos arquivos.`,
          },
          {
            retry: () => {
              const id = uuidV4();
              logger.info(`ACTION RETRY (UPLOAD FAILED) - id ${id}`);
              return startSkillProcess({
                ...skillPayload,
                requestId: id,
              });
            },
          }
        )
      );
      setWaitingForResponse(undefined);
      handleFinishExecution();
      return;
    }

    const skillDTO = {
      ...skillDTOResult.getValue(),
      isOnboarding,
    };

    if (!currentThreadId) {
      logger.error("Attempting to start skill process without thread ID");
      return;
    }

    try {
      await startSkillProcessMutation.mutateAsync({
        threadId: currentThreadId,
        skillPayload: skillDTO as SkillsPayload,
      });
    } catch (error) {
      logger.error("Error starting skill process:", error);

      const retryRequestId = skillPayload.requestId;

      if (error instanceof InsufficientCreditsError) {
        logger.warn(`SKILL ERROR - retrySkillById ${retryRequestId} - Insufficient Credits`, error);

        addMessage(
          createErrorMessage(
            { text: INSUFFICIENT_CREDITS_ERROR_MESSAGE },
            {
              retry: () => startSkillProcess(skillPayload),
              cancel: () => {
                setLastSkillPayload(undefined);
                setCurrentFlow({
                  ...initialChatFlow,
                  message: "Processo cancelado. Deseja começar uma nova tarefa?",
                });
              },
            }
          )
        );

        openPaywall();
      } else {
        if (error instanceof Error) {
          logger.error(`SKILL ERROR - retrySkillById ${retryRequestId} - ${error.message}`, error);
        } else {
          logger.error(`SKILL ERROR - retrySkillById ${retryRequestId} - unknown error`, error);
        }

        addMessage(
          createErrorMessage(
            { text: DEFAULT_ERROR_MESSAGE },
            {
              retry: () => startSkillProcess(skillPayload),
              cancel: () => {
                setLastSkillPayload(undefined);
                setCurrentFlow({
                  ...initialChatFlow,
                  message: "Processo cancelado. Deseja começar uma nova tarefa?",
                });
              },
            }
          )
        );
      }

      setSkillExecutionStatus(undefined);
      setWaitingForResponse(undefined);
      handleFinishExecution();
      return;
    }

    setLastSkillPayload({ ...skillPayload, isOnboarding: skillDTO.isOnboarding });
    setWaitingForResponse({ type: waitingTypeFromSkillId(skillPayload.skillId), executionId: skillPayload.requestId });
    generateThreadName({ data: skillDTO });
  };

  const skillWithUploadedFiles = async (skill: SkillsPayload): Promise<Result<SkillsPayload<UserInputDTO>>> => {
    try {
      setWaitingForResponse({ type: WaitingForResponseType.DOCUMENT_UPLOAD });

      const skillDTO = { ...skill, payload: { ...skill.payload } } as SkillsPayload<UserInputDTO>;
      await Promise.all(
        Object.keys(skillDTO.payload).map(async (key) => {
          // @ts-expect-error We are sure that the key exists
          const skillPayloadAttribute = skillDTO.payload[key] as UserInputDTO;

          if (isUserInput(skillPayloadAttribute)) {
            switch (skillPayloadAttribute.source) {
              case UserInputSource.FILE: {
                const uploadedFile = await uploadFile({
                  id: uuidV4(),
                  type: "UPLOADING",
                  name: skillPayloadAttribute.file.name,
                  file: skillPayloadAttribute.file,
                }).then((value) => value);

                // @ts-expect-error We are sure that the key exists
                skillDTO.payload[key] = {
                  source: skillPayloadAttribute.source,
                  file: uploadedFile,
                } as UserInputDTO;
                break;
              }
              case UserInputSource.CONTENT: {
                const editorText = await editor?.getBody();

                // @ts-expect-error We are sure that the key exists
                skillDTO.payload[key] = {
                  source: skillPayloadAttribute.source,
                  text: editorText,
                } as UserInputDTO;
                break;
              }
              case UserInputSource.TEXT: {
                // @ts-expect-error We are sure that the key exists
                skillDTO.payload[key] = {
                  source: skillPayloadAttribute.source,
                  text: skillPayloadAttribute.text,
                } as UserInputDTO;
              }
            }
          }
        })
      );

      return Result.ok(skillDTO);
    } catch (e) {
      logger.error("Error getting skill with uploaded files", e);
      return Result.fail(e instanceof Error ? e.message : "");
    } finally {
      setWaitingForResponse(undefined);
      handleFinishExecution();
    }
  };

  const handleSkillsResponse = (response: SkillsResponse) => {
    const retryRequestId = lastSkillPayload?.requestId;
    try {
      if (!response.success) {
        logger.error("Skills response indicates failure", { retryRequestId });
        throw new Error("Erro ao processar");
      }

      let messageToAdd: ActionMessage | TextMessage | undefined = undefined;

      if (response.messageToUser) {
        messageToAdd = currentThreadCaseId
          ? {
              id: uuidV4(),
              type: "ACTION",
              direction: "RECEIVED",
              author: "Lexter.ai",
              date: DateTime.now(),
              status: "READ",
              text: response.messageToUser,
              actions: [
                {
                  text: "Voltar para a página do caso",
                  onClick: () => {
                    void router.navigate(getCasePath({ caseId: currentThreadCaseId }));
                  },
                },
              ],
            }
          : {
              id: uuidV4(),
              type: "TEXT",
              direction: "RECEIVED",
              author: "Lexter.ai",
              date: DateTime.now(),
              status: "READ",
              text: response.messageToUser,
              copyable: response.skillId === ActionId.LEGAL_QUESTION,
            };
      }

      if (response.document) {
        const { document } = response;

        editor?.openFormattedText({
          document: {
            id: document.id,
            name: document.name,
            formattedText: document.formattedBody,
          },
        });
      }

      if (messageToAdd) {
        addMessage(messageToAdd);
      }

      const suggestedSkills = getSuggestedSkills(response.skillId);

      if (suggestedSkills && suggestedSkills.message) {
        addMessage({
          id: uuidV4(),
          type: "ACTION",
          direction: "RECEIVED",
          author: "Lexter.ai",
          date: DateTime.now(),
          status: "READ",
          text: suggestedSkills.message,
          actions: suggestedSkills?.options
            ? suggestedSkills?.options.map((option) => ({
                text: option.label,
                id: option.id,
                hidden: option.hidden,
                onClick: () => handleOptionSelect(option),
              }))
            : undefined,
        } as Message);
      }

      setLastSkillPayload(undefined);
      setSkillExecutionStatus("SUCCESS");

      if (!response.document) {
        setSkillExecutionStatus("SUCCESS_WITHOUT_DOCUMENT");
      }
    } catch (e) {
      if (e instanceof Object && "message" in e) {
        logger.error("Error processing skills response", {
          retryRequestId,
          error: e instanceof Error ? e.message : "Unknown error",
          stack: e instanceof Error ? e.stack : undefined,
          insufficientCredits: response.insufficientCredits,
        });
      }
      setMessages((prev) => [
        ...prev,
        createErrorMessage(
          { text: response.insufficientCredits ? INSUFFICIENT_CREDITS_ERROR_MESSAGE : DEFAULT_ERROR_MESSAGE },
          {
            retry: retryRequestId ? () => retrySkillById(retryRequestId) : undefined,
            cancel: () => {
              setLastSkillPayload(undefined),
                setCurrentFlow({
                  ...initialChatFlow,
                  message: "Processo cancelado. Deseja começar uma nova tarefa?",
                });
            },
          }
        ),
      ]);

      if (response.insufficientCredits) {
        logger.warn("handleSkillsResponse - User has insufficient credits", { retryRequestId });
        openPaywall();
      }
      setSkillExecutionStatus(undefined);
    }

    setWaitingForResponse(undefined);
  };

  const retrySkillById = (retryRequestId: string) => {
    if (!lastSkillPayload) return;
    if (lastSkillPayload.requestId !== retryRequestId) return;

    logger.info(`ACTION RETRY (RETRY SKILL BY REQUEST ID) - id ${retryRequestId}`);
    startSkillProcess({ ...lastSkillPayload, requestId: uuidV4() } as SkillsPayload);
  };

  const retryByMessageId = (messageId: string) => {
    const messageToRetry = messages.find((m) => m.id === messageId);
    if (!messageToRetry) return;

    logger.info(`ACTION RETRY (RETRY BY MESSAGE ID) - id ${messageId}`);
    sendMessage({ ...messageToRetry, id: uuidV4() });
  };

  const clearError = () => {
    setError("");
  };

  const [inputVisible, setInputVisible] = useState<boolean>(false);

  const clearChatAndStartNewSession = () => {
    if (waitingForResponse) return;

    createThread();

    setMessages([]);

    setCurrentFlow({
      ...initialChatFlow,
      message: "Seu histórico de interações foi limpo. Qual ação deseja começar?",
    });
  };

  const cancelOngoingExecution = async (executionId?: string) => {
    const idToCancel = executionId || waitingForResponse?.executionId;
    if (!idToCancel) return;

    try {
      const { status } = await cancelExecution({ executionId: idToCancel });

      switch (status) {
        case ExecutionStatus.SUCCESS:
          setWaitingForResponse(undefined);
          setLoadingState("FINISHED");
          setCurrentFlow({
            ...initialChatFlow,
            message: "Operação cancelada conforme solicitado. Qual ação deseja começar?",
          });
          toast.success(TOAST_MESSAGES.CANCELLED_SUCCESS);
          break;
        case ExecutionStatus.CONFLICT:
          toast.error(TOAST_MESSAGES.ALREADY_COMPLETED);
          setWaitingForResponse(undefined);
          setLoadingState("FINISHED");
          break;
        case ExecutionStatus.NOT_FOUND:
          toast.error(TOAST_MESSAGES.NOT_FOUND);
          setWaitingForResponse(undefined);
          setLoadingState("FINISHED");
          break;
        case ExecutionStatus.SERVER_ERROR:
          toast.error(TOAST_MESSAGES.ERROR_CANCELLING);
          break;
        default:
          toast.error(TOAST_MESSAGES.UNEXPECTED_ERROR);
      }
    } catch (e) {
      toast.error(TOAST_MESSAGES.RETRY_ERROR);
      if (e instanceof Error) {
        logger.error(`Error cancelOngoingExecution: ${e.message}`, e);
      }
    }
  };

  const handleEvaluation = async ({ messageId, newEvaluation, currentEvaluation }: HandleEvaluation) => {
    try {
      setMessages((prev) => {
        return prev.map((m) => {
          if (m.id === messageId) {
            return {
              ...m,
              evaluation: newEvaluation,
            };
          }
          return m;
        });
      });
      await evaluateMessage({ messageId, evaluation: newEvaluation });
    } catch (e) {
      setMessages((prev) => {
        return prev.map((m) => {
          if (m.id === messageId) {
            return {
              ...m,
              evaluation: currentEvaluation,
            };
          }
          return m;
        });
      });
      WebToast.error("Não foi possível avaliar a mensagem.");
    }
  };

  return (
    <MessagesContext.Provider
      value={{
        messages,
        error,
        clearError,
        skillExecutionStatus,
        loading,
        sendMessage,
        addMessage,
        actions,
        waitingForResponse,
        uploadingFiles,
        clearContext,
        inputContext,
        inputContextFormatted,
        clearChatAndStartNewSession,
        cancelOngoingExecution,
        connectionState: socket.connectionState,
        inputVisible,
        setInputVisible,
        startSkillProcess,
        refetchMessages,
        loadingState,
        totalResults,
        paginationModel,
        setPaginationModel,
        handleEvaluation,
        startSkill,
        documentCreationText,
      }}
    >
      <ReadOnlyAlert open={isDocumentReadOnlyOpen} onClose={() => setIsDocumentReadOnlyOpen(false)} />
      {children}
    </MessagesContext.Provider>
  );
};

const getWelcomeMessage = (): Message => {
  const welcomeMessage = (
    <>
      Olá! Bem-vindo ao Assistente Lexter.
      <br />
      Caso tenha dúvidas, acesse nossa{" "}
      <Link href={EXTERNAL_URLS.HELP_CENTER_URL} target="_blank" rel="noreferrer">
        Central de Ajuda
      </Link>{" "}
      ou entre em contato através do <Link href="mailto:meajuda@lexter.ai">meajuda@lexter.ai</Link>.
    </>
  );

  return {
    id: uuidV4(),
    type: "INITIAL",
    direction: "RECEIVED",
    author: "Lexter.ai",
    date: DateTime.now(),
    status: "READ",
    text: welcomeMessage,
    actions: [],
  };
};
