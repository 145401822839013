import { useMutation, useQueryClient } from "@tanstack/react-query";
import { THREADS_QUERY_KEY } from "@/hooks/threads/usePaginatedThreads";
import { ThreadService } from "@/services/thread";

export const useCreateThread = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["createThread"],
    mutationFn: async ({ threadName, caseId }: { threadName?: string; caseId?: string }) => {
      return ThreadService.createThread({ threadName, caseId });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: THREADS_QUERY_KEY });
    },
    onError: async () => {
      await queryClient.invalidateQueries({ queryKey: THREADS_QUERY_KEY });
    },
  });
};
