import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UpdateLexZapCompanyParams } from "@/hooks/useApi/types";
import { lexZapChannelsQueryKey } from "../useLexZapChannels";
import { LexZapChannel } from "@/services/lexZap/types";
import { AdminLexZapService } from "@/services/lexZap";

export const useUpdateLexZapCompany = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["updateLexZapCompany"],
    mutationFn: AdminLexZapService.updateLexZapCompany,
    onMutate: async ({ companyId, updates }: UpdateLexZapCompanyParams) => {
      const previousChannels = queryClient.getQueryData<LexZapChannel[]>(lexZapChannelsQueryKey);

      queryClient.setQueryData<LexZapChannel[]>(lexZapChannelsQueryKey, (old = []) =>
        old.map((channel) => {
          const adjUpdates: UpdateLexZapCompanyParams["updates"] = updates;

          if (updates.assistantName) {
            adjUpdates.assistantName = updates.assistantName.trim();
          }

          if (updates.companyName) {
            adjUpdates.companyName = updates.companyName.trim();
          }

          return channel.company?.companyId === companyId
            ? { ...channel, company: { ...channel.company, ...adjUpdates } }
            : channel;
        })
      );

      return { previousChannels };
    },
    onError: (_error, _updatedCompany, context) => {
      if (context?.previousChannels) {
        queryClient.setQueryData<LexZapChannel[]>(lexZapChannelsQueryKey, context.previousChannels);
      }
    },
  });
};
