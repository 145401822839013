import { SearchOutlined } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import React from "react";

interface SearchProps {
  label?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
}

export const Search = ({ label, placeholder, onChange }: SearchProps) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.value);
  };

  return (
    <TextField
      sx={{
        width: "100%",
        bgcolor: "common.white",
        height: "52px",

        borderRadius: "8px",
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
          "& fieldset": {
            borderColor: "common.lightShade",
          },
          "&:hover fieldset": {
            borderColor: "common.lightShade",
          },
          "&.Mui-focused fieldset": {
            borderColor: "common.lightShade",
          },
        },
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined sx={{ color: "common.mediumShade" }} />
          </InputAdornment>
        ),
      }}
      label={label}
      hiddenLabel
      variant="outlined"
      placeholder={placeholder}
      onChange={handleChange}
      size="medium"
    />
  );
};
