import { useCallback } from "react";
import { Config } from "@/config/Config";
import { logger } from "@/core/logger";
import { LoggedInUserData } from "@/contexts/AuthContext";
import { CloudHumansService } from "@/services/cloudHumans";

export const useCloudHumans = () => {
  const config = Config.getConfig();

  const loadCloudHumans = useCallback((user: LoggedInUserData) => {
    const isTheSameUser = window.helperChatUser && window.helperChatUser.email === user.userEmail;

    if (!isTheSameUser) {
      handleLoadCloudHumans(user);
    }
  }, []);

  const handleLoadCloudHumans = useCallback((user: LoggedInUserData) => {
    resetCloudHumans();
    try {
      if (config.isStaging || config.isProd) {
        const cloudHumansScript = document.createElement("script");
        cloudHumansScript.innerHTML = `
          var helperChatUser=helperChatUser||{};
          helperChatUser = {
          email : "${user.userEmail}"
          };
          (function(d,t) {
            var BASE_URL="https://cloudchat.cloudhumans.com";
            var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
            g.src=BASE_URL+"/packs/js/sdk.js";
            g.defer = true;
            g.async = true;
            g.id = "cloud_humans_head_script";
            s.parentNode.insertBefore(g,s);
            g.onload=function(){
              window.chatwootSDK.run({
                websiteToken: 'acZ6cJ36JmgtR8gwY9w3Xe3a',
                baseUrl: BASE_URL
              })
            }
            })(document,"script"); 
          `;
        cloudHumansScript.id = "cloud_humans_script";
        document.head.appendChild(cloudHumansScript);
      }
    } catch (e) {
      logger.debug(`Error loading Cloud Humans: ${JSON.stringify(e)}`);
    }
  }, []);

  const updateCloudHumansClientContact = useCallback(
    async ({
      userName,
      email,
      planType,
      phoneNumber,
      isActivePlan,
    }: {
      userName: string;
      email?: string;
      planType?: string;
      phoneNumber?: string;
      isActivePlan: boolean;
    }) => {
      try {
        if (!window.chatwootSDK && (config.isStaging || config.isProd)) {
          await CloudHumansService.updateClientContact({
            data: {
              userName,
              email,
              planType,
              phoneNumber,
              isActivePlan,
            },
          });
        }
      } catch (e) {
        logger.debug(`Error adding properties to Tawk.to: ${JSON.stringify(e)}`);
      }
    },
    []
  );

  const showWidget = useCallback(() => {
    try {
      if (window.chatwootSDK && window.chatwootSDK.showWidget) {
        window.chatwootSDK.showWidget();
      }
    } catch (e) {
      logger.debug(`Error showing CloudHumans widget: ${JSON.stringify(e)}`);
    }
  }, []);

  const resetCloudHumans = useCallback(() => {
    try {
      if (window.$chatwoot) {
        window.$chatwoot.reset();
      }
    } catch (e) {
      logger.error(`Error resetting CloudHumans: ${JSON.stringify(e)}`);
      return;
    }
  }, []);

  return { loadCloudHumans, updateCloudHumansClientContact, showWidget };
};
