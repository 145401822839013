import { CaseFileItem } from "@/core/CaseFile";
import { CaseService } from "@/services/case";
import { getFileExtensionByFileType } from "@/utils/getFileExtensionByFileType";
import { saveFileAs } from "@/utils/saveFileAs";
import { useMutation } from "@tanstack/react-query";

export const useDownloadCaseLegalProceedingsFiles = () => {
  const api = CaseService;

  return useMutation({
    mutationKey: ["downloadCaseLegalProceedingsFiles"],
    mutationFn: async ({ caseId, file }: { caseId: string; file: CaseFileItem }) => {
      return await api.getCaseFileUrl(caseId, file.id as string);
    },
    onSuccess: (data, { file }) => {
      const legalProceedingFile = file;

      const fileExtension = getFileExtensionByFileType(legalProceedingFile.extension!);
      saveFileAs(data.url, `${legalProceedingFile.name}.${fileExtension}`);
    },
  });
};
