import { LexterCopilotAuthService } from "@/services/lexterCopilotAuth";
import { useAuthStore } from "@/stores/useAuthStore";
import { useQuery } from "@tanstack/react-query";

export const useUserQuery = () => {
  const { token } = useAuthStore();

  return useQuery({
    queryKey: ["user"],
    queryFn: async () => await LexterCopilotAuthService.getUserData(),
    enabled: !!token,
  });
};
