import { SkillGroup } from "@/core/skillGroups/types";
import { CreditPerSkill } from "@/core/skills/types";

export const createNewDocumentSkillGroup: SkillGroup = {
  id: "create_new_document",
  name: "Escrever peça judicial",
  subtitle: "Selecione o tipo de peça:",
  credits: CreditPerSkill.ADVANCED_SKILL,
  skillShortcuts: [
    { label: "Petição inicial", skillId: "create_initial_petition" },
    { label: "Reclamação trabalhista", skillId: "create_labor_complaint", flag: "skillCreateLaborComplaintV1Enabled" },
    {
      label: "Reclamação trabalhista",
      skillId: "create_labor_complaint_v2",
      flag: "skillCreateLaborComplaintV2Enabled",
    },
    { label: "Contestação", skillId: "create_contestation", flag: "skillCreateDefenseV1Enabled" },
    { label: "Contestação", skillId: "create_contestation_v2", flag: "skillCreateDefenseV2Enabled" },
    { label: "Réplica", skillId: "create_reply", flag: "skillCreateReplyV1Enabled" },
    { label: "Réplica", skillId: "create_reply_v2", flag: "skillCreateReplyV2Enabled" },
    { label: "Petição intercorrente", skillId: "intercurrent_motion" },
    { label: "Apelação", skillId: "create_appeal" },
    { label: "Recurso ordinário trabalhista", skillId: "create_ordinary_appeal" },
    { label: "Outro tipo de peça", skillId: "create_other_type_of_piece", flag: "skillOtherTypeOfPieceV1Enabled" },
    { label: "Outro tipo de peça", skillId: "create_other_type_of_piece_v2", flag: "skillOtherTypeOfPieceV2Enabled" },
  ],
  tags: ["recommend", "litigation"],
  onboarding: {
    enabledSkills: ["create_initial_petition", "create_contestation", "create_appeal"],
  },
};
