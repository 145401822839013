import { useRecords } from "@/hooks/records/useRecords";
import { useLexZapMessagesMedia } from "../useLexZapMessagesMedia";
import { useMemo } from "react";
import { DateTime } from "luxon";
import { formatDateToISO } from "@/utils/formatDateToISO";
import { ThreadRecord, ThreadRecordWithThreadId } from "@/hooks/records/types";
import { useCaseLegalProceedingsFiles } from "@/hooks/legalProceedings/useCaseLegalProceedingsFiles";
import { CaseFileItem } from "@/core/CaseFile";
import { Paginated } from "@/core/api/types";

interface UseLexZapCaseFilesProps {
  caseId?: string;
  applicantPhoneNumber?: string;
  search?: string;
  disabled?: boolean;
  threadIds?: string[];
}
export const useCaseFiles = ({
  caseId,
  applicantPhoneNumber,
  search = "",
  disabled,
  threadIds,
}: UseLexZapCaseFilesProps) => {
  const {
    data: allRecords,
    isLoading: isLoadingRecords,
    error: errorRecords,
  } = useRecords({
    threadId: threadIds,
    order: "CREATED_AT",
  });

  const {
    data: messagesMedia,
    isLoading: isLoadingMessagesMedia,
    isError: isErrorMedia,
  } = useLexZapMessagesMedia({
    applicantPhoneNumber,
    disabled,
  });

  const { data: caseLegalProceedingsFiles, isLoading: isLoadingCaseLegalProceedingsFiles } =
    useCaseLegalProceedingsFiles({
      caseId,
    });

  const files: CaseFileItem[] = useMemo(() => {
    return messagesMedia
      ? messagesMedia.map((message) =>
          CaseFileItem.createMedia({
            name: message.media.filename,
            createdAt: message.timestamp,
            id: message.id,
            url: message.media.url,
            mimeType: message.media.mimeType,
          })
        )
      : [];
  }, [messagesMedia]);

  const records: CaseFileItem[] = useMemo(() => {
    function isRecordWithThreadId(input: ThreadRecord): input is ThreadRecordWithThreadId {
      return Boolean(input.threadId);
    }

    const recordsWithThreadId = Array.isArray(allRecords)
      ? allRecords
          .filter((r): r is Paginated<ThreadRecordWithThreadId> => r !== undefined)
          .flatMap((r) => r.data.filter(isRecordWithThreadId))
      : (allRecords?.data.filter(isRecordWithThreadId) ?? []);

    return recordsWithThreadId.map((record) =>
      CaseFileItem.createRecord({
        name: record.name,
        createdAt: DateTime.fromISO(formatDateToISO(record.createdAt)).toMillis(),
        id: record.id,
        recordType: record.type,
        threadId: record.threadId,
      })
    );
  }, [allRecords]);

  const legalProceedingFiles: CaseFileItem[] = useMemo(() => {
    return (
      caseLegalProceedingsFiles?.map((file) =>
        CaseFileItem.createLegalProceedingAttachedFile({
          name: file.name,
          createdAt: DateTime.fromISO(formatDateToISO(file.createdAt)).toMillis(),
          id: file.id,
          cnj: file.cnj,
          instance: file.instance,
          integration: file.integration,
          integrationFileId: file.integrationFileId,
          legalProceedingStepId: file.legalProceedingStepId,
          extension: file.extension,
        })
      ) || []
    );
  }, [caseLegalProceedingsFiles]);

  const caseFiles = useMemo(() => {
    return (
      [...records, ...files, ...legalProceedingFiles]
        .sort((a, b) => {
          return b.createdAt - a.createdAt;
        })
        .filter((item) => item.name.toLowerCase().includes(search.toLowerCase())) || []
    );
  }, [records, files, legalProceedingFiles, search]);

  return {
    data: caseFiles,
    isLoading: isLoadingRecords || isLoadingMessagesMedia || isLoadingCaseLegalProceedingsFiles,
    error: errorRecords || isErrorMedia,
  };
};
