import { AppBar, Box, IconButton, Theme, SxProps, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { HeaderWelcome } from "./HeaderWelcome";
import { HeaderGoBack } from "./HeaderGoBack";
import { HeaderEditor } from "./HeaderEditor";
import { PhoneIphone as PhoneIphoneIcon } from "@mui/icons-material";
import { useDocumentOnboarding } from "@/hooks/skills/useDocumentOnboarding";
import { HeaderVariant } from "./constants";
import { NumberManagementModal } from "@/components/LexZap/NumberManagementModal";
import { Notifications } from "@/components/Notifications";
import { useLexZapCompany } from "@/hooks/lexZap/useLexZapCompany";

interface HeaderProps {
  variant: HeaderVariant.WELCOME | HeaderVariant.GO_BACK | HeaderVariant.EDITOR;
  chatWidth?: number;
  sx?: SxProps<Theme>;
}

export const Header = ({ variant, chatWidth, sx }: HeaderProps) => {
  const theme = useTheme();
  const { isOnboarding } = useDocumentOnboarding();
  const [showNotifications, setShowNotifications] = useState(true);
  const [showNumberManagementModal, setShowNumberManagementModal] = useState(false);

  const { data: connectionData } = useLexZapCompany();

  useEffect(() => {
    if (isOnboarding) {
      setShowNotifications(false);
    } else {
      setShowNotifications(true);
    }
  }, [isOnboarding]);

  const openNumberManagementModal = () => {
    setShowNumberManagementModal(true);
  };

  const closeNumberManagementModal = () => {
    setShowNumberManagementModal(false);
  };

  let Content;
  switch (variant) {
    case HeaderVariant.WELCOME:
      Content = <HeaderWelcome />;
      break;
    case HeaderVariant.GO_BACK:
      Content = <HeaderGoBack />;
      break;
    case HeaderVariant.EDITOR:
    default:
      Content = <HeaderEditor chatWidth={chatWidth ?? 400} />;
  }

  return (
    <AppBar
      position="static"
      sx={{
        height: "56px",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 24px",
        borderBottom: "1px solid",
        borderColor: "common.lightShade",
        zIndex: 1,
        boxShadow: "none",
        color: "common.black",
        ...sx,
      }}
      className="app-bar-header-page-layout"
    >
      {Content}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "8px",
        }}
      >
        {connectionData?.phoneNumber && (
          <IconButton onClick={openNumberManagementModal}>
            <PhoneIphoneIcon style={{ color: theme.palette.common.black }} />
          </IconButton>
        )}

        {showNotifications && <Notifications />}
      </Box>

      {connectionData?.phoneNumber && (
        <NumberManagementModal
          onClose={closeNumberManagementModal}
          open={showNumberManagementModal}
          connectionData={connectionData}
        />
      )}
    </AppBar>
  );
};
