import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Note } from "@/services/note/types";
import { useCompanyMember } from "@/hooks/company/useCompanyMember";
import { DateTime } from "luxon";
import { WebToast } from "@/components/core/Toast";
import { logger } from "@/core/logger";
import { useArchiveNote } from "@/hooks/notes/useArchiveNote";
import { EditNote } from "../EditNote";

export const NoteItem = ({ note, ticketId }: { note: Note; ticketId: string }) => {
  const { data: user } = useCompanyMember({ memberId: note.createdByUserId });
  const { mutateAsync: archiveNote } = useArchiveNote();
  const [isEditing, setIsEditing] = useState(false);

  const handleDeleteNote = async () => {
    if (!note) return;

    try {
      await archiveNote({
        noteId: note.id,
        applicantId: note.applicantId,
        caseId: note.caseId,
        ticketId: note.ticketId,
      });
      WebToast.success("Anotação deletada com sucesso");
    } catch (error) {
      WebToast.error("Erro ao deletar anotação");
      logger.error("Erro ao deletar anotação", error);
    }
  };

  if (isEditing) {
    return <EditNote note={note} onClose={() => setIsEditing(false)} ticketId={ticketId} />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 1,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="bold"
            sx={{
              color: "text.secondary",
            }}
          >
            {user?.name || note.createdByUserId}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontSize: "12px",
              color: "text.secondary",
            }}
          >
            {DateTime.fromJSDate(new Date(note.createdAt)).toFormat("dd/MM/yyyy")}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          <Button variant="text" onClick={handleDeleteNote}>
            Remover
          </Button>
          <Button variant="text" onClick={() => setIsEditing(true)}>
            Editar
          </Button>
        </Box>
      </Box>

      <Typography variant="body1">{note.content}</Typography>
    </Box>
  );
};
