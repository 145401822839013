import React from "react";
import { Autocomplete, Box, Card, CardContent, TextField, Typography } from "@mui/material";
import { PageLayout } from "@/components/PageLayout";
import { useCredits } from "@/hooks/credits/useCredits";
import { PlanType } from "@/hooks/credits/types";
import { useCompanyTeam } from "@/hooks/company/useCompanyTeam";

// type CreateCaseProps = {};

export const CreateCase = () => {
  const { data: credits } = useCredits();
  const { data: companyTeam } = useCompanyTeam();
  const isEssential = credits?.companyPlan?.plan === PlanType.UNLIMITED;
  const lawyers = companyTeam?.map((member) => member.email);

  return (
    <PageLayout
      contentProps={{
        fullWidth: true,
      }}
      sx={{
        "& .page-layout-container": {
          padding: 5,
        },
      }}
      variant="secondary"
    >
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2, width: "100%" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
          <BoxContent title="Detalhes do caso" optional={false}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
              <TextField label="Nome do caso" placeholder="Caso do João" type="text" fullWidth />
              <TextField
                label="Cliente(s)"
                placeholder="João da Silva"
                type="text"
                helperText="Separe por vírgula para múltiplos"
                fullWidth
              />
              <Autocomplete
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputLabelProps={{ ...params.InputLabelProps, shrink: true }}
                    type="text"
                    label="Advogado responsável"
                    placeholder="Digite ou selecione o nome do advogado responsável por este caso"
                  />
                )}
                fullWidth
                options={lawyers ?? []}
              />
              <TextField
                label="Descrição do caso"
                placeholder="Digite aqui a descrição do caso"
                type="text"
                fullWidth
                multiline
                rows={4}
              />
            </Box>
          </BoxContent>
          <BoxContent title="Documentos e anexos" optional={true}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              alguma coisa
            </Typography>
          </BoxContent>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2, width: "100%" }}>
          <BoxContent title="Vincular processo" optional={true}>
            <Typography variant="body1" sx={{ mb: 2 }}>
              alguma coisa
            </Typography>
          </BoxContent>
          {!isEssential && (
            <BoxContent title="Vincular atendimento" optional={true}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                alguma coisa
              </Typography>
            </BoxContent>
          )}
        </Box>
      </Box>
    </PageLayout>
  );
};

type BoxContentProps = {
  children: React.ReactNode;
  optional?: boolean;
  title?: string;
};

const BoxContent = ({ children, optional, title }: BoxContentProps) => {
  return (
    <Card sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <CardContent sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", mb: 4 }}>
          <Typography variant="h5">{title}</Typography>
          {optional && (
            <Typography
              variant="body2"
              sx={{
                bgcolor: "grey.100",
                borderRadius: "100px",
                px: 1.5,
                py: 0.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Campo opcional
            </Typography>
          )}
        </Box>
        {children}
      </CardContent>
    </Card>
  );
};
