import { authenticatedCopilotServer } from "@/core/api";
import { Note, NotePayload } from "@/services/note/types";

export const NoteService = {
  async create({ data }: { data: NotePayload }) {
    const response = await authenticatedCopilotServer.post<{ id: string }>(`/notes`, data);
    return response.data;
  },

  async get(params: { applicantId?: string | null; caseId?: string | null; ticketId?: string | null }) {
    const response = await authenticatedCopilotServer.get<Note[]>(`/notes`, {
      params,
    });
    return response.data;
  },

  async update({ noteId, data }: { noteId: string; data: { content: string } }) {
    const response = await authenticatedCopilotServer.post<{ id: string }>(`/notes/${noteId}`, data);
    return response.data;
  },

  async archive({ noteId }: { noteId: string }) {
    const response = await authenticatedCopilotServer.put(`/notes/${noteId}/archive`);
    return response.data;
  },
};
