import * as React from "react";

import "@/styles/editor.css";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { env } from "@/constants/environment";
import { AuthProvider } from "@/contexts/AuthContext";
import { AlertModalProvider } from "@/hooks/useAlertModal/provider";
import { Toaster } from "react-hot-toast";
import { IndexRoutes } from "@/routes";
import { theme } from "@/theme";
import { loadSessionSettings } from "@/utils/loadSessionSettings";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Box } from "@mui/material";
import { ErrorBoundary } from "@datadog/browser-rum-react";
import { ErrorFallback } from "@/components/ErrorFallback";
import { queryClient } from "@/utils/queryClient";

export const App = () => {
  React.useEffect(() => {
    if (env.IS_PRODUCTION) {
      const googleAnalyticsId = "G-Z63RC3GPBL";
      const microsoftClarityId = "mfzhvrfjx6";
      const hotjarId = "4993008";
      const heapId = "1175471236";

      // Google Analytics
      const gaScript = document.createElement("script");
      gaScript.async = true;
      gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
      document.head.appendChild(gaScript);

      const gaScriptInit = document.createElement("script");
      gaScriptInit.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${googleAnalyticsId}');
      `;
      document.head.appendChild(gaScriptInit);

      // Google Tag
      const gtagScript = document.createElement("script");
      gtagScript.async = true;
      gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=AW-16462471594`;
      document.head.appendChild(gtagScript);

      const gtagScriptInit = document.createElement("script");
      gtagScriptInit.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'AW-16462471594');
      `;
      document.head.appendChild(gtagScriptInit);

      // Microsoft Clarity
      const clarityScript = document.createElement("script");
      clarityScript.innerHTML = `
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r),t.async=1,t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            window.clarity=clarity;
        })(window, document, "clarity", "script", "${microsoftClarityId}");
      `;
      document.head.appendChild(clarityScript);

      // Hotjar
      const hotjarScript = document.createElement("script");
      hotjarScript.innerHTML = `
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:"${hotjarId}",hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
      `;
      document.head.appendChild(hotjarScript);

      // Heap
      const heapScript = document.createElement("script");
      heapScript.type = "text/javascript";
      heapScript.async = true;
      heapScript.innerHTML = `
        window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
        heap.load("${heapId}");
      `;
      document.head.appendChild(heapScript);

      // Meta Pixel
      const metaPixelScript = document.createElement("script");
      metaPixelScript.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1084439335960900');
        fbq('track', 'PageView');
      `;
      document.head.appendChild(metaPixelScript);

      // HubSpot
      const hubSpotScript = document.createElement("script");
      hubSpotScript.type = "text/javascript";
      hubSpotScript.async = true;
      hubSpotScript.defer = true;
      hubSpotScript.src = "//js.hs-scripts.com/8374664.js";
      document.head.appendChild(hubSpotScript);
    }
    if (env.IS_STAGING) {
      const heapId = "491410910";

      // Heap
      const heapScript = document.createElement("script");
      heapScript.type = "text/javascript";
      heapScript.async = true;
      heapScript.innerHTML = `
        window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
        heap.load("${heapId}");
      `;
      document.head.appendChild(heapScript);
    }

    loadSessionSettings();
  }, []);

  return (
    <ErrorBoundary fallback={ErrorFallback}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <AlertModalProvider>
              <IndexRoutes />
            </AlertModalProvider>
          </ThemeProvider>
        </AuthProvider>

        {env.IS_DEV && (
          <Box
            sx={{
              position: "fixed",
              bottom: "80px",
              right: "12px",
              zIndex: 9999,
            }}
          >
            <ReactQueryDevtools buttonPosition="relative" />
          </Box>
        )}
      </QueryClientProvider>

      <CssBaseline />
      <Toaster position="bottom-left" containerStyle={{ zIndex: "99999" }} />
    </ErrorBoundary>
  );
};
