import { PageLayout } from "@/components/PageLayout";
import React, { useMemo, useState } from "react";
import { ChatView } from "./components/ChatView";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useImpersonation } from "@/hooks/useImpersonation";
import { useLexZapChats } from "@/hooks/lexZap/useLexZapChats";
import { WhatsappPageHeader } from "./components/WhatsappPageHeader";
import { ScrollableContainer } from "./components/ScrollableContainer";

export const WhatsappAssistantPage = () => {
  const { data: chatsData, isLoading: isLoadingChats, isFetching: isFetchingChats } = useLexZapChats();

  const allChats = useMemo(() => {
    if (!chatsData) return [];

    return chatsData.sort((a, b) => (b.lastMessageDate || 0) - (a.lastMessageDate || 0));
  }, [chatsData]);

  const { impersonatedUser } = useImpersonation();

  const [isHeaderVisible, setIsHeaderVisible] = useState(true);
  const closeHeader = () => setIsHeaderVisible(false);
  const openHeader = () => setIsHeaderVisible(true);

  return (
    <PageLayout
      contentProps={{
        disablePadding: true,
        fullWidth: true,
      }}
      contentSx={{
        height: "100%",
      }}
      headerProps={{
        extraContent: impersonatedUser ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              paddingX: 4,
              paddingY: 2,
              backgroundColor: "info.light",
            }}
          >
            <Box>
              <Typography variant="pageTitle">{impersonatedUser.companyName || "Outra company"}</Typography>
              <Typography variant="body" color="text.primary" sx={{ fontSize: "16px" }}>
                ID: {impersonatedUser.companyId}
                {!!impersonatedUser.companyEmail && (
                  <>
                    <br />
                    Email: {impersonatedUser.companyEmail}
                  </>
                )}
                {!!impersonatedUser.whatsappNumber && (
                  <>
                    <br />
                    Whatsapp: {impersonatedUser.whatsappNumber}
                  </>
                )}
              </Typography>
            </Box>

            {isFetchingChats && (
              <CircularProgress size={20} sx={{ marginLeft: 1, color: "text.primary" }} thickness={5} />
            )}
          </Box>
        ) : undefined,
      }}
      variant="secondary"
    >
      <ScrollableContainer onScrollDown={closeHeader} onScrollUp={openHeader}>
        <Box
          sx={{
            height: "100%",
            display: "grid",
            gridTemplateRows: "min-content minmax(0, 1fr)",
            overflow: "visible",
          }}
        >
          <WhatsappPageHeader isVisible={isHeaderVisible} onScrollDown={closeHeader} />

          <ChatView chats={allChats} isLoadingChats={isLoadingChats} />
        </Box>
      </ScrollableContainer>
    </PageLayout>
  );
};
