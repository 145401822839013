import { PlanType } from "./types";

export const planTitles: Record<PlanType, string[]> = {
  [PlanType.TRIAL]: ["Teste gratuito", "Período de teste"],
  [PlanType.FREEMIUM]: ["Teste gratuito"],
  [PlanType.INITIAL]: ["Inicial"],
  [PlanType.BASIC]: ["Básico"],
  [PlanType.INTERMEDIARY]: ["Intermediário"],
  [PlanType.ADVANCED]: ["Avançado"],
  [PlanType.PROFESSIONAL]: ["Profissional"],
  [PlanType.CUSTOM]: ["Profissional"],
  [PlanType.UNLIMITED]: ["Essencial"],
  [PlanType.PROFESSIONAL_WITH_WPP]: ["Profissional"],
  [PlanType.PROFESSIONAL_CUSTOM]: ["Profissional"],
  [PlanType.PERFORMANCE]: ["Performance"],
};
