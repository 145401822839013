import * as React from "react";
import { Dispatch, SetStateAction, useState } from "react";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";

import { Footer } from "./Footer";
import { Input } from "./Input";
import { LoadingChat } from "./LoadingChat";
import { LoadingMessage, MessageCard } from "./MessageCard";

import { Loading } from "@/components/Loading";
import {
  Action,
  ErrorMessage,
  HandleEvaluation,
  Message,
  WaitingForResponse,
  useMessagesContext,
} from "@/contexts/MessagesContext";
import { Threads } from "@/components/Threads";
import { ConnectionState, SkillsPayload } from "@/contexts/WebSocketContext";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { SkillForm } from "./SkillForm";
import { useSkill } from "@/hooks/skills/useSkill";
import { useSkillGroup } from "@/hooks/skills/useSkillGroup";
import { SkillGroupView } from "@//components/Chat/SkillGroupView";
import { useThreadContext } from "@/contexts/ThreadContext";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { SuccessPage } from "./SuccessPage";
import { RunningSkill } from "./RunningSkill";
import { EditDocumentSection } from "@/pages/EditDocumentSection";
import { PaginationPayload } from "@/core/api/types";
import { PrecedentSearch } from "@/pages/PrecedentSearch";
import { Skill, SkillId } from "@/core/skills/types";
import { logger } from "@/core/logger";
import { getSkillPath, ROUTE_PATHS } from "@/routes/routePaths";
import { SuccessModal } from "./SuccessModal";

const MAX_SCROLL_POSITION = 24;

export type CustomSkillFormProps = {
  skill: Skill;
  goBack: () => void;
  startSkillProcess: (payload: SkillsPayload) => void;
};

const CustomSkillFormMap: Map<SkillId, React.FC<CustomSkillFormProps>> = new Map([
  ["search_precedent", PrecedentSearch],
  ["edit_document", EditDocumentSection],
]);

export function Chat() {
  const { threadId } = useParams<{ threadId: string }>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const skillId = searchParams.get("skill");
  const skillGroupId = searchParams.get("skillGroup");
  const isOnboarding = searchParams.get("isOnboarding");
  const { data: skill } = useSkill(skillId ?? undefined);
  const { data: skillGroup } = useSkillGroup(skillGroupId ?? undefined);
  const { successPageAfterCreationOfPiece } = useFeatureFlags();
  const goBack = () => navigate(-1);
  const [isSuccessPageOpen, setIsSuccessPageOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const {
    messages,
    error,
    skillExecutionStatus,
    clearError,
    loading,
    sendMessage,
    startSkillProcess,
    addMessage,
    actions,
    waitingForResponse,
    uploadingFiles,
    clearContext,
    inputContext,
    loadingState,
    refetchMessages,
    totalResults,
    paginationModel,
    setPaginationModel,
    handleEvaluation,
    startSkill,
  } = useMessagesContext();

  const { currentThreadId, threadConnectionState, openThread, closeCurrentThread } = useThreadContext();

  const closeCurrentSkill = () => {
    searchParams.delete("skill");
  };

  const closeSuccess = () => {
    setIsSuccessPageOpen(false);
    setIsSuccessModalOpen(false);
  };

  const goToChat = () => {
    closeCurrentSkill();
    if (!location.pathname.includes("chat")) {
      navigate(ROUTE_PATHS.HOME);
    }
  };

  useEffect(() => {
    if (skillExecutionStatus === "SUCCESS" && successPageAfterCreationOfPiece) {
      setIsSuccessPageOpen(true);
    } else if (
      currentThreadId &&
      currentThreadId === threadId &&
      skillExecutionStatus === "SUCCESS_WITHOUT_DOCUMENT" &&
      successPageAfterCreationOfPiece
    ) {
      setIsSuccessModalOpen(true);
    }
  }, [skillExecutionStatus, successPageAfterCreationOfPiece]);

  useEffect(() => {
    if (threadConnectionState === ConnectionState.OPEN && currentThreadId && skill) {
      void startSkill({ skillId: skill.id, actionId: skill.actionId });
      return () => closeCurrentSkill();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadConnectionState, currentThreadId, skill]);

  useEffect(() => {
    if (threadId) {
      openThread({ threadId });
      return () => {
        closeSuccess();
        closeCurrentThread();
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threadId]);

  useEffect(() => {
    if (currentThreadId) {
      return () => {
        closeSuccess();
        closeCurrentThread();
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentThreadId]);

  if (threadId && skillGroup) {
    return (
      <>
        <SkillGroupView
          skillGroup={skillGroup}
          onSelectSkill={({ skillId, customTitle, defaultData }) => {
            const url = getSkillPath({
              skillId,
              threadId,
              customTitle,
              isOnboarding: JSON.parse(isOnboarding ?? "false") || undefined,
            });
            navigate(url, {
              state: defaultData,
            });
          }}
          goBack={goBack}
        />
        {isSuccessModalOpen && <SuccessModal onClose={closeSuccess} threadId={threadId} />}
      </>
    );
  }

  if (skillId && skill?.customForm) {
    const CustomSkillForm = CustomSkillFormMap.get(skill.id);
    if (CustomSkillForm) {
      return (
        <>
          <CustomSkillForm skill={skill} goBack={goBack} startSkillProcess={startSkillProcess} />
          {isSuccessModalOpen && <SuccessModal onClose={closeSuccess} threadId={threadId} />}
        </>
      );
    }
  }

  if (skillId && threadId) {
    return (
      <>
        <SkillForm
          threadId={threadId}
          skillId={skillId}
          goBack={goBack}
          goToChat={goToChat}
          sendMessage={sendMessage}
          startSkillProcess={startSkillProcess}
        />
        {isSuccessModalOpen && <SuccessModal onClose={closeSuccess} threadId={threadId} />}
      </>
    );
  }

  if (
    (skillExecutionStatus === "RUNNING" && successPageAfterCreationOfPiece) ||
    (waitingForResponse && successPageAfterCreationOfPiece)
  ) {
    return <RunningSkill />;
  }

  if (isSuccessPageOpen) {
    return <SuccessPage onClose={closeSuccess} threadId={threadId} />;
  }

  if (loading) {
    return (
      <Box sx={{ width: "100%", height: "100%" }}>
        <LoadingChat />
      </Box>
    );
  }

  if (!threadId) {
    logger.error("Not have Thread ID in a chat");
    return null;
  }

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <ChatInternal
        sendMessage={sendMessage}
        messages={messages}
        inputContext={inputContext}
        clearContext={clearContext}
        onError={addMessage}
        uploadingFiles={uploadingFiles}
        waitingForResponse={waitingForResponse}
        isLoading={loading}
        actions={actions}
        refetchMessages={refetchMessages}
        isRefetching={loadingState === "REFETCHING"}
        threadId={threadId}
        totalResults={totalResults}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        handleEvaluation={handleEvaluation}
      />
      <Snackbar open={!!error} autoHideDuration={6000} onClose={clearError}>
        <Alert onClose={clearError} severity="error" sx={{ width: "100%" }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
}

enum Tab {
  CHAT,
  THREADS,
}

function ChatInternal({
  sendMessage,
  messages,
  inputContext,
  clearContext,
  onError,
  uploadingFiles,
  waitingForResponse,
  isLoading,
  actions,
  refetchMessages,
  isRefetching,
  threadId,
  totalResults,
  paginationModel,
  setPaginationModel,
  handleEvaluation,
}: {
  sendMessage: (message: Message) => unknown;
  messages: Message[];
  inputContext: string;
  clearContext: () => unknown;
  onError: (message: ErrorMessage) => unknown;
  uploadingFiles: { [fileId: string]: number };
  waitingForResponse?: WaitingForResponse;
  isLoading?: boolean;
  actions: Action[];
  refetchMessages: ({ threadId, pagination }: { threadId: string; pagination: PaginationPayload }) => Promise<void>;
  isRefetching: boolean;
  threadId: string;
  totalResults: number;
  paginationModel: PaginationPayload;
  setPaginationModel: Dispatch<SetStateAction<PaginationPayload>>;
  handleEvaluation: (props: HandleEvaluation) => Promise<void>;
}) {
  const [activeTab, setActiveTab] = React.useState(Tab.CHAT);
  const getChatList = () => {
    return document.getElementById("ChatInternal");
  };

  useEffect(() => {
    const chatList = getChatList();
    if (chatList) {
      chatList.scrollTop = chatList.scrollHeight - chatList.offsetHeight;
    }
  }, []);

  const onScroll = async () => {
    const chatList = getChatList();

    if (!chatList) return;

    const scrollPosition = chatList?.scrollTop;

    if (
      scrollPosition < MAX_SCROLL_POSITION &&
      !isLoading &&
      !isRefetching &&
      threadId &&
      messages.length <= totalResults
    ) {
      const firstMessage = document.getElementById(messages[0].id);

      await refetchMessages({
        pagination: {
          page: paginationModel.page + 1,
          pageSize: paginationModel.pageSize,
        },
        threadId,
      });

      firstMessage?.scrollIntoView({ block: "start", behavior: "instant" });

      setPaginationModel((prev) => ({ ...prev, page: prev.page + 1 }));
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{
        display: "grid",
        gridTemplateRows: "minmax(0, 1fr)",
        gridTemplateColumns: "minmax(0, 1fr)",
        height: "100%",
      }}
    >
      {activeTab === Tab.CHAT ? (
        <Stack sx={{ height: "100%" }}>
          <Messages
            messages={messages}
            uploadingFiles={uploadingFiles}
            waitingForResponse={waitingForResponse}
            isLoading={isLoading}
            onScroll={onScroll}
            isRefetching={isRefetching}
            handleEvaluation={handleEvaluation}
          />
          <Divider />
          <Input
            sendMessage={sendMessage}
            context={inputContext}
            clearContext={clearContext}
            onError={onError}
            loading={!!waitingForResponse}
            actions={actions}
          />
          <Footer loading={!!waitingForResponse} threadId={threadId} />
        </Stack>
      ) : (
        <Stack sx={{ height: "100%", display: "flex", alignItems: "center" }}>
          <Threads onClose={() => setActiveTab(Tab.CHAT)} component="SCREEN" />
        </Stack>
      )}
    </Paper>
  );
}

function Messages({
  messages,
  uploadingFiles,
  waitingForResponse,
  isLoading,
  onScroll,
  isRefetching,
  handleEvaluation,
}: {
  messages: Message[];
  uploadingFiles: { [fileId: string]: number };
  waitingForResponse?: WaitingForResponse;
  isLoading?: boolean;
  onScroll: () => void;
  isRefetching: boolean;
  handleEvaluation: (props: HandleEvaluation) => Promise<void>;
}) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        pl: 2,
        pr: 2,
        pt: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        overflowY: "auto",
      }}
      id="ChatInternal"
      onScroll={onScroll}
    >
      <Box
        sx={{
          height: "36px",
        }}
      >
        <Loading isLoading={isRefetching}>
          {
            <Typography variant="body2" color="textSecondary">
              Início da conversa
            </Typography>
          }
        </Loading>
      </Box>
      {messages.map((msg, index) => (
        <MessageCard
          key={msg.id}
          message={msg}
          uploadingFiles={uploadingFiles}
          isLastMessage={index + 1 === messages.length}
          isLoading={isLoading}
          handleEvaluation={handleEvaluation}
        />
      ))}
      {waitingForResponse && <LoadingMessage waitingForResponse={waitingForResponse} />}
    </Box>
  );
}
