import { buildSkillStep } from "@/core/skillForms/builder";

export const createReplySkillStep3 = buildSkillStep({
  title: "Detalhes do caso",
  description: "Preencha a lista abaixo para fornecer o contexto do seu caso à IA:",
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Nome do cliente",
        description: "Insira o nome completo do cliente, conforme registrado nos documentos oficiais do processo:",
        placeholder: "Maria da Silva, conforme registrado no CPF 321.654.987-00",
      },
    },
    {
      name: "case_breakdown",
      type: "string",
      ui: {
        label: "Descrição dos fatos",
        description: "Descreva os eventos do ponto de vista do cliente, esclarecendo os aspectos contestados.",
        placeholder:
          "O réu não cumpriu a obrigação contratual de entrega do imóvel na data acordada, conforme previsto no contrato assinado em 15/03/2022.",
      },
    },
    {
      name: "theses",
      type: "string",
      required: false,
      ui: {
        label: "Argumentos jurídicos",
        description:
          "Apresente os argumentos jurídicos que justificam a réplica, citando leis, normas ou jurisprudências favoráveis.",
        placeholder:
          "A parte lesada pelo inadimplemento parcial pode pedir a resolução do contrato e indenização pelos danos sofridos, em virtude do CC.",
      },
    },
    {
      name: "evidence_text",
      type: "string",
      required: false,
      ui: {
        label: "Descrever provas",
        description:
          "Descreva as provas que sustentam os argumentos apresentados na réplica, como registros, contratos ou testemunhos.",
        placeholder:
          "Anexo cópia do contrato datado de 15/03/2022 e comprovantes de e-mails demonstrando a negativa de entrega do imóvel.",
      },
    },
    {
      name: "requests",
      type: "string",
      required: false,
      ui: {
        label: "Solicitar pedidos",
        description:
          "Especifique os pedidos que está reiterando ou adicionando na réplica, detalhando as providências solicitadas.",
        placeholder:
          "Reitero o pedido de rescisão contratual com devolução dos valores pagos e indenização por perdas e danos.",
      },
    },
  ],
});
