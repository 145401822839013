import React, { useEffect, useRef, useState } from "react";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ActionButton } from "./ActionComponents/ActionButton";
import { SelectSkill } from "../SelectSkill";

export function Footer(props: { loading: boolean; threadId: string }) {
  const { loading } = props;

  const [openActionsMenu, setOpenActionsMenu] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const handleClickActionsMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (buttonRef.current && buttonRef.current.contains(event.target as Node)) {
      setOpenActionsMenu((prev) => !prev);
    }
  };

  const handleCloseActionsMenu = () => {
    setOpenActionsMenu(false);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        handleCloseActionsMenu();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  return (
    <>
      {openActionsMenu && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          pt: 1,
          pb: "10px",
          pl: 3,
          pr: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <WarningAmberRoundedIcon sx={{ color: "text.secondary" }} />

          <Typography
            variant="multiLineBody"
            color={"text.primary"}
            sx={{ ml: 1, color: "text.secondary", fontSize: "10px" }}
          >
            Conteúdos gerados por IA podem conter dados incorretos.
          </Typography>
        </Box>

        <ActionButton
          id="button_new_action"
          isOpen={openActionsMenu}
          disabled={loading}
          onClick={handleClickActionsMenu}
          buttonRef={buttonRef}
        />
        <SelectSkill isOpen={openActionsMenu} onClose={() => setOpenActionsMenu(false)} threadId={props.threadId} />
      </Box>
    </>
  );
}
