import React from "react";
import { Typography, Box } from "@mui/material";
import { Carousel } from "@/components/Carousel";
import PieceGeneratedIllustration from "@/assets/svgs/piece-generated.svg?react";
import NotificationsIllustration from "@/assets/svgs/notificacoes.svg?react";
import ChatWithLiaIllustration from "@/assets/svgs/conversa-cliente.svg?react";
import CaseUpdatedIllustration from "@/assets/svgs/case-updated.svg?react";

interface Slide {
  title: string;
  subtitle: string;
  image: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export const CarouselContent: React.FC = () => {
  const slides: Slide[] = [
    {
      image: ChatWithLiaIllustration,
      subtitle:
        "Atendimento ao cliente, detalhamento do caso até a venda dos serviços do seu escritório, feito pela Lia, nossa especialista de relacionamento virtual.",
      title: "Assistente no WhatsApp",
    },
    {
      image: PieceGeneratedIllustration,
      subtitle:
        "Com a ajuda da Laura, nossa especialista jurídica virtual, você pode realizar diversas tarefas com o auxílio de Inteligência Artificial e focar seu tempo em demandas mais estratégicas.",
      title: "Tarefas jurídicas com Inteligência Artificial",
    },
    {
      image: NotificationsIllustration,
      subtitle:
        "Faça entrevistas, coletas de informações relevantes de novos contatos e até resumos do caso com ajuda da Lia e tenha leads qualificados para o seu escritório.",
      title: "Vendas e relacionamento pelo WhatsApp",
    },
    {
      image: CaseUpdatedIllustration,
      subtitle:
        "Deixe a Laura monitorar seus processos e gerencie notificações para você e seus clientes de forma rápida e prática.",
      title: "Monitoramento de processos",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "320px" }}>
        <Carousel autoPlay loop interval={10000}>
          {slides.map(({ image: Image, title, subtitle }, idx) => (
            <Box
              key={idx}
              sx={{
                minHeight: "100px",
                textAlign: "center",
              }}
            >
              <Image height="260px" />
              <Box sx={{ mb: "24px" }} />
              <Typography variant="h6" textTransform="none" mb={3}>
                {title}
              </Typography>
              <Typography variant="body1" mb={3}>
                {subtitle}
              </Typography>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};
