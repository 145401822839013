import { CreateCaseModal } from "@/components/Cases/createCaseModal";
import { CreateOrAttachThread } from "@/components/Cases/createOrAttachThread";
import { Modal } from "@/components/Modal";
import React, { useState } from "react";

export const SuccessModal = ({ onClose, threadId }: { onClose: () => void; threadId?: string }) => {
  const [isCreateCaseModalOpen, setIsCreateCaseModalOpen] = useState(false);
  const [isCaseCreated, setIsCaseCreated] = useState(false);

  const closeCreateCaseModal = () => {
    setIsCreateCaseModalOpen(false);
  };

  const confirmCreateCaseModal = () => {
    setIsCreateCaseModalOpen(false);
    setIsCaseCreated(true);
  };

  return (
    <Modal open={true} onClose={onClose} maxWidth={600} closeButton={false}>
      {threadId && (
        <>
          <CreateOrAttachThread
            onCreateNewCase={() => setIsCreateCaseModalOpen(true)}
            threadId={threadId}
            onClose={onClose}
            isCaseCreated={isCaseCreated}
            isModal={true}
          />
          {isCreateCaseModalOpen && (
            <CreateCaseModal onClose={closeCreateCaseModal} onConfirm={confirmCreateCaseModal} threadId={threadId} />
          )}
        </>
      )}
    </Modal>
  );
};
