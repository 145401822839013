import { useMutation, useQueryClient } from "@tanstack/react-query";
import { lexZapChannelsQueryKey } from "../useLexZapChannels";
import { AdminLexZapService } from "@/services/lexZap";

export const useUpdateLexZapChannel = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: AdminLexZapService.updateLexZapChannel,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: lexZapChannelsQueryKey });
    },
  });
};
