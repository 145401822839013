import { useMutation, useQueryClient } from "@tanstack/react-query";
import { LexZapService } from "@/services/lexZap";
import { getChatsQueryKey } from "../useLexZapChats";
import { LexZapChat } from "../types";

export const useMarkChatAsAnswered = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ["markChatAsAnswered"],
    mutationFn: LexZapService.markChatAsAnswered,
    onMutate: ({ applicantPhoneNumber }) => {
      let previousUnansweredMessagesCount: number | undefined = undefined;

      queryClient.setQueryData<LexZapChat[]>(getChatsQueryKey(), (oldChats = []) =>
        oldChats.map((chat) => {
          if (chat.endClientNumber !== applicantPhoneNumber) return chat;

          previousUnansweredMessagesCount = chat.unansweredMessagesCount;
          return {
            ...chat,
            unansweredMessagesCount: 0,
          };
        })
      );

      return { previousUnansweredMessagesCount };
    },
    onError: (_error, { applicantPhoneNumber }, context) => {
      if (context?.previousUnansweredMessagesCount) {
        queryClient.setQueryData<LexZapChat[]>(getChatsQueryKey(), (oldChats = []) =>
          oldChats.map((chat) => {
            if (chat.endClientNumber !== applicantPhoneNumber) return chat;

            return {
              ...chat,
              unansweredMessagesCount: context.previousUnansweredMessagesCount,
            };
          })
        );
      }
    },
  });
};
