import { alpha, Box, Skeleton, useTheme } from "@mui/material";
import React, { useMemo } from "react";

interface ChatPropsSkeleton {
  isActive: boolean;
}

export const ChatItemSkeleton = ({ isActive }: ChatPropsSkeleton) => {
  const textWidth = useMemo(() => Math.floor(100 + Math.random() * 150), []);
  const nameWidth = useMemo(() => Math.floor(100 + Math.random() * 50), []);
  const theme = useTheme();
  const bgColor = theme.palette.grey["50"];

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "min-content minmax(0, 1fr) min-content",
        gap: 1,
        backgroundColor: isActive ? bgColor : undefined,
        paddingX: 1.5,
        paddingY: 1,
        borderRadius: "8px",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: bgColor ? alpha(bgColor, 0.4) : undefined,
        },
        "&:active": {
          backgroundColor: bgColor ? alpha(bgColor, 0.7) : undefined,
        },
      }}
    >
      <Box />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Skeleton variant="text" width={nameWidth} />

          <Skeleton variant="text" width={80} />
        </Box>

        <Skeleton variant="text" width={textWidth} />
      </Box>

      <Box>
        <Skeleton variant="text" width={40} />
      </Box>
    </Box>
  );
};
