import React from "react";
import { Box, Collapse, Typography } from "@mui/material";
import { Button } from "@/components/Button";
import { useScrollHandler } from "@/hooks/useScrollHandler";
import { ApplicantsList } from "@/components/Applicants/ApplicantsList";

interface WhatsappPageHeaderProps {
  isVisible: boolean;
  onScrollDown: () => void;
}

export const WhatsappPageHeader = ({ isVisible, onScrollDown }: WhatsappPageHeaderProps) => {
  const [isApplicantListOpen, setIsApplicantListOpen] = React.useState(false);

  const { ref: containerRef } = useScrollHandler({
    onScrollDown,
  });

  const openApplicants = () => {
    setIsApplicantListOpen(true);
  };

  const closeApplicants = () => {
    setIsApplicantListOpen(false);
  };

  return (
    <>
      <Collapse
        in={isVisible}
        easing={{
          enter: "linear",
          exit: "linear",
        }}
        orientation="vertical"
        sx={{
          height: "min-content",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
          ref={containerRef}
        >
          <Box>
            <Typography variant="h5" mb={1}>
              Atendimento
            </Typography>

            <Typography variant="body" color="common.shade" sx={{ fontSize: "16px" }}>
              Aqui você pode interagir e gerenciar com os atendimentos de sua conta
            </Typography>
          </Box>

          <Button variant="contained" color="secondary" onClick={openApplicants}>
            Nova conversa
          </Button>
        </Box>
      </Collapse>

      <ApplicantsList isOpen={isApplicantListOpen} onClose={closeApplicants} />
    </>
  );
};
