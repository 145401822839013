import { Header } from "@/components/PageLayout/Header";
import { HeaderVariant } from "@/components/PageLayout/Header/constants";
import { useWebEditorContext } from "@/contexts/WebEditorContext";
import { Box } from "@mui/material";
import React, { useState } from "react";
import { EditorModal } from "./components/EditorModal";
import { Resizer } from "./components/Resizer";
import { EditorUIProvider } from "./contexts/EditorUIContext/provider";
import { useResizable } from "./useResizable";
import { Outlet } from "react-router-dom";

export const EditorPageLayout = () => {
  const [chatWidth, setChatWidth] = useState(0);
  const { containerRef, handleMouseDown, isResizing } = useResizable({
    onWidthChange: setChatWidth,
  });

  return (
    <EditorUIProvider>
      <Box
        sx={{
          display: "grid",
          gridTemplateRows: "min-content minmax(0, 1fr)",
          height: "100%",
          width: "100%",
        }}
      >
        <Header variant={HeaderVariant.EDITOR} chatWidth={chatWidth} />

        <Box
          ref={containerRef}
          sx={{
            display: "grid",
            gridTemplateColumns: `${chatWidth}px minmax(0, 1fr)`,
            gridTemplateRows: "minmax(0, 1fr)",
            height: "100%",
            width: "100%",
          }}
        >
          <Box sx={{ width: "100%", display: "flex", position: "relative" }}>
            <Box sx={{ width: "100%", overflow: "auto", display: "flex" }}>
              <Outlet />
            </Box>
            <Resizer onMouseDown={handleMouseDown} isResizing={isResizing} />
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              borderLeft: "1px solid",
              borderColor: "common.lightShade",
              position: "relative",
              backgroundColor: "background.default",
            }}
          >
            <WebEditor />

            <EditorModal />
          </Box>
        </Box>
      </Box>
    </EditorUIProvider>
  );
};

const WebEditor = () => {
  const { editorElement } = useWebEditorContext();

  return editorElement;
};
