import { WebToast } from "@//components/core/Toast";
import { Loading } from "@/components/Loading";
import { ModalRight } from "@/components/Modal/ModalRight";
import { Box, Button } from "@mui/material";
import React, { ChangeEvent, useEffect } from "react";
import { InputField } from "../InputField";
import { useCreateLexZapChannel } from "@/hooks/lexZap/admin/useCreateLexZapChannel";

export const CreateChannelModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const [form, setForm] = React.useState<
    Partial<{
      channelId?: string;
    }>
  >({});
  const { mutateAsync: createChannel, isPending: isLoading } = useCreateLexZapChannel();

  useEffect(() => {
    setForm({});
  }, [isOpen]);

  const handleCreateChannel = async () => {
    if (!form.channelId) {
      return onClose();
    }

    try {
      await createChannel({
        channelId: form.channelId,
      });
      onClose();
      WebToast.success("Canal criado com sucesso");
    } catch {
      WebToast.error("Falha ao criar o canal");
    }
  };

  const handleChangeForm = <K extends keyof typeof form>(prop: K) => {
    return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setForm((prevState) => ({
        ...prevState,
        [prop]: e.target.value,
      }));
    };
  };

  return (
    <ModalRight
      title={"Criar Canal"}
      size={"SMALL"}
      onClose={onClose}
      footer={
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            gap: "8px",
          }}
        >
          <Button variant="contained" disabled={isLoading || !form.channelId} onClick={handleCreateChannel}>
            Criar
          </Button>
          <Button disabled={isLoading} onClick={onClose}>
            Cancelar
          </Button>
        </Box>
      }
      open={isOpen}
    >
      <Loading isLoading={isLoading}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
          }}
        >
          <InputField
            placeholder="AX9TFsCH"
            onChange={handleChangeForm("channelId")}
            type="text"
            value={form.channelId}
            label="ID do Canal"
          />
        </Box>
      </Loading>
    </ModalRight>
  );
};
