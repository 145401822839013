import React, { EffectCallback, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { PageLayout } from "@/components/PageLayout";
import { CreditCardMethod } from "./CreditCardMethod";
import { PlanInformation } from "./PlanInformation";
import { usePaymentContext } from "@/contexts/PaymentContext";
import { Loading } from "@/components/Loading";
import { Button } from "@/components/Button";
import PaymentSuccessIcon from "@/assets/svgs/paymentSuccess.svg?react";
import PaymentFailIcon from "@/assets/svgs/paymentFail.svg?react";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "@/routes/routePaths";
import { getParam } from "@/utils/getParams";
import { useCredits } from "@/hooks/credits/useCredits";
import { useNewPlans } from "@/hooks/useNewPlans";

export const InternalPaymentPage = () => {
  const { reset, onPlanChange } = usePaymentContext();
  const plans = useNewPlans();

  useEffect(() => {
    const planId = getParam("PLAN");
    if (planId) {
      const plan = plans.find((p) => p.id.toLowerCase() == (planId as string).toLowerCase());
      if (plan) onPlanChange(plan);
    }

    return () => {
      reset();
    };
  }, []);

  return (
    <PageLayout
      contentSx={{
        width: "100%",
      }}
      sx={{
        ".page-layout-container": {
          p: 0,
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 4,
          pl: 4,
          justifyContent: "center",
          "@media (max-width: 960px)": {
            flexWrap: "wrap",
          },
        }}
      >
        <PlanInformation />
        <PaymentPage />
      </Box>
    </PageLayout>
  );
};

const PaymentPage = () => {
  return (
    <Box
      sx={{
        flexGrow: 4,
        bgcolor: "common.white",
        border: "1px solid black",
        borderColor: "common.lightShade",
        borderTop: "none",
        px: 4,
        py: 2,
        minWidth: "50%",
        height: "calc(100vh - 56px)",
        "@media (max-height: 800px)": {
          position: "relative",
          height: "auto",
          minHeight: "100%!important",
        },
      }}
    >
      <PaymentMethods />
    </Box>
  );
};

const PaymentMethods = () => {
  const { loading, error, success } = usePaymentContext();

  const showPaymentMethod = loading || error || success;

  return (
    <Box sx={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <PaymentResult />
      <Box
        sx={{
          display: showPaymentMethod ? "none" : "inline",
          flexGrow: 1,
        }}
      >
        <Typography variant="h5" mb={1}>
          Contratar plano
        </Typography>
        <Typography mb={1} variant="body">
          Complete os dados abaixo para realizar a contratação do plano.
        </Typography>
        <CreditCardMethod />
      </Box>
    </Box>
  );
};

const PaymentResult = () => {
  const { loading, success, loadingMessage, errorMessage, error, handleErrorChange, onLoadingChange } =
    usePaymentContext();
  const navigate = useNavigate();
  const { refetch: refetchCredits } = useCredits();

  const handleNavigate = () => {
    navigate(ROUTE_PATHS.HOME, { replace: true });
  };

  const onSuccessReturn = async () => {
    refetchCredits().then(handleNavigate);
  };

  const onErrorReturn = () => {
    handleErrorChange(false);
    onLoadingChange(false);
  };

  useEffect((): ReturnType<EffectCallback> => {
    if (success) {
      const timer = setTimeout(() => {
        onSuccessReturn();
      }, 8000);
      return () => clearTimeout(timer);
    }
  }, [success]);

  if (loading) {
    return (
      <Box
        sx={{
          height: "calc(100vh - 56px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Loading
          label={
            <Typography align="center" variant="body">
              {loadingMessage}
            </Typography>
          }
          size="LARGE"
          isLoading={loading}
        />
      </Box>
    );
  }

  if (success || error) {
    return (
      <Box
        sx={{
          height: "calc(100vh - 56px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
          flexDirection: "column",
          maxWidth: "300px",
        }}
      >
        {success ? <PaymentSuccessIcon /> : <PaymentFailIcon />}
        <Typography align="center" fontWeight={700} variant="subtitle">
          {success ? "Sucesso!" : "Falha!"}
        </Typography>
        <Typography display="inline" whiteSpace="normal" noWrap={false} align="center" variant="body">
          {success &&
            "Sua assinatura foi realizada com sucesso e seus créditos já estão disponíveis para utilizar o Assistente Lexter."}
          {error && (
            <>
              Infelizmente houve uma falha ao efetuar a contratação de sua assinatura.
              {errorMessage ? (
                <Typography marginTop={2} variant="body">
                  <strong>Motivo:</strong> {errorMessage}
                </Typography>
              ) : (
                <>
                  <br />
                  Por favor, tente novamente em alguns momentos.
                </>
              )}
            </>
          )}
        </Typography>
        <Button
          onClick={success ? onSuccessReturn : onErrorReturn}
          sx={{ textWrap: "nowrap", px: !errorMessage ? 16 : 2, boxShadow: "0px 24px 40px 0px #4A4A6833" }}
          variant="contained"
        >
          {success ? "Fechar" : !errorMessage ? "Voltar" : "Altere o cartão/cupom e tente novamente"}
        </Button>
        {success && (
          <Typography align="center" variant="body2">
            Essa mensagem será fechada em alguns segundos.
          </Typography>
        )}
      </Box>
    );
  }

  return null;
};
