import {
  ArticleOutlined as ArticleOutlinedIcon,
  LogoutOutlined as LogoutOutlinedIcon,
  PersonOutline as PersonOutlineIcon,
  CampaignOutlined as CampaignOutlinedIcon,
  HandshakeOutlined as HandshakeOutlinedIcon,
  IntegrationInstructionsOutlined as IntegrationInstructionsOutlinedIcon,
} from "@mui/icons-material";
import { Dialog, MenuList, Paper, SxProps } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { EXTERNAL_URLS, ROUTE_PATHS } from "@/routes/routePaths";
import { openUrl } from "@/utils/openUrl";
import { Credits } from "./Credits";
import { MenuItem, MenuItemProps } from "./MenuItem";
import { useFeatureFlags } from "@/hooks/useFeatureFlags";
import { useCredits } from "@/hooks/credits/useCredits";
import { PlanType } from "@/hooks/credits/types";
import { AuthService } from "@/services/auth";

interface ConfigMenuContentProps {
  onClose: () => void;
  onLogout?: () => void;
}

const ConfigMenuContent = ({ onClose, onLogout }: ConfigMenuContentProps) => {
  const navigate = useNavigate();
  const { data: credits } = useCredits();

  const isTrial = credits?.companyPlan?.plan === PlanType.TRIAL;
  const isUnlimited = credits?.companyPlan?.plan === PlanType.UNLIMITED;

  const beforeItemAction = () => {
    onClose();
  };

  const flags = useFeatureFlags();

  const allMenuItems: ({ hidden?: boolean } & MenuItemProps)[] = [
    {
      label: "Meu perfil",
      icon: PersonOutlineIcon,
      onClick: () => {},
      hidden: true,
    },
    {
      label: "Indique e Ganhe",
      icon: HandshakeOutlinedIcon,
      onClick: () => navigate(ROUTE_PATHS.REFERRAL),
      hidden: !flags.membergetmemberEnabled || isUnlimited || isTrial,
    },
    {
      label: "Termos de uso",
      icon: ArticleOutlinedIcon,
      onClick: () => openUrl(EXTERNAL_URLS.TERMS_OF_USE),
    },
    {
      label: "Dar Feedback",
      icon: CampaignOutlinedIcon,
      onClick: () => navigate(ROUTE_PATHS.FEEDBACK),
    },
    {
      label: "Dev Tools",
      icon: IntegrationInstructionsOutlinedIcon,
      onClick: () => navigate(ROUTE_PATHS.DEV_TOOLS),
      hidden: !flags.devModeEnabled,
    },
    {
      label: "Sair da Conta",
      icon: LogoutOutlinedIcon,
      onClick: onLogout || (() => AuthService.logout()),
    },
  ];
  const visibleMenuItems = allMenuItems.filter((item) => !item.hidden);

  return (
    <>
      <Credits onClose={onClose} />
      <MenuList
        sx={{
          py: 0,
          backgroundColor: "common.dorian",
          borderColor: "common.lightShade",
          borderWidth: 0,
          borderTopWidth: "1px",
          borderStyle: "solid",
          borderRadius: "0 0 5px 5px",
        }}
      >
        {visibleMenuItems.map(({ onClick: itemAction, ...item }, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              beforeItemAction();
              itemAction();
            }}
            {...item}
          />
        ))}
      </MenuList>
    </>
  );
};

interface ConfigMenuProps {
  open: boolean;
  onClose: () => void;
  onLogout?: () => void;
  sx?: SxProps;
  backdrop?: boolean;
}

export const ConfigMenu = ({ sx: sxProp, backdrop = true, open, onClose, onLogout }: ConfigMenuProps) => {
  const sx: SxProps = {
    position: "absolute",
    m: 0,
    width: 225,
    ...sxProp,
  };

  if (backdrop) {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        PaperProps={{
          sx,
        }}
      >
        <ConfigMenuContent onClose={onClose} />
      </Dialog>
    );
  }

  if (!open) return null;

  return (
    <CardWithoutBackdrop onClose={onClose} sx={sx}>
      <ConfigMenuContent onClose={onClose} onLogout={onLogout} />
    </CardWithoutBackdrop>
  );
};

interface CardWithoutBackdropProps {
  children: React.ReactNode;
  onClose: () => void;
  sx?: SxProps;
}
export const CardWithoutBackdrop = ({ children, onClose, sx }: CardWithoutBackdropProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (event.target instanceof HTMLElement && containerRef.current && !containerRef.current.contains(event.target)) {
        onClose();
      }
    }

    setTimeout(() => {
      document.addEventListener("click", handleClickOutside);
    }, 0);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      ref={containerRef}
      elevation={10}
      sx={{
        zIndex: 1301,
        overflow: "hidden",
        ...sx,
      }}
    >
      {children}
    </Paper>
  );
};
