import { Box, SxProps } from "@mui/material";
import React from "react";

interface ResizerProps {
  onMouseDown?: React.MouseEventHandler<HTMLDivElement>;
  isResizing?: boolean;
  anchor?: "left" | "right";
  sx?: SxProps;
}
export const Resizer = ({ onMouseDown, isResizing, anchor = "right", sx }: ResizerProps) => (
  <Box
    sx={{
      position: "absolute",
      zIndex: 12,
      top: 0,
      bottom: 0,
      right: anchor === "right" ? "-5px" : undefined,
      left: anchor === "left" ? "-5px" : undefined,
      width: "10px",
      cursor: "col-resize",
      backgroundColor: isResizing ? "common.lightShade" : undefined,
      opacity: 0.3,
      transition: "background 0.3s ease",
      ":hover": { backgroundColor: "common.lightShade" },
      ...sx,
    }}
    onMouseDown={onMouseDown}
  />
);
