import React from "react";
import { Box } from "@mui/material";
import { useScrollHandler } from "@/hooks/useScrollHandler";

export const ScrollableContainer = ({
  children,
  onScrollDown,
  onScrollUp,
}: {
  children: React.ReactNode;
  onScrollDown: () => void;
  onScrollUp: () => void;
}) => {
  const { ref: topRef } = useScrollHandler({ onScrollDown, onScrollUp });
  const { ref: bottomRef } = useScrollHandler({ onScrollDown, onScrollUp });
  const { ref: leftRef } = useScrollHandler({ onScrollDown, onScrollUp });
  const { ref: rightRef } = useScrollHandler({ onScrollDown, onScrollUp });

  const paddingX = "40px";
  const paddingY = "32px";

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        paddingX,
        paddingY,
        position: "relative",
      }}
    >
      <Box ref={topRef} sx={{ position: "absolute", top: 0, left: 0, right: 0, height: paddingY }} />
      <Box ref={bottomRef} sx={{ position: "absolute", bottom: 0, left: 0, right: 0, height: paddingY }} />
      <Box ref={leftRef} sx={{ position: "absolute", top: 0, left: 0, bottom: 0, width: paddingX }} />
      <Box ref={rightRef} sx={{ position: "absolute", top: 0, right: 0, bottom: 0, width: paddingX }} />

      {children}
    </Box>
  );
};
