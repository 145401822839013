import { authenticatedCopilotServer } from "@/core/api";
import { CaseNextStep, LegalProceedingFile } from "@/hooks/lexZap/types";

export const CaseService = {
  getCaseNextSteps: async (caseId: string) => {
    const response = await authenticatedCopilotServer.get<CaseNextStep[]>(`/cases/${caseId}/nextSteps`);
    return response.data;
  },

  getCaseFiles: async (caseId: string) => {
    const response = await authenticatedCopilotServer.get<LegalProceedingFile[]>(`/cases/${caseId}/files`);
    return response.data;
  },

  getCaseFileUrl: async (caseId: string, fileId: string) => {
    const response = await authenticatedCopilotServer.get<{ url: string }>(`/cases/${caseId}/files/${fileId}/download`);
    return response.data;
  },
};
