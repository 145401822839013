import { WebToast } from "@//components/core/Toast";
import { Loading } from "@/components/Loading";
import { ModalRight } from "@/components/Modal/ModalRight";
import { useUpdateLexZapCompany } from "@/hooks/lexZap/admin/useUpdateLexZapCompany";
import { LexZapCompany } from "@/hooks/lexZap/types";
import { Box, Button } from "@mui/material";
import React, { ChangeEvent, useEffect, useMemo } from "react";
import { InputField } from "../InputField";

export const EditCompanyModal = ({
  isOpen,
  onClose,
  company,
}: {
  isOpen: boolean;
  onClose: () => void;
  company?: LexZapCompany;
}) => {
  const [form, setForm] = React.useState<
    Partial<{
      assistantName?: string;
      companyName?: string;
    }>
  >({});
  const { mutateAsync: updateCompany, isPending: isLoading } = useUpdateLexZapCompany();

  useEffect(() => {
    if (company) {
      setForm({
        assistantName: company.assistantName,
        companyName: company.companyName,
      });
    } else {
      setForm({});
    }
  }, [company]);

  const hasChangedFields = useMemo(() => {
    return form.assistantName !== company?.assistantName || form.companyName !== company?.companyName;
  }, [form, company]);

  const handleUpdateUser = async () => {
    if (!company) {
      return onClose();
    }

    try {
      await updateCompany({
        companyId: company.companyId,
        email: company.email,
        updates: {
          ...form,
        },
      });
      onClose();
      WebToast.success("Alteração feita com sucesso");
    } catch {
      WebToast.error("Falha ao alterar o usuário");
    }
  };

  const handleChangeForm = <K extends keyof typeof form>(prop: K) => {
    return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setForm((prevState) => ({
        ...prevState,
        [prop]: e.target.value,
      }));
    };
  };

  if (!company) return null;

  return (
    <ModalRight
      title={"Customizar Assistente"}
      size={"SMALL"}
      onClose={onClose}
      footer={
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            gap: "8px",
          }}
        >
          <Button variant="contained" disabled={isLoading || !hasChangedFields} onClick={handleUpdateUser}>
            Atualizar
          </Button>
          <Button disabled={isLoading} onClick={onClose}>
            Cancelar
          </Button>
        </Box>
      }
      open={isOpen}
    >
      <Loading isLoading={isLoading}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
          }}
        >
          <InputField placeholder="Email" disabled={true} value={company.companyId} label="Company Id" />

          <InputField
            placeholder="Nome do assistente"
            onChange={handleChangeForm("assistantName")}
            type="text"
            value={form.assistantName}
            label="Nome do assistente"
          />

          <InputField
            placeholder="Nome do escritório"
            onChange={handleChangeForm("companyName")}
            type="text"
            value={form.companyName}
            label="Nome do escritório"
          />
        </Box>
      </Loading>
    </ModalRight>
  );
};
