import CreateIcon from "@mui/icons-material/Create";
import { Box, CircularProgress, IconButton, TextField, Typography } from "@mui/material";
import { ExitConfirmDialog } from "@/components/ExitConfirmDialog";
import { useThreadContext } from "@/contexts/ThreadContext";
import React, { useState } from "react";
import { WebToast } from "@//components/core/Toast";
import { useRenameThread } from "@/hooks/threads/useRenameThread";

interface HeaderEditorProps {
  chatWidth: number;
}

export const HeaderEditor = ({ chatWidth }: HeaderEditorProps) => {
  const [exitModalParams, setExitModalParams] = useState<{
    title: string;
    action: () => void;
    actionType: "NEW" | "RESTART" | "EXISTING" | "EXIT";
  }>();

  const { currentThread, isGeneratingThreadName } = useThreadContext();

  const { name: currentThreadName, id: threadId } = currentThread || {};

  const [editThreadName, setEditThreadName] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  const { mutateAsync: renameThread, isPending: isRenamingThread } = useRenameThread();

  const handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditThreadName(event.target.value);
  };

  const handleFileNameClick = () => {
    setIsEditing(true);
  };

  const handleUpdateThreadName = async () => {
    if (!threadId || !editThreadName || editThreadName.length === 0 || currentThreadName === editThreadName) {
      setIsEditing(false);
      return;
    }
    try {
      await renameThread({ threadId, name: editThreadName });
      setIsEditing(false);
      WebToast.success("Nome da sessão alterado com sucesso");
    } catch (err) {
      WebToast.error("Erro ao alterar o nome da sessão");
      setIsEditing(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: `${chatWidth - 40}px`,
          justifyContent: "space-between",
          height: "100%",
          "&:hover > button": { display: "flex" },
        }}
      >
        {isEditing ? (
          <TextField
            value={editThreadName ?? currentThreadName}
            onChange={handleFileNameChange}
            onBlur={handleUpdateThreadName}
            autoFocus
            variant="standard"
            sx={{
              flex: 1,
              fontSize: "14px",
              fontWeight: "700",
              width: "100%",
              "& .MuiInputBase-root.Mui-disabled::before": {
                borderBottom: "none",
              },
            }}
            disabled={isRenamingThread || isGeneratingThreadName}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleUpdateThreadName();
              }
            }}
          />
        ) : (
          <Typography
            variant="preTitle"
            sx={{ flex: 1, width: "100%", fontSize: "14px", textTransform: "none" }}
            onClick={handleFileNameClick}
          >
            {editThreadName ?? currentThreadName}
          </Typography>
        )}
        {isRenamingThread || isGeneratingThreadName ? (
          <Box sx={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          <IconButton
            onClick={handleFileNameClick}
            sx={{
              display: "none", // show only when hovering
            }}
          >
            <CreateIcon />
          </IconButton>
        )}
      </Box>

      <Box sx={{ width: "1px", height: "18px", backgroundColor: "common.mediumShade", margin: "0 16px" }} />

      <ExitConfirmDialog
        open={!!exitModalParams}
        title={exitModalParams?.title || ""}
        onConfirm={() => {
          setExitModalParams(undefined);
          exitModalParams?.action();
        }}
        action={exitModalParams?.actionType || "NEW"}
        onCancel={() => setExitModalParams(undefined)}
      />
    </Box>
  );
};
