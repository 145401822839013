import { decodeJwt } from "jose";
import * as logger from "@/core/logger";

// All times in minutes
const SESSION_EXPIRATION_TIME_IN_MINUTES = 10;

export const isTokenExpired = (token: string) => {
  try {
    const claims = decodeJwt(token);
    if (!claims.iat) {
      return true;
    }

    const tokenExpirationTime = new Date(claims.iat * 1000);
    tokenExpirationTime.setMinutes(tokenExpirationTime.getMinutes() + SESSION_EXPIRATION_TIME_IN_MINUTES);
    const currentTime = new Date();

    return currentTime > tokenExpirationTime;
  } catch (error) {
    if (error instanceof Error) {
      logger.error(`Error decoding token: ${error.message}`, error);
    }
    return true;
  }
};
