import { buildSkillStep } from "@/core/skillForms/builder";

export const createLaborInitialPetitionSkillStep2 = buildSkillStep({
  title: "Detalhes do caso",
  description: "Preencha a lista abaixo para fornecer o contexto do seu caso à IA:",
  fields: [
    {
      name: "client",
      type: "string",
      ui: {
        label: "Autor",
        description: "Insira o nome completo do cliente, conforme registrado nos documentos oficiais do processo:",
        placeholder: "Maria da Silva, conforme registrado no CPF 321.654.987-00 e na carteira de trabalho.",
      },
    },
    {
      name: "case_breakdown",
      type: "string",
      ui: {
        label: "Descrição dos fatos",
        description:
          "Resuma a relação empregatícia, mencionando o cargo, a data de admissão e desligamento, e as responsabilidades desempenhadas:",
        placeholder:
          "Fui dispensada sem justa causa, sem o pagamento integral das verbas rescisórias e sem aviso prévio, conforme exige a legislação.",
      },
    },
    {
      name: "theses",
      type: "string",
      required: false,
      ui: {
        label: "Argumentos jurídicos",
        description:
          "Indique os fundamentos legais que sustentam a reclamação, citando leis, normas ou jurisprudências pertinentes:",
        placeholder:
          "Busca-se a quitação das verbas rescisórias devidas, com multa por atraso no pagamento devido a rescisão do contrato de trabalho sem justa causa, previsto na CLT.",
      },
    },
    {
      name: "evidence_text",
      type: "string",
      required: false,
      ui: {
        label: "Descrever provas",
        description:
          "Descreva provas que sustentem os fatos, como: documentos, imagens ou evidências relacionadas ao caso:",
        placeholder:
          "O autor dispõe de fotos e laudo técnico de vistoria datado de 05/09/2022, comprovando a não conformidade do imóvel entregue.",
      },
    },
    {
      name: "requests",
      type: "string",
      required: false,
      ui: {
        label: "Solicitar pedidos",
        description:
          "Liste os pedidos que deseja fazer, como pagamento de verbas rescisórias, horas extras, ou outras indenizações:",
        placeholder:
          "Solicita a quitação de verbas rescisórias, multa do art. 477 da CLT, e indenização por danos morais no valor de R$ 5.000,00.",
      },
    },
  ],
});
