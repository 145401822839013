import React from "react";
import { PageLayout } from "@/components/PageLayout";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { LegalProceedingsTable } from "../../components/LegalProceedingsTable";
import { CourtCredentialsList } from "./components/CourtCredentialsList";
import { OabsList } from "./components/OabsList";
export const LegalProceedingMonitoringPage = () => {
  return (
    <PageLayout
      sx={{
        ".page-layout-container": {
          px: 4,
          pt: 4,
          bgcolor: "#FCFBFB",
        },
      }}
      contentSx={{
        width: "100%",
      }}
    >
      <LegalProceedingMonitoring />
    </PageLayout>
  );
};

export const LegalProceedingMonitoring = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Typography variant="h5" pb={4}>
        Casos e processos
      </Typography>
      <Box sx={{ width: "100%", fontWeight: 400, border: "1px solid #DDDCDC", borderRadius: 2, bgcolor: "white" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            sx={{
              "& .MuiTab-root": {
                textTransform: "none",
                pb: 1,
                pt: 1,
                minHeight: "48px",
                fontWeight: 400,
              },
              "& .MuiSvgIcon-root": {
                fontSize: "20px",
              },
            }}
          >
            <Tab label="Processos" />
            <Tab label="Inscrições OAB’s vinculadas" />
            <Tab label="Autorizações de acesso" />
          </Tabs>
        </Box>
        {value === 2 && <CourtCredentialsList />}
        {value === 1 && <OabsList />}
        {value === 0 && (
          <Box
            sx={{
              p: 2,
            }}
          >
            <LegalProceedingsTable monitoringColumnEnabled />
          </Box>
        )}
      </Box>
    </>
  );
};
