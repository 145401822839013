import { WebToast } from "@//components/core/Toast";
import { Loading } from "@/components/Loading";
import { ModalRight } from "@/components/Modal/ModalRight";
import { Box, Button } from "@mui/material";
import React, { ChangeEvent, useEffect } from "react";
import { InputField } from "../InputField";
import { LexZapChannel } from "@/services/lexZap/types";
import { useUpdateLexZapChannel } from "@/hooks/lexZap/admin/useUpdateLexZapChannel";

export const UpdateChannelApiKeyModal = ({
  isOpen,
  onClose,
  channel,
}: {
  isOpen: boolean;
  onClose: () => void;
  channel?: LexZapChannel;
}) => {
  const [form, setForm] = React.useState<
    Partial<{
      apiKey?: string;
    }>
  >({});
  const { mutateAsync: updateChannel, isPending: isLoading } = useUpdateLexZapChannel();

  useEffect(() => {
    setForm({});
  }, [channel]);

  const handleUpdateApiKey = async () => {
    if (!channel) {
      return onClose();
    }

    try {
      await updateChannel({
        channelId: channel.id,
        updates: {
          apiKey: form.apiKey,
        },
      });
      onClose();
      WebToast.success("API Key alterada com sucesso");
    } catch {
      WebToast.error("Falha ao alterar a API Key");
    }
  };

  const handleChangeForm = <K extends keyof typeof form>(prop: K) => {
    return (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setForm((prevState) => ({
        ...prevState,
        [prop]: e.target.value,
      }));
    };
  };

  if (!channel) return null;

  return (
    <ModalRight
      title={"Alterar API Key"}
      size={"SMALL"}
      onClose={onClose}
      footer={
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            gap: "8px",
          }}
        >
          <Button variant="contained" disabled={isLoading || !form.apiKey} onClick={handleUpdateApiKey}>
            Atualizar
          </Button>
          <Button disabled={isLoading} onClick={onClose}>
            Cancelar
          </Button>
        </Box>
      }
      open={isOpen}
    >
      <Loading isLoading={isLoading}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "32px",
          }}
        >
          <InputField
            placeholder="Nova API Key"
            onChange={handleChangeForm("apiKey")}
            type="text"
            value={form.apiKey}
            label="API Key"
          />
        </Box>
      </Loading>
    </ModalRight>
  );
};
