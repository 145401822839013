import React from "react";
import { ListItemIcon, MenuItem, Popover, PopoverProps, Typography } from "@mui/material";
import { Description as DescriptionIcon, PermMedia as PermMediaIcon } from "@mui/icons-material";
interface FilesSelectorProps extends PopoverProps {
  anchorEl: Element;
  onClose: () => void;
  openDocumentSelector: () => void;
  openImageVideoSelector: () => void;
}

export const FilesSelector = ({
  anchorEl,
  onClose,
  openDocumentSelector,
  openImageVideoSelector,
  ...rest
}: FilesSelectorProps) => {
  return (
    <Popover
      onClose={onClose}
      anchorEl={anchorEl}
      {...rest}
      sx={{
        "& .MuiModal-backdrop": {
          backgroundColor: "transparent",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent",
        },
        ...rest.sx,
      }}
    >
      <MenuItem onClick={openDocumentSelector} id="select-files">
        <ListItemIcon sx={{ minWidth: "32px" }}>
          <DescriptionIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="body1">Documento</Typography>
      </MenuItem>
      <MenuItem onClick={openImageVideoSelector}>
        <ListItemIcon sx={{ minWidth: "32px" }}>
          <PermMediaIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="body1">Fotos e vídeos</Typography>
      </MenuItem>
    </Popover>
  );
};
