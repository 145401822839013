import { useWebEditorContext } from "@/contexts/WebEditorContext";
import { useMemo } from "react";
import { WebEditor } from "./WebEditor";
import { Editor } from "./types";

export const useEditor = () => {
  const {
    editor: webEditor,
    currentTabIsEmpty: webEditorCurrentTabIsEmpty,
    allTabsAreEmpty: webEditorAllTabsAreEmpty,
    currentTabContent: webEditorCurrentTabContent,
  } = useWebEditorContext();

  const currentTabContent = useMemo(() => {
    return webEditorCurrentTabContent;
  }, [webEditorCurrentTabContent]);

  const currentTabIsEmpty = useMemo(() => {
    return webEditorCurrentTabIsEmpty;
  }, [webEditorCurrentTabIsEmpty]);

  const allTabsAreEmpty = useMemo(() => {
    return webEditorAllTabsAreEmpty;
  }, [webEditorAllTabsAreEmpty]);

  const editor: Editor | undefined = useMemo(() => {
    if (!webEditor) {
      return undefined;
    }
    return new WebEditor(webEditor);
  }, [webEditor]);

  return {
    editor,
    currentTabContent,
    currentTabIsEmpty,
    allTabsAreEmpty,
  };
};
