import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getNotesQueryKey } from "../useNotes";
import { NoteService } from "@/services/note";
import { Note } from "@/services/note/types";
export const useArchiveNote = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["archiveNote"],
    mutationFn: async ({
      noteId,
    }: {
      noteId: string;
      applicantId?: string | null;
      caseId?: string | null;
      ticketId?: string | null;
    }) => await NoteService.archive({ noteId }),
    onMutate: async ({ noteId, applicantId, caseId, ticketId }) => {
      await queryClient.cancelQueries({ queryKey: getNotesQueryKey({ applicantId, caseId, ticketId }) });
      const previousNotes = queryClient.getQueryData(getNotesQueryKey({ applicantId, caseId, ticketId }));

      queryClient.setQueryData(getNotesQueryKey({ applicantId, caseId, ticketId }), (old: Note[]) => {
        return [...old.filter((a) => a.id !== noteId)];
      });
      return { previousNotes };
    },
    onError: (_error, { applicantId, caseId, ticketId }, context) => {
      queryClient.setQueryData(getNotesQueryKey({ applicantId, caseId, ticketId }), context?.previousNotes);
    },
  });
};
